@media @sm-max {
  .user-count {
    
    &__value {
      margin-right: 0.8rem;
    }
  
    &__btn {
      width: 2rem;
      height: 2rem;
      
      .svg-def {
        opacity: 1 !important;
        width: 1.4rem;
        height: 1.4rem;
        margin: 0;
      }
    }
  }
}
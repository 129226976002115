.c-select {
  position: relative;
  width: 100%;
  color: @color-violet-darklight;
  
  &__main,
  &__item {
    display: flex;
    align-items: center;
    padding-left: @input-padding-side - 0.9rem;
    cursor: pointer;
    
    color: @color-violet-darklight;
  }
  
  
  &__main {
    position: relative;
    background: white;
    padding-right: 3rem;
  }
  
  &__ico {
    flex-shrink: 0;
    width: 2.3rem;
    height: 1.3rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  &__selection,
  &__item-text {
    flex-grow: 1;
    padding: @input-padding-top 0 @input-padding-bottom 0.9rem;
    
    color: inherit;
    font-size: 1.4rem;
    
    transition: color @time;
  }
  
  &__item-text {
    padding-right: @input-padding-side;
  }
  
  &__arrow {
    position: absolute;
    top: 50%;
    right: 1.1rem;
    transform: translateY(-50%);
    display: flex;
    
    &::before {
      content: "";
      display: inline-block;
      border-top: .5rem solid @color-violet-darklight;
      border-left: .4rem solid transparent;
      border-right: .4rem solid transparent;
      transition: transform .2s;
    }
  }
  
  &._open &__arrow::before {
    transform: rotate(180deg);
  }
  
  &__dropdown {
    position: absolute;
    z-index: 41;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 10rem;
    background: white;
    visibility: hidden;
    opacity: 0;
    
    transition: opacity @time-modal;
  }
  
  &._open &__dropdown {
    visibility: visible;
    opacity: 1;
  }
  
  &__item {
    transition: background-color @time;
  }
  
  &__item-text {
  
  }
  
  &__item:hover {
    background-color: @color-grey-light;
    
    color: @color-blue;
  }
}
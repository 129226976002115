.specifications {
  .font;
  line-height: 1.2em;
  font-size: 1.6rem;

  table {
    font: inherit;
    color: inherit;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  tbody {
    font: inherit;
    color: inherit;
  }

  tr {
    font: inherit;
    color: inherit;
  }

  tr:nth-child(2n - 1) {
    td,th {
      background-color: #362940;
    }
  }

  td,th {
    font: inherit;
    color: inherit;
    padding: 1rem 2rem;
    vertical-align: top;

    &:first-child {
      width: 28%;
      color: fade(white,60%);
      border-radius: @radius 0 0 @radius;
    }

    &:last-child {
      border-radius: 0 @radius @radius 0;
    }

    span {
      display: inline-block;
      margin-right: 3rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.news-slider {
	.font;
	margin-left: 13rem;
	position: relative;
	
	&::before {
		content: '';
		position: absolute;
		width: 3rem;
		right: 100%;
		top: 5rem;
		bottom: 5rem;
		background-color: @color-violet-lighter;
		z-index: 1;
	}
	
	&__navs {
		position: absolute;
		top: 0;
		left: -3rem;
		z-index: 1;
	}
	
	&__search {
		position: absolute;
		bottom: 0;
		left: -3rem;
		//z-index: 1;
		width: calc(~'73rem + 3rem ');
	}
	
	&__container-main {
		width: 100%;
		display: flex;
		height: 55.5rem;
		position: relative;
		z-index: 5;
		pointer-events: none;
	}
	
	//--
	
	&__container {
		flex-grow: 1;
		height: 100%;
		overflow: hidden;
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	
	&__wrap {
		width: 100%;
		height: 100%;
		display: flex;
		pointer-events: auto;
	}
	
	&__slide {
		width: 36.5rem;
		height: 100%;
		flex-shrink: 0;
		position: relative;
		margin-right: .2rem;
		z-index: 1;
		transition: z-index @time;
	}
	
	&__slide-content,
	&__thumbs-slide-content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		box-shadow: none;
		transform: translate3d(0,0,0);
		transition: top @time,left @time,right @time,bottom @time,box-shadow @time;
	}
	
	&__slide:nth-child(2n - 1) {
		.news-slider__slide-content {
			flex-direction: column-reverse;
		}
	}
	
	&__img-wrap {
		height: 50%;
		overflow: hidden;
		flex-grow: 1;
		picture {
			display: block;
			width: 100%;
			height: 100%;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate3d(0,0,0);
			transition: transform @time;
		}
	}
	
	//--
	
	&__container-thumbs {
		width: 73rem;
		height: 100%;
		overflow: hidden;
		margin-right: .2rem;
		flex-shrink: 0;
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	
	&__thumbs-wrap {
		width: 100%;
		height: 100%;
		display: flex;
	}
	
	&__thumbs-slide {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		position: relative;
		pointer-events: auto;
	}
	
	&__thumbs-img-wrap {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		z-index: 2;
		picture {
			display: block;
			width: 100%;
			height: 100%;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate3d(0,0,0);
			transition: transform @time;
		}
	}
	
	&__description {
		display: flex;
		flex-direction: column;
		.font;
		color: @color-violet-darklight;
		background-color: white;
		padding: 2.5rem 3rem;
		align-items: flex-start;
		width: 100%;
		height: 50%;
		transition: padding-right @time, height @time, width @time;
	}
	
	&__description-main {
		font: inherit;
		color: inherit;
		width: 100%;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
	
	&__description-date {
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
		opacity: .6;
		display: block;
		margin-bottom: 1.3rem;
	}
	
	&__description-title {
		.h4;
		color: inherit;
		font-size: 1.6rem;
		flex-grow: 1;
		height: 1em;
		overflow: hidden;
		
		a {
			font: inherit;
			color: inherit;
			transition: color @time;
		}
	}
	
	&__description-title a:hover {
		color: @color-mint;
	}
	
	&__description-link {
		margin-top: 2rem;
	}
	
	&__thumbs-description {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 50%;
		z-index: 5;
	}
}

@media @sm {
	.news-slider__slide:hover,
	.news-slider__thumbs-slide:hover {
		//z-index: 5;
		.news-slider__slide-content,
		.news-slider__thumbs-slide-content {
			//top: -2rem;
			//right: -1.5rem;
			//bottom: -2rem;
			//left: -1.5rem;
			//box-shadow: 0 0.3rem 1.5rem 0 fade(black, 45%)
		}
		
		.news-slider__description {
			//padding-right: 3rem + 3rem;
			//height: 60%;
		}
		
		.news-slider__thumbs-description {
			//padding-right: 3rem + 1.5rem;
		}
		
		.news-slider__img-wrap,
		.news-slider__thumbs-img-wrap {
			img {
				transform: scale(1.1);
			}
		}
		.news-slider__img-wrap {
		
		}
	}
}
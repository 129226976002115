.price-block {
	.font();
	//display: flex;
	//flex-direction: column;
	//align-items: flex-start;
	//justify-content: center;
	
	&__price {
		display: inline-block;
		position: relative;
		padding-right: 2.2rem;
		
		font: inherit;
		color: white;
		font-weight: @semibold;
		font-size: 2rem;
		
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 1.7rem;
			height: 1.7rem;
			background: url(../img/png/coin-ico.png) no-repeat center;
			background-size: contain;
			transform: translateY(-50%);
		}
	}
	
	&__old-price {
		font: inherit;
		font-size: 1.2rem;
		color: fade(white, 60);
	}
}
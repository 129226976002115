@media @lg-max {
	.news-slider {
		
		
		&::before {
		
		}
		
		&__navs {
		
		}
		
		&__search {
		
		}
		
		&__container-main {
		
		}
		
		//--
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
		}
		
		&__slide-content,
		&__thumbs-slide-content {
		
		}
		
		&__slide._reverse {
			.news-slider__slide-content {
			
			}
		}
		
		&__img-wrap {
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		//--
		
		&__container-thumbs {
		
		}
		
		&__thumbs-wrap {
		
		}
		
		&__thumbs-slide {
		
		}
		
		&__thumbs-img-wrap {
			
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		&__description {
		
		}
		
		&__description-main {
		
		}
		
		&__description-date {
		
		}
		
		&__description-title {
			
			
			a {
			
			}
		}
		
		&__description-title a:hover {
		
		}
		
		&__description-link {
		
		}
		
		&__thumbs-description {
		
		}
		
		&__slide:hover,
		&__thumbs-slide:hover {
			
			.news-slider__slide-content,
			.news-slider__thumbs-slide-content {
			
			}
			
			.news-slider__description {
			
			}
			
			.news-slider__thumbs-description {
			
			}
		}
	}
}

@media @md-max {
	.news-slider {
		margin-left: 3rem;
		
		&::before {
		
		}
		
		&__navs {
		
		}
		
		&__search {
		
		}
		
		&__container-main {
		
		}
		
		//--
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
		}
		
		&__slide-content,
		&__thumbs-slide-content {
		
		}
		
		&__slide._reverse {
			.news-slider__slide-content {
			
			}
		}
		
		&__img-wrap {
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		//--
		
		&__container-thumbs {
		
		}
		
		&__thumbs-wrap {
		
		}
		
		&__thumbs-slide {
		
		}
		
		&__thumbs-img-wrap {
			
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		&__description {
		
		}
		
		&__description-main {
		
		}
		
		&__description-date {
		
		}
		
		&__description-title {
			
			
			a {
			
			}
		}
		
		&__description-title a:hover {
		
		}
		
		&__description-link {
		
		}
		
		&__thumbs-description {
		
		}
		
		&__slide:hover,
		&__thumbs-slide:hover {
			
			.news-slider__slide-content,
			.news-slider__thumbs-slide-content {
			
			}
			
			.news-slider__description {
			
			}
			
			.news-slider__thumbs-description {
			
			}
		}
	}
}

@media @sm-max {
	.news-slider {
		
		
		&::before {
		
		}
		
		&__navs {
		
		}
		
		&__search {
			width: calc(~'36.5rem + 3rem ');
		}
		
		&__container-main {
		
		}
		
		//--
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
		}
		
		&__slide-content,
		&__thumbs-slide-content {
		
		}
		
		&__slide._reverse {
			.news-slider__slide-content {
			
			}
		}
		
		&__img-wrap {
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		//--
		
		&__container-thumbs {
			display: none;
		}
		
		&__thumbs-wrap {
		
		}
		
		&__thumbs-slide {
		
		}
		
		&__thumbs-img-wrap {
			
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		&__description {
		
		}
		
		&__description-main {
		
		}
		
		&__description-date {
		
		}
		
		&__description-title {
			
			
			a {
			
			}
		}
		
		&__description-title a:hover {
		
		}
		
		&__description-link {
		
		}
		
		&__thumbs-description {
		
		}
		
		&__slide:hover,
		&__thumbs-slide:hover {
			
			.news-slider__slide-content,
			.news-slider__thumbs-slide-content {
			
			}
			
			.news-slider__description {
			
			}
			
			.news-slider__thumbs-description {
			
			}
		}
	}
}

@media @xs-max {
	.news-slider {
		margin-left: 0;
		
		&::before {
			display: none;
		}
		
		&__navs {
			left: 0;
		}
		
		&__search {
			width: 100%;
			left: 0;
		}
		
		&__container-main {
			height: 50rem;
		}
		
		//--
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			width: 32rem;
		}
		
		&__slide-content,
		&__thumbs-slide-content {
		
		}
		
		&__slide._reverse {
			.news-slider__slide-content {
			
			}
		}
		
		&__img-wrap {
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		//--
		
		&__container-thumbs {
		
		}
		
		&__thumbs-wrap {
		
		}
		
		&__thumbs-slide {
		
		}
		
		&__thumbs-img-wrap {
			
			
			picture {
			
			}
			
			img {
			
			}
		}
		
		&__description {
			padding: 1.5rem 2rem;
		}
		
		&__description-main {
		
		}
		
		&__description-date {
		
		}
		
		&__description-title {
			
			
			a {
			
			}
		}
		
		&__description-title a:hover {
		
		}
		
		&__description-link {
		
		}
		
		&__thumbs-description {
		
		}
		
		&__slide:hover,
		&__thumbs-slide:hover {
			
			.news-slider__slide-content,
			.news-slider__thumbs-slide-content {
			
			}
			
			.news-slider__description {
			
			}
			
			.news-slider__thumbs-description {
			
			}
		}
	}
}
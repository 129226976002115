@media @sm-max {
	.page-thanks {
		padding-top: 5rem;
		
		&__title {
			
			font-size: 4rem;
		}
		
		&__status-bar {
			margin-bottom: 8rem;
		}
	}
}

@media @xs-max {
	.page-thanks {
		padding-top: 3.5rem;
		
		&__content {
			margin-bottom: 3.5rem;
		}
		
		&__title {
			font-size: 2.7rem;
			
		}
		
		&__text {
			margin-bottom: 2.5rem;
		}
		
		&__status-bar {
			margin-bottom: 6rem;
		}
	}
}
@media @sm-max {
  .page {
    padding-top: 6.2rem;
  
    &__main {
      padding-top: 1.8rem;
    }
    
    &__pagination {
      margin-bottom: 1.2rem;
    }
  
  
    &__title {
      font-size: 4rem;
    }
  }
}

@media @xs-max  {
  .page {
    &__title {
      margin-bottom: 1.8rem;
    
      font-size: 2.7rem;
    }
  }
}
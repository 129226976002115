.catalog-gallery {
  width: 100%;
  position: relative;
  padding-top: 5rem;
  &:after {
    content: '';
    position: absolute;
    width: 3rem;
    left: 100%;
    top: 5rem;
    bottom: 5rem;
    background-color: #3e374a;
    z-index: 6;
  }
  &__wrap {
    display: flex;
  }
  &__slide {
    display: block;
    flex-shrink: 0;
  }
  &__img {
    width: 100%;
  }
  &__container {
    overflow: hidden;
  }
  &__navs {
    position: absolute;
    right: -3rem;
    top: 0;
  }
}
.page-catalog-main {
  padding-top: 0;
  .page-catalog {
    display: flex;
    position: relative;
    z-index: 5;
    &-wrapper {
      position: relative;
      padding-top: 4rem;
      padding-bottom: 6rem;
      .catalog-background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ecedf0;
        &__top {
          min-height: 25rem;
          background-color: #33203d;
          position: relative;
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to top, #261e34 0%, rgba(38, 30, 52, 0) 100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          &:after {
            content: '';
            width: 100%;
            height: 5.3rem;
            display: block;
            position: absolute;
            bottom: -2.5rem;
            left: 0;
            transform: scale(1,-1);
            background-image: url(../img/png/ragged-pattern.png);
            background-size: contain;
            background-position: top;
            background-repeat: repeat-x;
            z-index: 2;
          }
          .gradient-image {
            width: 100%;
            height: 100%;
            opacity: 0.11;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
          }
        }
        &__bottom {
          //position: absolute;
          width: 100%;
          height: 100%;
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          opacity: 0.1;
        }
      }
    }
    &__left {
      width: 23rem;
      min-width: 23rem;
      margin-right: 5.4rem;
    }
  }
}


.game-cover {
  background-color: #362940;
  border-radius: 0 0 0.3rem 0.3rem;
  &__image {
    width: 100%;
    height: 12.9rem;
    position: relative;
    margin-bottom: 7.1rem;
    img {
      display: block;
    }
    .main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .logo {
      width: 100%;
      height: 8.6rem;
      object-fit: contain;
      position: absolute;
      bottom: -4.4rem;
    }
  }
  &__main {
    h3 {
      padding: 0 3rem 2.2rem;
      font-family: "Source Sans Pro";
      margin-bottom: 0;
    }
    .j-show-catalog {
      width: 100%;
      &:before {
        border-radius: 0;
      }
      span:last-child {
        display: none;
      }
      &.opened {
        &:before {
          background-color: #55375d;
          box-shadow: none;
        }
        span:first-child {
          display: none;
        }
        span:last-child {
          display: inline-block;
        }
      }
    }


  }
  &__breadcrumbs {
    padding: 1rem 3rem;
    border-top: 1px solid #261e34;
    a {
      display: block;
      padding-bottom: 1.5rem;
      svg {
        display: inline-block;
        width: 0.4rem;
        height: 0.6rem;
        margin-right: .2rem;
        margin-bottom: 0.1rem;
        use {
          fill: #00aba5;
          transition: 0.2s ease-in-out;
        }
      }
      span {
        color: #2dd6dc;
        transition: 0.2s ease-in-out;
        font-size: 1.4rem;
      }

     &:nth-child(2) {
       padding-left: 1.2rem;
     }
     &:nth-child(3) {
       padding-left: 2.4rem;
     }
     &:hover {
       span {
         color: #ffffff;
       }
       use {
          fill: #ffffff;
       }
     }

    }
  }
  &__menu {
    & > ul > li {
      padding: 1.6rem 3rem;
      position: relative;
      font-size: 1.4rem;
      border-top: 1px solid #261e34;
      transition: 0.2s ease-in-out;
      > a {
        text-transform: uppercase;
      }
      i {
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        border: 1px solid #493752;
        border-radius: 3px;
        position: absolute;
        right: 1.3rem;
        top: 1.3rem;
        cursor: pointer;
        transition: .2s;
        &:before {
          content: '';
          border-top: .4rem solid #fff;
          border-left: .3rem solid transparent;
          border-right: .3rem solid transparent;
          margin-left: .7rem;
          position: absolute;
          top: 0.9rem;
          left: 0;
          transition: .2s;
        }
        &:hover {
         background-color: #5c4363;
       }
      }
      &.active {
        background-color: #42334e;
        i {
          background-color: #5c4363;
          &:before {
            transform:rotateX(180deg);
          }
        }
      }
    }
    & > ul > li > ul {
      padding: .8rem 0 0 1.6rem;
      display: none;
      li {
        padding: 0.9rem 0;
        font-size: 1.4rem;
        top: 0.8rem;
      }
    }
  }
}

.j-catalog-hidden {
  display: none;
}
a.highlight {
  color: #2dd6dc;
  pointer-events: none;
}

//filters

.filters-wrap {
  margin-top: 3.7rem;
  background-color: #362940;
  h3 {
    padding: 3rem 3rem 2rem;
    font-family: "Source Sans Pro";
  }
  .filter-submit {
    padding: 2rem 0;
    input[type=submit] {
      width: 100%;
      border-radius: 0;
    }
  }
}
.filter-price, .filter-price-mobile{
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;
  height: 0.3rem !important;
  margin: 1rem 0 2rem;
  .noUi-base {
    background-color: #4f475e;
  }
  .noUi-handle {
    width: 1.2rem !important;
    height: 1.2rem !important;
    background-color: #db5121;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    right: -0.5rem !important;
    top: -0.5rem;
    cursor: pointer;
    &:before, &:after {
      display: none;
    }
  }
  .noUi-connects {
    display: block;

    .noUi-connect {
      display: block;
      background: #db5121;
    }
  }

}
.filter-item {
  padding: 1.5rem 3rem;
  border-top: 1px solid #261e34;
  &__trigger {
    position: relative;
    cursor: pointer;
    span {
      font-size: 1.4rem;
      font-family: 'Source Sans Pro',sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1.2;
      color: #fefefe;
      cursor: pointer;
    }
    i {
      display: block;
      width: 2.2rem;
      height: 2.2rem;
      background-color: #5c4363;
      border: 1px solid #493752;
      border-radius: 3px;
      position: absolute;
      right: -1.5rem;
      top: -0.2rem;
      cursor: pointer;
      transition: .2s;
      &:before {
        content: '';
        border-top: .4rem solid #fff;
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        margin-left: .7rem;
        position: absolute;
        top: 0.8rem;
        left: 0;
        transition: .2s;
        transform:rotateX(180deg);
        z-index: 1;
      }
      &.closed {
        background-color: transparent;
        &:before {
          transform: rotateX(0);
        }
      }
    }
  }

  &__content {
    padding-top: 2rem;
    .checkbox__sub-text {
      font-size: 1.2rem;
    }
    .input__list {
      &-item {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
        transition: 0.2s;
        &:nth-child(n+5) {
          display: none;
        }
        &:hover {
          color: #2dd6dc;
        }
        &.disabled-checkbox {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .show-more {
      font-size: 1.4rem;
      color: #2dd6dc;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
      margin-bottom: 1rem;
      &:after {
        content: '';
        border-top: .4rem solid #2dd6dc;
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        margin-left: .7rem;
        margin-top: 0.5rem;
        transition: .2s;
      }
      span:last-child {
        display: none;
      }
      &.open {
        &:after {
          transform:rotateX(180deg);
          margin-top: .2rem;
        }
        span:first-child {
          display: none;
        }
        span:last-child {
          display: inline-block;
        }
      }
      &:hover {
        color: #fff;
        &:after {
          border-top-color: #fff;
        }
      }
    }

    .rating-stars-wrap {
      display: inline-block;
      margin-top: 0.3rem;
      .rating-star {
        width: 1.3rem;
        height: 1.2rem;
        display: inline-block;
        margin-right: 0.2rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }



  }
}




.filter-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    color: #261e34;
    font-size: 1.4rem;
    width: 7.1rem;
    padding: 1.2rem 1rem;
  }
  .divider {
      background: #fff;
      height: 0.1rem;
      width: 1rem
  }
}

.catalog-sort-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  position: relative;
  z-index: 2;

  &__buttons {
    display: flex;
    .border-btn {
      display: inline-block;
      border-top: 1px solid #493752;
      border-bottom: 1px solid #493752;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: .02em;
      padding: 1.1rem 2rem;
      cursor: pointer;
      font-size: 1.4rem;
      transition: 0.2s ease-in-out;
      &:first-child {
        border-left: 1px solid #493752;
        border-radius: .3rem 0 0 .3rem;
      }
      &:last-child {
        border-right: 1px solid #493752;
        border-radius: 0 .3rem .3rem 0;
      }
      &:hover {
        background-color: #493752;
        border-color: #493752;
        color: #fff;
      }
      &.active {
        background-color: #55375d;
        color: #fff;
      }
    }

  }
  &__change-view {
    display: flex;
    .view-switch {
      width: 1.7rem;
      height: 1.6rem;
      margin: 0 0.5rem;
      background-position: center top;
      cursor: pointer;
      &:before {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        box-shadow: 0 4px 10px rgba(0, 171, 165, 0.6);
        opacity: 0;
        transition: 0.2s;
        top: 0;
        background-color: rgba(0,171,165,.3);
      }

      &.view-table {
        background: url(../img/png/view-table.png) no-repeat;
      }
      &.view-line {
        background: url(../img/png/view-line.png) no-repeat;
      }
      &:hover {
        background-position: center bottom;
      }
      &.active {
        background-position: center bottom;
        pointer-events: none;
        &:before {
          opacity: 1;
        }
      }
    }

  }

  &__sort {
    &>div {
      display: flex;
    }
    .sort-select {
      width: 18.9rem;
      height: 4rem;
      background: #40384b;
      //.selectbox {
      //  z-index: 0;
      //}
    }
    .catalog-search {
      margin-left: 1.8rem;
      .search-hidden {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        display: flex;
        opacity: 0;
        visibility: hidden;
        transform: translateX(1rem);
        transition: 0.2s ease-in-out;
        input {
          width: 100%;
          height: 4.1rem;
          padding: 1.5rem 1rem;
          color: #fff;
          background-color: #40384b;
        }
        button {
          border-radius: 0;
          border: none;
          box-shadow: none;
        }
      }
      .search-but {
        width: 4.1rem;
        height: 4.1rem;
        min-width: 4.1rem;
        border-radius: 0.3rem;
        background-color: #40384b;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        svg {
          width: 1.2rem;
          height: 1.3rem;
          display: inline-block;
          use {
            fill: #fff
          }
        }
      }

      &.opened {
        .search-hidden {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}

.filters-mobile-wrap {
  display: none;
}

.catalog-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 3rem;
  > p {
    color: #261e34;
  }
  &.view-table {
    max-width: 81rem;
    .top__cards-item {
      will-change: transform;
      transform: scale(1) perspective(1px) translateZ(0);
      backface-visibility: hidden;
      width: 24.7rem;
      transition: 0.3s;
      margin-top: 2.5rem;
      margin-right: 3rem;
      min-height: 47.7rem;
      position: relative;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        transform: scale(1.05);
      }
      .card-sticker {
        position: absolute;
        top: -0.8rem;
        left: -0.5rem;
        padding: 0.5rem 1rem;
        z-index: 5;
        display: inline-flex;
        span {
          display: inline-block;
          color: #fff;
          font-family: 'Source Sans Pro',sans-serif;
          font-size: 1.2rem;
          line-height: 1;
          letter-spacing: 0.02rem;
          text-transform: uppercase;
        }
        &.top-sale {
          background-color: #00aba5;
        }
        &.special-offer {
          background-color: #b34580;
        }
      }

      .selected-filters {
        padding: 1.5rem 2.5rem 0;
        ul {
          li {
            display: block;
            padding: 0 0 0.4rem;
            color: #261e34;
            font-size: 1.2rem;
            &:before {
              content: '';
              width: 0.6rem;
              height: 0.6rem;
              background-color: #926f9b;
              border-radius: 50%;
              display: inline-block;
              margin-right: 0.5rem;
              margin-bottom: 0.1rem;
            }
          }
        }
      }
    }
    .card__seller{
      color: rgba(38,30,52,.5);
    }
  }
  &.view-line {
    flex-direction: row;
    padding-top: 1.5rem;
    .top__cards-item {
      width: 100%;
      margin-right: 0;
      min-height: unset;
      flex-direction: row;
      margin-top: 1.2rem;
      will-change: transform;
      transform: scale(1);
      transition: 0.2s ease-in-out;
      position: relative;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 36px 54px rgba(0, 0, 0, 0.11);
        transition: 0.2s ease-in-out;
        opacity: 0;
      }
      &:hover {
        transform: scale(1.05);
        z-index: 1;
        &:before {
          opacity: 1;
        }
      }
      .card__img-wrap {
        width: 18.5rem;
        min-width: 18.5rem;
        height: 10.3rem;
      }
      .card__content {
        flex-direction: row;
      }
      .g-btn {
        font-size: 0;
        padding: 0;
        width: 3.2rem;
        min-width: 3.2rem;
        &:before {
          border-radius: 0;
        }
      }
      .card__header {
        padding: 0.5rem 0 .9rem 2rem;
      }
      .card__to-cart svg {
        margin-right: 0;
      }
      .card__title {
        max-height: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 22rem;
      }
      .card__available {
        margin-top: 1rem;
      }
      .card__seller {
        align-items: center;
        border-top: none;
        border-left: 1px solid #eceaed;
        padding: 1rem;
        position: relative;
        .card__price {
          > span {
            display: block;
            text-align: right;
          }
        }
        &-name-wrap {
          position: absolute;
          background-color: #362940;
          box-shadow: 0 36px 54px rgba(0, 0, 0, 0.11);
          padding: 1rem 1.4rem;
          visibility: hidden;
          opacity: 0;
          width: auto;
          margin: 0;
          transition: 0.2s;
          right: 2.5rem;
          top: -2.6rem;
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0.9rem 0.9rem 0;
            border-color: transparent #362940 transparent transparent;
            display: block;
            position: absolute;
            right: 0;
            bottom: -0.7rem;

          }
          span {
            color: #fff;
          }
        }
        .card__price-val-conv {
          display: block;
        }

        &.show-tooltip {
          .card__seller-name-wrap {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .card-sticker {
        position: absolute;
        top: -0.8rem;
        left: -0.5rem;
        padding: 0.5rem 1rem;
        z-index: 5;
        display: inline-flex;
        span {
          display: inline-block;
          color: #fff;
          font-family: 'Source Sans Pro',sans-serif;
          font-size: 1.2rem;
          line-height: 1;
          letter-spacing: 0.02rem;
          text-transform: uppercase;
        }
        &.top-sale {
          background-color: #00aba5;
        }
        &.special-offer {
          background-color: #b34580;
        }
      }
      .selected-filters {
        padding: 1.5rem 2.5rem 0;
        ul {
          li {
            display: block;
            padding: 0 0 0.4rem;
            color: #261e34;
            font-size: 1.2rem;
            &:before {
              content: '';
              width: 0.6rem;
              height: 0.6rem;
              background-color: #926f9b;
              border-radius: 50%;
              display: inline-block;
              margin-right: 0.5rem;
              margin-bottom: 0.1rem;
            }
          }
        }
      }
      .card__price {
        text-align: right;
        &-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &-val-conv {
          display: block;
        }
      }
    }

    .selected-filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 2.5rem;
      width: 20%;
      ul li {
        display: flex !important;
        align-items: center;
      }
    }
    .card-item-slider {
      &__slide {
        width: 18.5rem !important;
      }
      &__navs {
        display: none;
      }
    }
  }
  .star-rating {
    padding-left: 0;
  }
  .card__available {
    align-items: flex-end;
    margin-top: 0.7rem;

    svg.not_available {
      width: 1.3rem;
      height: 1.3rem;
      display: none;
    }
  }
  .card.not-available {
    .card__price-box {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
      svg.not_available {
        display: block;
      }
      svg.available {
        display: none;
      }
    }
  }
  .card__price {
    &-coin {
      margin: 0 0 0 .5rem;
    }
    &-val {
      margin-right: 0;
      white-space: nowrap;
    }
  }

}

.card-item-slider {
  width:100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  //&__container {
  //
  //}
  &__wrap {
    display: flex;
  }
  &__slide {
    //width: 100%;
    display: block;
    flex-shrink: 0;
  }
  &__fade {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s ease-in-out;
    transform: translateY(1rem);
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(to top, #362940 0%, rgba(54, 41, 64, 0.01) 99%, rgba(54, 41, 64, 0) 100%);
      opacity: 0.83;
    }

    .right-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      a {
        display: inline-block;
        margin: 0 0.9rem;
        svg {
          width: 100%;
          height: 100%;
          use, path {
            fill: #fff;
            transition: 0.2s ease-in-out;
          }
        }
        &:hover {
          svg use, svg path {
            fill: #00aba5;
          }
        }
        &.to-favorits {
          width: 1.6rem;
          height: 1.5rem;
        }
        &.to-compare {
          width: 1.4rem;
          height: 1.7rem;
        }

      }
    }
    &:hover {
      opacity: 1;
      transform: translateY(0);
    }
  }


  &__navs {
    position: absolute;
    bottom: 1.2rem;
    left: 1.5rem;
  }
  &__btn {
    display: inline-block;
    width: 1.1rem;
    height: 1.7rem;
    margin: 0 0.9rem;
    background: none;
    border: none;
    appearance: none;
    svg {
      width: 100%;
      height: 100%;
      use {
        fill: #fff;
        transition: 0.2s ease-in-out;
        &:hover {
          fill: #00aba5;
        }
      }
    }
    &._prev {
      transform: rotateY(180deg);
    }
    &._disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

}

.load-more-button {
  width: 100%;
  margin: 2rem 0;
  text-align: center;
  a {
    display: inline-block;
  }
}

.catalog-pagination {
  margin: 2rem 0 3rem;
  ul {
    display: flex;
    justify-content: center;
    li {
      border: 1px solid #cdc8db;
      border-right: none;
      background-color: transparent;
      transition: 0.2s;
      &:first-child {
        border-radius: 0.3rem 0 0 0.3rem;
      }
      &:last-of-type {
        border-right: 1px solid #cdc8db;
        border-radius:  0 0.3rem 0.3rem 0;
      }
      a {
        display: inline-block;
        padding: 1.1rem 1.2rem;
        line-height: 1;
        color: #261e34;
        transition: 0.2s;
      }
      span {
        display: inline-block;
        padding: 1.1rem 1.2rem;
        color: #261e34;
        vertical-align: bottom;
        transition: 0.2s;
      }
      &:hover {
        border-color: #cdc8db;
        background-color: #cdc8db;
      }
      &.active {
        background-color: #00aba5;
        border-color: #00aba5;
        a, span {
          color: #fff;
        }
      }
    }
  }
}
.chosen-filter {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 0.16rem 1rem 0.5rem;
      border: 1px solid #bbadc6;
      border-radius: 0.3rem;
      margin-right: 1.1rem;
      margin-bottom: 1.2rem;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        border-color: #261e34;
      }
      span {
        font-size: 1.4rem;
        color: #261e34;
      }
      i {
        width: 0.7rem;
        height: 0.7rem;
        display: inline-block;
        margin-left: 0.8rem;
        margin-bottom: 0.1rem;
        background: url(../img/svg/lil-cross.svg) no-repeat center;
        cursor: pointer;
      }
      &.clear-all {
        border-color: #d6a08e !important;
        cursor: pointer;
        span {
          color: #db5121;
        }
        i {
          background: url(../img/svg/lil-cross-orange.svg) no-repeat center;
        }
      }
    }
  }
}
.chosen-filter-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 4rem;
  .right-buttons {
    display: flex;
    align-items: center;
    min-width: 26rem;
    margin-left: 3.4rem;
    margin-bottom: 1.6rem;
    justify-content: flex-end;
    .cart-settings {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      position: relative;
      &:hover span {
        color: #36b2aa;
      }
      svg {
        width: 1.4rem;
        min-width: 1.4rem;
        height: 1.4rem;
      }
      span {
        display: inline-block;
        margin-left: 0.7rem;
        font-size: 1.4rem;
        color: #261e34;
        transition: 0.2s;
      }
      &__popup {
        background-color: #362940;
        padding: 2.8rem 3rem 2rem;
        width: 28rem;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateY(1rem);
        transition: 0.2s;
        &.open-popup {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
        h4 {
          font-size: 1.4rem;
          font-family: 'Source Sans Pro',sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 1.2;
          color: #fff;
          margin-bottom: 2rem;
        }
        label {
          color: #fff;
          display: block;
          margin-bottom: 1.5rem;
        }
        span {
          margin: 0 1.5rem 0 0;
        }
        .popup-close {
          width: 1.1rem;
          height: 1.1rem;
          position: absolute;
          right: 1.3rem;
          top: 1rem;
          svg {
            width: 100%;
            height: 100%;
            min-width: unset;
            use {
              fill: #fff;
            }
          }
        }

      }
    }
    label {
      font-size: 1.4rem;
      line-height: 1.3;
      color: #261e34;
    }
  }
}

.best-block {
  background-color: #362940;
  margin-top: 3.7rem;
  h3 {
    padding: 2.2rem 3rem 1rem;
    margin-bottom: 0;
    font-family: "Source Sans Pro";
  }
  &__inner {
    padding: 2rem 0 1.5rem;
    border-top: 1px solid #261e34;
    &:first-of-type {
      border-top: none;
    }
    .subtitle {
      font-size: 1.4rem;
      color: rgba(255,255,255,0.7);
      padding: 0 2.8rem 0;
    }
  }
  .card__seller {
    border: none;
    padding: 1.2rem 2.8rem;
    span {
      color: rgba(255,255,255,0.5);
      transition: 0.2s;
    }
    &:hover {
      span:first-child {
        color: #2dd6dc;
      }
    }
    &.online {
      .card__seller-avatar {
        border-color: #00c2bb;
        box-shadow: 0 0 4px rgba(0, 242, 233, 0.75);
      }
      .card__seller-name {
        color: #fff;
      }
      &:hover span:first-child {
        color: #2dd6dc;
      }
    }
  }
}

.tags-block {
  margin-top: 3.7rem;
  h3 {
    color: #261e34;
    margin-bottom: 2rem;
    font-family: "Source Sans Pro";
  }
  &__inner {
    ul {
      li {
        display: inline-block;
        margin: 0 0.9rem 1rem 0;
        border: 1px solid #bbadc6;
        border-radius: 0.3rem;
        a {
          display: inline-block;
          padding: .3rem 1rem .4rem;
          font-size: 1.4rem;
          color: #261e34;
          transition: 0.2s;
          &:hover {
            background-color: #302741;
            color: #fff;
          }
        }
      }
    }
  }
}

.seller-reviews {
  position: relative;
  background-color: #261e34;
  padding: 6rem 0 3rem;
  margin: 0;
  &:before {
    content: '';
    width: 100%;
    height: 5.3rem;
    background-image: url(../img/png/seller-reviews-bg.png);
    background-repeat: repeat-x;
    background-size: contain;
    display: block;
    position: absolute;
    top: -1rem;
    left: 0;
  }
  &:after {
    content: '';
    width: 97.8rem;
    height: 51.2rem;
    background: url(../img/png/blue-spot.png) no-repeat center;
    background-size: contain;
    display: block;
    position: absolute;
    right: 0;
    top: 5rem;
  }
  h2 {
    color: #fff;
    font-family: Oswald,sans-serif;
    font-weight: 600;
    font-size: 5rem;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1rem;
  }
  .custom-review-slider__inner {
    z-index: 1;
  }
}

.description-block {
  position: relative;
  background-color: #261e34;
  padding: 4rem 0;
  &__hidden {
    .crop-text {
      overflow: hidden;
      position: relative;
      max-height: 5.6rem;
      p {
        color: rgba(255,255,255,0.5);
      }
    }
    .read-more {
      padding: 1rem;
      cursor: pointer;
      position: absolute;
      left: 50%;
      bottom: 0.5rem;
      margin-left: -1rem;
      display: none;
      i {
        display: inline-block;
        border-top: .5rem solid #666ebb;
        border-left: .4rem solid transparent;
        border-right: .4rem solid transparent;
        transition: .2s;
      }
    &:hover i {
      border-top-color: #00aba5;
    }
    }
  &.cropped {
    .crop-text:after {
      content: '';
      width: 100%;
      height: 8rem;
      background-image: linear-gradient(to top, #261e34 0%, rgba(54, 41, 64, 0.01) 99%, rgba(54, 41, 64, 0) 100%);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: visibility 0.2s ease;
    }
    .read-more {
      display: block;
    }
  }
  &.cropped.active {
    .crop-text:after {
      visibility: hidden;
    }
    .read-more i {
      transform: rotateX(180deg);
    }
  }
  }
}

.popup {
  top: -9999px;
  left: -9999px;
  width: 100%;
  height: 100%;
  z-index: -20;
  opacity: 0;
  transition: opacity .3s ease;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  padding: 4rem 0 0;
  &.active {
    opacity: 1;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: auto;
  }
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-content {
    overflow-x: auto;
    max-height: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }
  .popup-close  {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 1.9rem;
    right: 1.6rem;
    z-index: 1;
  }
}
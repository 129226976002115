.tabs-select {
	position: relative;
	.font;
	border: 1px solid @color-violet-lightdark-3;
	border-radius: @radius;
	
	&__active {
		font: inherit;
		color: inherit;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 11/14em 20/14em;
		letter-spacing: .02em;
		text-transform: uppercase;
		
		&::after {
			margin-left: auto;
			border-top-color: currentColor;
		}
		
		&:hover {
			color: inherit;
			&::after {
				border-top-color: currentColor;
			}
		}
	}
	
	&__block {
		font: inherit;
		color: inherit;
		padding: 0 2rem 1.1rem;
		position: absolute;
		z-index: 41;
		background-color: @color-violet-darklight;
		left: -1px;
		right: -1px;
		top: 100%;
		border: 1px solid @color-violet-lightdark-3;
		border-top: 0;
		border-bottom-left-radius: @radius;
		border-bottom-right-radius: @radius;
		display: none;
	}
	
	&__list {
		font: inherit;
		color: inherit;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	
	&__tab {
		font: inherit;
		color: inherit;
		text-transform: uppercase;
		margin-bottom: 1.1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__tab._open {
		display: none;
	}
	
	&._open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		.tabs-select__active {
			
			&::after {
				transform: rotate(180deg);
			}
		}
	}
}

.tabs-select._light {
	color: @color-violet-darklight;
	border: 1px solid @color-grey-violet;
	
	.tabs-select__block {
		background-color: @color-grey-light;
		border-color: @color-grey-violet;
	}
}
.check-button {
  
  & input[type="radio"],
  & input[type="checkbox"] {
    display: none;
  }
  
  &__content {
    padding: 1rem 1.8rem 1.1rem;
    border: 0.1rem solid #5d4d65;
    border-radius: @radius;
    
    font-size: 1.4rem;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    
    transition: @time;
    
    &:hover {
      color: white;
      border-color: @color-mint;
    }
    
    & svg {
      width: 1rem;
      height: 0.8rem;
      margin-right: 0.2rem;
    }
  }
  
  & input:checked ~ &__content {
    background-color: @color-mint;
    border-color: @color-mint;
    box-shadow: 0 1.3rem 2.4rem rgba(0, 171, 186, 0.44);
    
    color: white;
  }
}
@media @md-max {
  .main-footer {


    &__line {

    }

    &__inner {
      padding: 5.5rem 3rem;
    }

    &__social {

    }

    &__col {

    }

    &__col--info {

    }

    &__col--dropdowns {

    }

    &__dropdown {

    }
  }
}

@media @sm-max {
  .main-footer {


    &__line {

    }

    &__inner {
      padding: 4rem 2rem 3.5rem;
      flex-direction: column;
    }

    &__social {

    }

    &__col {
      margin: 0 0 2.5rem;
      width: 100%;
      padding: 0 1rem;
    }

    &__col--info {
      order: 1;
      margin: 1rem 0 0;
    }

    &__col--dropdowns {
      order: -1;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      margin: 0 0 3.5rem;
    }

    &__dropdown {
      margin-bottom: 0;
    }
  }
}
@media @xs-max {
	.values-block {
		display: block;

		&__item {
			flex: 0 1 auto;
			padding-bottom: 4.5rem;

			&:last-child {
				padding-bottom: 0;
			}

			&--left {

				.values-block__input-wrap {


					&::after {
						display: none;
					}

				}

				.values-block__input {
					padding-right: 0;
				}
			}
		}

		&__item-head {
			flex-wrap: wrap-reverse;
		}

		&__item-bottom {
			flex-direction: column;
		}
		
		&__input-decor {
			display: flex;
		}

		&__available--right {
			margin-left: 0;
		}
	}
}
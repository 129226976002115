@media @md-max {
  .page-settings {
    
    &__main{
      max-width: 730px;
    }

    &__form{
      width: 61%;
      margin-right: 4%;
      .form-group{
        input[type="submit"]{
          display: inline-flex;
        }
      }
    }

  }
}

@media (max-width: 1000px) {
  .page-settings {

 /*   &__topmenu{
      display: none;
    }*/
    
    &__main{
      max-width: 100%;
      width: 100%;
    }

    &__form{
      max-width: 380px;
      margin-right: 50px;
    }

    &__main-wrap{
      margin-right: 0;
    }

    &__preview{
      position: absolute;
      top: 453px;
      left: 430px;
    }

  }
}

@media @xs-max {
  .page-settings {

    &__form{
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      .form-group{
        input[type="submit"]{
          display: block;
        }
      }
    }

    &__info{
      display: none;
    }

    &__preview{
      display: none;
    }

  }
}
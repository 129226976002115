.custom-review-slider {
	width: 100%;
	margin-top: 7rem;
	margin-bottom: 7rem;
	&__title {
		.h2;
		text-align: center;
	}
	
	&__inner {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		&::before {
			content: '';
			position: absolute;
			width: 3rem;
			right: 100%;
			top: 5rem;
			bottom: 5rem;
			background-color: @color-violet-lighter;
			z-index: 1;
		}
	}
	
	&__navs {
		position: relative;
		left: -3rem;
		align-self: flex-start;
	}
	
	
	&__container {
		width: 100%;
		overflow: hidden;
	}
	
	&__wrap {
		width: 100%;
		display: flex;
	}
	
	&__slide {
		width: calc(~'(100% - (3.5rem * 2)) / 3');
		flex-shrink: 0;
		margin-right: 3.5rem;
		
		&:last-child {
			margin-right: 0;
		}
	}
}
.header-search {
  display: flex;
  
  &__toggle {
    position: relative;
    z-index: 5;
    padding: 0;
    width: 4.3rem;
    border: 0;
    
    font-size: 0;
    transition: @time;
    
    svg {
      width: 1.2rem;
      height: 1.3rem;
    }
  
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(~"100% + 0.2rem");
      background: #40384b;
      opacity: 0;
      transition: @time;
    }
  }
  
  &._open &__toggle::before {
    opacity: 1;
  }

  &._open &__toggle {
    box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
    background: #40384b;
  }
  
  &__dropdown {
    display: none;
    position: absolute;
    left: 50%;
    top: 100%;
    width: 115.8rem;
    max-height: calc(~"100vh - 200%");
    max-width: calc(~"100% - 6rem");
    transform: translateX(-50%);
    background: #1f1928;
    flex-direction: column;
    
    & > * {
      flex-shrink: 0;
    }
  }
  
  &._open &__dropdown {
    display: flex;
    animation: fadeIn @time-modal;
  }
  
  &__search-prev-results {
    height: 50%;
    flex: 1 1 50%;
  }
}
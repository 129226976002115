.promo-code {
  display: flex;
  align-items: center;
  
  font-size: 1.4rem;
  
  &__main {
    display: flex;
    align-items: center;
    background: #3e374a;
    border-radius: @radius;
    padding: 1rem 2.8rem 1rem 2rem;
    margin-right: -1.1rem;
  }
  
  &__code {
    margin-right: 1.2rem;
    
    font-size: 1.4rem;
  }
  
  &__status {
    display: flex;
    align-items: center;
    padding-bottom: 0.2rem;
    
    font-size: 1.4rem;
    color: @color-mint-light;
    
    & svg {
      transform: translateY(0.2rem);
      width: 1.4rem;
      height: 1.1rem;
      margin-right: 0.4rem;
      fill: @color-mint-light
    }
  }
  
  &__second-wrap {
    display: flex;
    align-items: center;
  }
  
  &__change-btn {
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    
    font-size: 1.4rem;
    color: @color-violet-lightest;
    
    transition: color @time;
  }
  
  &__change-btn-ico {
    width: 2.3rem;
    height: 2.3rem;
    background: @color-violet-lightest;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.8rem;
    
    transition: background-color @time;
    
    & svg {
      width: 0.9rem;
      height: 0.9rem;
      fill: white;
    }
  }
  
  &__change-btn:hover {
    color: @color-violet-lightest2;
  }
  
  &__change-btn:hover &__change-btn-ico {
    background: @color-violet-lightest2;
  }
  
    // - - - -
  
  &__form {
    position: relative;
    display: flex;
  }
  
  &__label {
    position: absolute;
    bottom: calc(~"100% + " 0.9rem);
    left: 0;
    
    font-size: 1.4rem;
    color: fade(white, 60);
  }
  
  &__select,
  &__input {
    width: 17.4rem;
  }
  
  &__select {
   
    
    & .selectbox-select-text {
      padding-left: 1.6rem;
    }
  }
  
  &__form-btn.g-btn {
    padding-top: 1.1rem;
    border-radius: 0 @radius @radius 0;
    
    &::before {
      border-radius: 0 @radius @radius 0;
    }
  }
  
  &__or {
    margin: 0 1.4rem 0.2rem;
    
    font-size: 1.4rem;
  }
}
@media @md-max {
	.top-panel {
	
		&__menu-tooltip {
			&._reverse {
				flex-direction: row;
				right: 50%;
				left: auto;
				
				.menu-tooltip__arrow {
					transform: none;
					margin-right: -.3rem;
					margin-left: 0;
				}
			}
		}
	}
}
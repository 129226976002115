.custom-review {
	width: 100%;
	background-color: @color-violet-lightdark;
	display: flex;
	.font;
	flex-direction: column;
	
	&__head {
		font: inherit;
		color: inherit;
		position: relative;
		padding: 2rem 2rem 1.5rem;
		display: flex;
		justify-content: flex-start;
	}
	
	&__head-inner {
		display: flex;
		font: inherit;
		color: inherit;
	}
	
	&__author-ava {
		width: 6.5rem;
		height: 6.5rem;
		border-radius: 100%;
		border: .1rem solid @color-grey-light-2;
		padding: .3rem;
		box-shadow: none;
		align-self: center;
		flex-shrink: 0;
		margin-right: 2rem;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 100%;
		}
	}
	
	&__head._online {
		.custom-review__author-ava {
			border: .1rem solid @color-blue;
			box-shadow: 0 0 .2rem .1rem @color-blue, inset 0 0 .2rem .1rem @color-blue;
		}
	}
	
	&__author-info {
		font: inherit;
		color: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	
	&__label {
		font: inherit;
		color: fade(white, 60%);
		font-size: 1.2rem;
		display: block;
		margin-bottom: .8rem;
		
		span {
			margin-right: .7rem;
		}
	}
	
	&__name {
		.h3;
		color: inherit;
	}
	
	&__content {
		padding: 0 3rem 3rem;
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
	
	&__star-rating {
		margin-top: 1.5rem;
	}
	
	&__btn {
		&::before {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
	
	&__text {
		position: relative;
		flex-grow: 1;
		
		&._open,
		&._ps-slide-disabled {
			& .custom-review__text-inner {
				cursor: auto;
				&::after {
					opacity: 0;
					visibility: hidden;
					
				}
			}
		}
	}
	
	&__text-inner {
		overflow: hidden;
		position: relative;
		.font;
		font-size: 1.6rem;
		line-height: 1.2;
		max-height: calc(~'1.2em * 4');
		cursor: pointer;
		p {
			font: inherit;
			color: rgba(255,255,255,.6);
		}
		
		&::after {
			content: '';
			width: 100%;
			top: 1rem;
			background-image: linear-gradient(to bottom, fade(@color-violet-lightdark, 0%), @color-violet-lightdark);
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			pointer-events: none;
			transition: opacity @time, visibility @time;
			z-index: 2;
		}
	}
	
	&__text:not([data-ps-slide-wrap]) {
		.custom-review__text-inner {
			max-height: none;
			cursor: auto;
			&::after {
				display: none;
			}
		}
	}
}
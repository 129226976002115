.security {
	margin-top: 7rem;
	margin-bottom: 7rem;
	position: relative;
	
	
	&__inner {
		//padding-top: 17rem;
		//padding-bottom: 25.5rem;
		position: relative;
		z-index: 8;
	}
	
	&__title {
		.h2;
		text-align: center;
		margin-bottom: 3.5rem;
	}
	
	&__container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: -3.5rem;
		margin-left: -3.5rem;
		margin-right: -3.5rem;
	}
	
	&__item {
		padding-top: 3.5rem;
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		width: calc(~'100%/5');
	}
	
	&__item-head {
		padding: 0 3rem 1.5rem 3rem;
		position: relative;
		margin-bottom: 3.3rem;
	}
	
	&__item-number {
		display: inline-block;
		font-family: @oswald;
		font-weight: @semibold;
		font-size: 8rem;
		line-height: 1;
		color: @color-violet-lightdark;
	}
	
	&__item-img {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 5.5rem;
		width: auto;
		z-index: 5;
		
		&--alert {
			height: 5.1rem;
		}
		
		&--identity {
			height: 5.7rem;
		}
		
		&--control {
			height: 5.5rem;
		}
		
		&--payments {
			height: 5.1rem;
		}
		
		&--access {
			height: 4.9rem;
		}
	}
	
	&__item-content {
		.font;
	}
	
	&__item-title {
		.h3;
		margin-bottom: .5em;
	}
	
	&__item-text {
		font: inherit;
		color: fade(white, 60%);
	}
	
	
}
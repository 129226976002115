@media @xs-max {
  .deals {
    padding: 0;
    
    &__header {
    
    }
    
    &__header-title {
    
    }
    
    &__header-navs {
    
    }
    
    &__nav-btn {
    
    }
    
    &__nav-btn--close {
    
    }
    
    &__items {
    
    }
    
    &__item {
    
    }
    
    &__item-content {
    
    }
    
    &__item-title {
    
    }
    
    &__item-status {
    
    }
    
    &__item-log {
    
    }
    
    &__item-log-item {
    
    }
    
    &__item-log-time {
    
    }
    
    &._fixed {
      width: 100%!important;
      top: 6.2rem + 5rem;
    }
    
    &._closed {
      .deals__nav-btn--close {
        svg {
        
        }
        
        &::after {
        
        }
      }
    }
  }
}
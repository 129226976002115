.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 2.8rem;
  min-height: 2rem;
  cursor: pointer;
  
  .no-selection();
  
  .font();
  font-size: 1.6rem;
  
  & input {
    display: none;
  }
  
  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid #5d4d65;
    border-radius: @radius;
    
    transition: border-color @time;
    
    &::before {
      content: '';
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.2rem;
      background: @color-orange;
      opacity: 0;
      
      transition: opacity @time;
    }
  }
  
  &:hover &__mark {
    border-color: #74637d;
  }
  
  & input:checked + &__mark::before {
    opacity: 1;
  }
  
  &__sub-text {
    opacity: 0.3;
    margin-left: 0.4rem;
    
    font-size: 1.4rem;
  }
}

.checkbox {
  
  & input[type="radio"] + &__mark,
  & input[type="radio"] + &__mark::before {
    border-radius: 50%;
  }
}
.cart-list {
  width: 100%;
  
  & tbody {
    background: #362940;
  }
  
  & th,
  & td,
  &__number,
  &__name,
  &__categories-list,
  &__link,
  &__expire,
  &__status-text {
    .font();
  }
  
  & tbody tr:first-child td {
    border-top: 0.1rem solid @color-violet-darklight;
  }
  
  & th,
  & td {
    padding: 1.6rem 1rem;
    vertical-align: middle;
  }
  
  & tr:first-child td {
    //min-height: 10.4rem;
  }
  
  & th {
    padding-top: 0.7rem;
    padding-bottom: 0.8rem;
    border-top: 0.1rem solid #493752;
    
    color: fade(white, 60);
  }
  
  & td:first-child {
    position: relative;
    width: 3rem;
    padding: 0;
  }
  
  & td:nth-child(2) {
    position: relative;
    padding-left: 2rem;
    width: 28%;
    
    & > * {
      position: relative;
      z-index: 2;
    }
  }
  
  & td:nth-child(3) {
    width: 20%;
  }
  
  & td:nth-child(4) {
    width: 12%;
  }
  
  & td:nth-child(5) {
    width: 8%;
  }
  
  &__delete-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & svg {
      width: 1rem;
      height: 1rem;
      fill: white;
      
      transition: fill @time;
    }
    
    &:hover svg {
      fill: @color-orange
    }
  }
  
  &__bg-img {
    position: absolute !important;
    z-index: 1 !important;
    top: 0;
    left: 0;
    width: 18.2rem;
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left, #362940, fade(#362940, 0));
    }
  }
  
  &__name {
    margin: 0 0 1rem;
    
    font-weight: @semibold;
    font-size: 1.6rem;
  }
  
  &__type {
    display: flex;
    align-items: center;
    margin: 0 0 0.8rem;
    
    font-size: 1.2rem;
    
    & svg {
      margin-right: 0.5rem;
      width: 1.4rem;
      height: 1.2rem;
      fill: white;
    }
  }
  
  &__categories-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    
    & * {
      font: inherit;
    }
  }
  
  &__category {
    display: flex;
    align-items: center;
    color: #a27ba8;
    
    &:hover {
      color: #c3a2d3;
    }
    
    & svg {
      width: 0.4rem;
      height: 0.6rem;
      fill: currentColor;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
      
      transition: @time;
    }
  }
  
  &__price {
    display: inline-block;
    position: relative;
    padding-right: 1.6rem;
    
    .font();
    font-size: 1.2rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.2rem;
      height: 1.2rem;
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
  
  &__old-price {
    display: inline-block;
    .font();
    font-size: 1.2rem;
    color: fade(white, 60);
  }
  
  &__delivery {
    display: flex;
    align-items: center;
    
    .font()
  }
  
  &__delivery-ico {
    width: 1.8rem;
    height: 1.8rem;
    fill: white;
    margin-right: 0.8rem;
  }
  
  &__delivery-help {
    margin-left: 0.8rem;
  }
  
  &__ava-wrap {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    border: .1rem solid @color-blue;
    padding: .1rem;
    box-shadow: 0 0 .4rem .1rem fade(@color-blue, 90), inset 0 0 .4rem fade(@color-blue, 50);
    align-self: center;
    margin-bottom: 1rem;
    flex-shrink: 0;
    
    &._disabled {
      border-color: #c0c3cb;
      box-shadow: none;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
    
    & .base-tooltip__dropdown {
      bottom: 5rem;
      left: 1.6rem;
      pointer-events: none;
    }
    
    & .base-tooltip__text {
      padding: 1rem 1.8rem 1.1rem;
      width: auto;
      max-width: 28rem;
    }
  }
  
  & &__seller-name {
    //.font();
    font-size: 1.4rem;
    text-transform: uppercase;
    .clipText();
  }
  
  &__amount {
    width: 10rem;
    margin-bottom: 0.6rem;
  }
  
  &__available {
    font-size: 1.2rem;
    color: fade(white, 60);
  }
  
  &__total-price-wrap {
    text-align: right;
    display: inline-block;
  }
  
  &__discount {
    margin-bottom: 1.2rem;
    
    font-size: 1.4rem;
    color: fade(white, 60);
  }
  
  &__discount-value {
    color: @color-blue;
  }
  
  &__total-price {
    display: inline-block;
    position: relative;
    padding-right: 2.2rem;
    margin-bottom: 0.4rem;
    
    .font();
    font-weight: @semibold;
    font-size: 2rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.7rem;
      height: 1.7rem;
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
  
  &__total-old-price {
    .font();
    font-size: 1.2rem;
    color: fade(white, 60);
  }
  
  a&__total-price-wrap:hover  &__total-price,
  a&__total-price-wrap:hover &__total-old-price {
    color: @color-blue;
  }
  
  & td._more {
    position: relative;
    width: 3rem;
    padding: 0;
  }
  
  & th._total,
  & td._total {
    padding-right: 2rem;
    text-align: right;
  }
  
  &__more {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    max-width: 100%;
    height: 100%;
    border-left: 0.1rem solid @color-violet-darklight;
    
    & span {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: white;
      margin: 0.2rem 0;
    }
    
    &:hover span {
      background-color: @color-blue;
    }
  }
  
  &__product._open &__more {
    background: #3e374a;
    border-color: #3e374a;
  }
  
  &__info-list-row {
    background: #3e374a;
  }
  
  &__info-list-row td {
    padding: 0;
  }
  
  &__info-list-wrap {
    display: none;
  }
  
  &__info-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  &__info-list-value-item {
    width: (100% - 2% * 5) /6;
    margin-right: 2%;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    
    &:nth-child(6n) {
      margin-right: 0;
    }
  }
  
  &__info-list-title {
    margin-bottom: 0.6rem;
    
    font-size: 1.2rem;
    color: fade(white, 40);
  }
  
  &__info-list-value {
    margin-bottom: 0.3rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.share-box {
	.font();
	text-align: center;
	background-color: #362940;
	padding-top: 1.5rem;
	padding-bottom: 2rem;
	
	&__content {
		font: inherit;
	}
	
	&__text {
		font: inherit;
		.highlighted {
			color: @color-blue;
		}
		margin-bottom: 1.8rem;
	}
	
	&__container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: -.7rem -.7rem 0;
	}
	
	&__btn {
		width: 3.6rem;
		height: 3.6rem;
		border-radius: @radius;
		margin: .7rem .7rem 0;
		transition: transform @time;
		
		svg {
			fill: white;
			width: 1.5rem;
			height: 1.5rem;
		}
		
		&:hover {
			transform: scale(1.1);
		}
	}
}


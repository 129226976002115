@media @md-max {
	.main-header {
		
		&__user-bar._logged {
			flex-grow: 0;
		}
		
		
		&__main-nav-col {

		}
		
		&__main-nav {
			padding-right: 0.5rem;
		}
		
		&__menu-trigger {
			background: #581f59;
			width: 5.4rem;
		}
		
		&__main-nav-col {
			margin-left: auto;
		}
	}
}

@media @sm-max {
	.main-header {

		&__inner {
			height: 6rem;
		}
		&__content {
			padding: 0 2rem;
		}
		
		&__main-logo {
			margin-right: 6%;
			width: 12rem;
		}
		
		&__user-bar {
			//justify-content: space-between;
			//width: 50%;
			//max-width: 30rem;
		}

	}
}
.import-products {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  
  &__toggle {
    background: @color-orange;
    border: 0.1rem solid @color-orange;
    border-bottom: 0;
    border-radius: @radius;
    padding: 1.1rem 2rem 1.2rem;
    box-shadow: 0 1.7rem 5rem rgba(217, 80, 34, 0.44);
  
    text-transform: uppercase;
    
    transition: @time;
    
    &:hover {
      color: white;
    }
    
    &::before {
      content: "";
      display: inline-block;
      vertical-align: .18em;
      border-top: .4rem solid white;
      border-left: .3rem solid transparent;
      border-right: .3rem solid transparent;
      margin-right: .7rem;
      transition: .2s;
    }
  }
  
  &._open &__toggle {
    background: @color-violet-darklight;
    border-radius: @radius @radius 0 0;
    padding-bottom: 5.3rem;
    box-shadow: none;
  }

  &._open &__toggle:hover {
    color: @color-blue;
  }
  
  &._open &__toggle::before {
   transform: rotate(-180deg);
  }

  &._open &__toggle:hover::before {
    border-top-color: @color-blue;
  }
  
  &__content-wrap {
    display: none;
    margin-top: -0.1rem;
    width: 100%;
    padding-bottom: 2.4rem;
  }
  
  &__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 0.1rem solid @color-orange;
    border-radius: @radius;
    border-top-right-radius: 0;
    padding: 2.4rem 3.4rem 1.6rem;
  }
  
  &__col {
    width: 40%;
    flex-grow: 1;
  }
  
  &__inputs-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1.8rem;
  }
  
  &__or {
    flex-shrink: 0;
    margin: 0 1.2rem 1.2rem;
  }
  
  &__input {
    width: 100%;
  }
  
  &__note {
    align-self: flex-end;
    margin-left: 2.8rem;
    margin-bottom: 1.2rem;
    
    .font();
    font-size: 1.6rem;
    
    & a {
      font: inherit;
      color: #a27ba8;
    }
  }
  
  &__footer {
    width: 100%;
  }
  
  &__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    min-width: 13rem;
  }
}
.check-buttons {
	
	&__container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin: -2rem 0 0 -2rem;
	}
	
	&__item {
		margin: 2rem 0 0 2rem;
		& input[type="radio"],
		& input[type="checkbox"] {
			display: none;
		}
	}
	
	&__item-mark {
		padding: 1rem 1.8rem 1.1rem;
		border: 0.1rem solid #5d4d65;
		border-radius: @radius;
		
		font-size: 1.4rem;
		display: block;
		cursor: pointer;
		
		transition: @time;
		
		&:hover {
			color: white;
			border-color: @color-mint
		}
	}
	
	&__item input:checked ~ &__item-mark {
		background-color: @color-mint;
		border-color: @color-mint;
		box-shadow: 0 1.3rem 2.4rem rgba(0, 171, 186, 0.44);
		
		color: white;
	}
}
.section-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.6rem;
  
  &__titles-wrap {
    .font();
  }
  
  &__title {
    //flex: 1;
    font-size: 2.7rem;
    margin-right: 1.2rem;
    word-break: break-word;
  }
  
  &__subtitle {
    display: block;
    font: inherit;
    font-size: 1.2rem;
    margin-top: .4rem;
    color: fade(white, 60);
    margin-bottom: -.6rem;
  }
  
  &__section-help {
    position: relative;
    top: 0.1rem;
    margin-left: 1rem;
    flex-shrink: 0;
  }
  
  &__toggle {
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid #493752;
    border-radius: @radius;
    margin-right: 1rem;
    flex-shrink: 0;
    margin-top: .5rem;
    transition: background-color @time, border-color @time;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: .18em;
      border-top: .4rem solid #fff;
      border-left: .3rem solid transparent;
      border-right: .3rem solid transparent;
      transform: rotate(0);
      transition: .2s;
    }
    
    &._open {
      background-color: #55375d;
      border-color: #55375d;
      &::before  {
        transform: rotate(180deg);
      }
    }
  
    &:hover {
      border-color: #725f7c;
    }
  }
  
  &__short-btn-wrap {
    flex-shrink: 0;
    margin-top: .5rem;
  }
  
  &__short-btn {
    //margin-left: 1.2rem;
  }
  
  &__select {
    margin-left: auto;
    margin-top: -.6rem;
    //margin-bottom: -1rem;
    width: 19rem;
    
    & .selectbox-select-text {
      padding-left: 1.5rem;
    }
  }
}
.input {
  
  & &__select {
    background: white;
    z-index: auto !important;
    
    .font();
    color: @color-violet-darklight;
    
  }
  
  &__select {
    & select {
      //display: none;
      //visibility: hidden;
    }
  
    & * {
      font: inherit;
      color: inherit;
    }
    
    & .selectbox-select {
      display: flex;
      cursor: pointer;
      position: relative;
      z-index: 5;
    }
    
    & .selectbox-select-text {
      display: block;
      padding: @input-padding;
      padding-right: 0;
      height: 3.9rem;
      flex-grow: 1;
      .clipText()
    }
  
    & .selectbox-trigger {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-shrink: 0;
      height: auto;
      
      transition: @time;
    }
    
    & .selectbox-trigger-arrow {
      content: "";
      display: inline-block;
      border-top: .5rem solid @color-violet-darklight;
      border-left: .4rem solid transparent;
      border-right: .4rem solid transparent;
      
      vertical-align: .18em;
    }
  
  
  
    & .selectbox-dropdown {
      top: 100% !important;
      z-index: 41;
      background: white;
      width: 100% !important;
      animation: fadeIn @time;
    }
  }
  
  &._disabled &__select {
    background: fade(white, 18);
    pointer-events: none;
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  
  &__items-col {
    display: flex;
  }
  
  &__item {
    display: flex;
    align-items: center;
    margin-right: 2.8rem;
    
    .font();
    font-size: 1.2rem;
    color: @color-violet-lightest;
    
    &:last-child {
      margin-right: 0;
    }
    
    &:hover {
      color: @color-violet-lightest2;
    }
  }
  
  &__ico {
    margin-right: 0.6rem;
    
    transition: @time;
    
    & svg {
      position: relative;
      top: 0.2rem;
      height: 1.8rem;
      width: 2rem;
      fill: #4f475e;
      
      transition: @time;
    }
  }
  
  &__item._active &__ico,
  &__item:hover &__ico {
    filter: drop-shadow(0 0.6rem 0.9rem rgba(216, 80, 34, 0.47));
    
    & svg {
      fill: #e8215e;
    }
  }
  
  &__item._compare._active &__ico,
  &__item._compare:hover &__ico {
    filter: drop-shadow(0 0.6rem 0.9rem rgba(232, 159, 33, 0.47));
    
    & svg {
      fill: #e89f21;
    }
  }
  
  &__item._share._active &__ico,
  &__item._share:hover &__ico {
    filter: drop-shadow(0 0.6rem 0.9rem rgba(33, 159, 232, 0.47));
    
    & svg {
      fill: #219fe8;
    }
  }
  
  &__item._review._active &__ico,
  &__item._review:hover &__ico {
    filter: drop-shadow(0 0.6rem 0.9rem rgba(33, 159, 232, 0.47));
    
    & svg {
      fill: #219fe8;
    }
  }
}

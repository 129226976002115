.file-include {
  width: 100%;
  display: block;
  
  &__input {
    display: none;
  }
  
  &__field {
    width: 100%;
    height: 3.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: @input-padding;
    padding-right: 1rem;
    cursor: pointer;
    
    .font();
    color: @color-violet-darklight;
  }
  
  &__input:disabled ~ &__field {
    opacity: 0.2;
    pointer-events: none;
  }
  
  &__text {
    flex-grow: 1;
    .clipText()
  }
  
  &__ico {
    flex-shrink: 0;
    margin-left: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    fill: @color-violet-darklight;
    
    transition: @time;
  }
  
  &__field:hover &__ico {
    fill: @color-blue
  }
}
.editor {
	.font();
	
	&__head {
		font: inherit;
		display: flex;
		margin-bottom: 1.2rem;
		align-items: baseline;
	}
	
	&__lang-wrap {
		margin-left: auto;
		display: flex;
		align-items: flex-end;
		font: inherit;
	}
	
	&__lang {
		display: inline-block;
		font: inherit;
		font-size: 1.2rem;
		color: @color-blue;
	}
	
	&__title {
		display: inline-flex;
		font: inherit;
		color: fade(white, 60);
	}
	
	&__input-wrap {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
	}
	
	&__error {
		display: block;
		overflow: hidden;
		max-height: 0;
		margin: 0;
		
		font: inherit;
		font-size: 1.2rem;
		color: @color-orange;
		
		transition: @time;
	}
	
	&._error &__error {
		max-height: 7rem;
		margin-top: 0.3rem;
	}
	
	&__btn-del {
		padding: 0.5rem;
		border: 0.1rem solid #5d4d65;
		border-radius: @radius;
		
		font-size: 1.6rem;
		margin-left: 1rem;
		//top: 0.2rem;
		margin-bottom: -.2rem;
		&:hover {
			background-color: #5d4d65;
			
			color: white;
		}
	}
}


@media @md-max {
  .catalog-sort-row {
    .sort-buttons-select {
      position: relative;
      z-index: 10;
      font-family: 'Source Sans Pro',sans-serif;
      font-size: 1.4rem;
      line-height: 1.15;
      font-weight: 400;
      color: #fff;
      border: 1px solid #493752;
      border-radius: .3rem;
      width: 40%;
      cursor: pointer;
      span {
        transition: 0.2s ease-in-out;
      }
      .j-dropdown-trigger {
        font: inherit;
        color: inherit;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: .78571429em 1.42857143em;
        letter-spacing: .02em;
        text-transform: uppercase;
        &:after {
          content: "";
          display: inline-block;
          vertical-align: .18em;
          border-top: .4rem solid rgba(255,255,255,.5);
          border-top-color: rgba(255, 255, 255, 0.5);
          border-left: .3rem solid transparent;
          border-right: .3rem solid transparent;
          margin-left: .7rem;
          transition: .2s;
        }
        &:hover {
          span {
            color: #2dd6dc;
          }
        }
        &:hover:after {
           background-top-color: #2dd6dc;
         }
      }
      .j-select-block {
        font: inherit;
        color: inherit;
        padding: 0 2rem 1.1rem;
        position: absolute;
        z-index: 5;
        background-color: #261e34;
        left: -1px;
        right: -1px;
        top: 100%;
        border: 1px solid #493752;
        border-top: 0;
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
        display: none;
        ul {
          font: inherit;
          color: inherit;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .j-select-item {
            font: inherit;
            color: inherit;
            text-transform: uppercase;
            margin-bottom: 1.1rem;
            &:last-child {
              margin-bottom: 0;
            }
            &._open {
              display: none;
            }
          }
        }
      }
      &._open {
        .j-dropdown-trigger:after {
          transform: rotateX(180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .catalog-grid.view-table {
    max-width: 54rem;

    .top__cards-item {
      margin-right: 3rem;
      &:nth-child(3n) {
        margin-right: 3rem;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
@media @sm-max {
  .page-catalog-main {
    h1 {
      font-size: 4rem;
      position: relative;
      z-index: 1;
    }
    .page-catalog {
      display: block;
      &__left {
        width: 100%;
        margin: 0 0 4rem;
        .game-cover {
          border-radius: 0;
        }
      }
      &__right {
        width: 100%;
        .catalog-top {
          padding: 0 3rem;
        }
      }
    }
    .page-catalog-wrapper {
      padding: 0 0 6rem;
    }
    .game-cover {
      display: flex;
      background-color: #261e34;
      position: relative;
      &__image {
        height: 9rem;
        width: 16rem;
        min-width: 16rem;
        margin: 0;
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to left, #261f35 0%, rgba(37, 34, 56, 0) 100%);
          position: absolute;
          right: 0;
          top: 0;
        }
        .main-image {
          img {
            opacity: 0.6;
          }
        }
        .logo {
          width: 12.4rem;
          height: 6.1rem;
          top: 1.4rem;
          left: 1.6rem;
          z-index: 1;

        }
      }

      &__main {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 3.2rem;

        .j-show-catalog {
          width: auto;
        }
        h3 {
          width: 100%;
          display: block;
          padding: 0 2rem;
          text-align: center;
        }
      }
      &__menu {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #362940;
        left: 0;
        top: 0;
        z-index: 999;
        max-height: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        .game-cover-mobile-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2.8rem 2rem;
          h3 {
            text-align: left;
            padding: 0 2rem 0 0 0;
          }
          .catalog-close {
            width: 1.6rem;
            height: 1.6rem;
            min-width: 1.6rem;
            svg {
              width: 100%;
              height: 100%;
              use {
                fill: #fff;
              }
            }
          }
        }
      }
    }
    .chosen-filter-row  {
      padding: 5rem 3rem 0;
      .right-buttons {
        min-width: unset;
        width: 100%;
        margin-left: 0;
        /*justify-content: space-between;*/
        .cart-settings__popup {
          right: auto;
          left: 0;
        }
      }
    }
  }
  .catalog-background {
    &__top {
      padding-bottom: 9rem;
      &:before {
        content: '';
        width: 72rem;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        background: url(../img/png/pink-spot.png) no-repeat;
      }
    }
  }
  .catalog-sort-row {
    .mobile-filter-trigger {
      width: 4.1rem;
      height: 4.1rem;
      border-radius: 0.3rem;
      background-color: #db5121;
      box-shadow: 0 19px 54px rgba(219, 81, 33, 0.44);
      margin-right: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
      span {
        color: #fff;
        font-size: 1.1rem;
        background-color: #55375d;
        display: inline-block;
        padding: 0.3rem 0.6rem;
        min-width: 1.7rem;
        height: 1.7rem;
        line-height: 1;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
        border-radius: 50%;
      }
    }
    .filters-wrap {
      display: none;
    }
    .sort-buttons-select {
      margin-left: 6rem;
    }
  }

  //.filter-mobile-wrap {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //}
  .filter-close {
     svg {
       width: 100%;
       height: 100%;
       path {
         fill: #24263c;
       }
     }
   }
  .filter-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #362940;
    .chosen-filter-mobile {
      background-color: #ededf1;
      padding: 2rem;
      h3 {
        color: #261f35;
        margin-bottom: 2rem;
      }
    }
    .filter-form {
      h3 {
        padding: 2.5rem 2rem 2rem;
      }
      .filter-price-mobile, .filter-inputs {
        width: 17rem;
      }
      .filter-submit {
        width: 17rem;
        padding: 2rem 0;
        input {
          width: 100%;
        }
      }
    }
    .tags-block {
      margin-top: 0;
      padding: 2rem;
      background-color: #ededf1;
    }
  }
  .best-block {
    position: relative;
    margin-bottom: 5rem;
    margin-top: 0;
    z-index: 1;
    &:before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      display: block;
      background-color: #261e34;
    }
    h3 {
      width: 50%;
    }
    &__inner {
      width: 50%;
      border: none;
    }
    &__wrap {
      display: flex;
    }
  }
  /* popups */

  html.open-popup {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
  html.open-catalog {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    header {
      transform: translateY(-6.2rem);
    }
  }
  .game-cover__main  {
    .j-show-catalog {
      &:before {
        border-radius: 0.3rem;
      }
      &.opened {
        &:before {
          background-color: #00aba5;
          box-shadow: 0 1.9rem 5.5rem 0 rgba(0,171,165,.4);
        }
        &:hover:before {
          background-color: #00cbc4;
          box-shadow: 0 1.9rem 5.5rem 0 rgba(0,203,196,.45);
        }
        span:first-child {
          display: block !important;
        }
        span:last-child {
          display: none !important;
        }
      }
    }
  }
}

@media @xs-max {
  .page-catalog-main {
    h1 {
      font-size: 2.7rem;
    }
    .page-catalog__left{
      margin-bottom: 0;
      .game-cover {
        &__main {
          padding: 0;
          justify-content: space-between;
          .j-show-catalog {
            background: #00aba5 url(../img/svg/arrow-right.svg) no-repeat center;
            background-size: 0.7rem 1rem;
            border-radius: 0;
            width: 4.2rem;
            padding: 0;
            height: 100%;
            &:before {
              background:none;
            }
            span {
              display: none;
            }
          }
          .show-catalog-mob {
            padding: 0 2rem;
            width: 100%;
            text-align: center;
            span {
              font-size: 1.4rem;
              text-transform: uppercase;
              color: #fff;
              display: inline-block;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .catalog-background__top {
    display: none !important;
  }
  .page-catalog-main .page-catalog__right {
    .catalog-top {
      position: relative;
      padding: 2rem 0;
      &__background {
        background-color: #33203d;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to top,#261e34 0,rgba(38,30,52,0) 100%);
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        &:after {
          content: '';
          width: 100%;
          height: 5.3rem;
          display: block;
          position: absolute;
          bottom: -2.5rem;
          left: 0;
          transform: scale(1,-1);
          background-image: url(../img/png/ragged-pattern.png);
          background-size: contain;
          background-position: top;
          background-repeat: repeat-x;
          z-index: 2;
        }
        .gradient-image {
          width: 100%;
          height: 100%;
          opacity: .11;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top center;
        }
      }

    }
    .page__pagination, h1 {
      padding: 0 2rem 2rem;
    }
    .catalog-sort-row {
      flex-wrap: wrap;
      margin-top: 0;

      .mobile-filter-trigger {
        order: 1;
        border-radius: 0;
        margin-right: 0;
      }
      .sort-buttons-select {
        width: 100%;
        margin: 0 2rem 2rem;
        order: 0;
        .j-select-block {
          position: unset;
          background-color: transparent;
        }
      }

      .catalog-sort-row__sort {
        order: 2;
        width: 100%;
        padding-left: 4.1rem;
        .sort-select {
          width: 100%;
        }
        .catalog-search {
          margin-left: 0;
          .search-hidden {
            top: auto;
            bottom: 0;
            height: 4.1rem;
          }
          .search-but {
            border-radius: 0;
          }
        }
      }
    }
    .chosen-filter-row {
      padding: 3rem 2rem 0;
      position: relative;
    }
  }
  .catalog-gallery {
    padding-top: 2.8rem;
    &:after {
      display: none;
    }
    &__navs {
      display: none;
    }
    &__slide {
      min-height: 9rem;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .swiper-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0;
      .swiper-pagination-bullet {
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.5);
        display: inline-block;
        margin: 0 0.7rem;
        &-active {
          background-color: #fff;
        }
      }
    }
  }

  .best-block {
    &:before {
      display: none;
    }
    h3 {
      width: 100%;
    }
    &__inner {
      width: 100%;
      &:last-child {
        border-top: 1px solid #261e34;
      }
    }
    &__wrap {
      flex-direction: column;
    }
  }
  .seller-reviews {
    padding: 4rem 0 0;
    &:after {
      top: auto;
      bottom: 0;
    }
    h2 {
      font-size: 2.7rem;
      max-width: 50%;
      text-align: left;
      padding: 0 2rem;
      position: relative;
      z-index: 1;
    }
  }

  .catalog-pagination ul li a {
    padding: 1.1rem 1rem;
  }

  .pagination__link._back {
    position: relative;
  }

  .game-cover__main  {
    .j-show-catalog {
      &:before {
        border-radius: 0;
      }
      &.opened {
        span:first-child {
          display: none !important;
        }
      }
    }
  }
}

@media screen and(max-width: 620px) {
  .catalog-grid.view-table {
    max-width: 28rem;
    justify-content: center;
    .top__cards-item {
      margin-right: 0 !important;
      &:first-child {
        margin-top: 1rem;
      }
    }
  }
}

@media screen and (min-width: 1640px) {
  .catalog-grid.view-table {
    max-width: 110rem;
    .top__cards-item {
      &:nth-child(3n), &:nth-child(2n) {
        margin-right: 3.7rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (min-width: 2560px) {
  .catalog-grid.view-table {
    max-width: unset;
    .top__cards-item {
      margin-right: 3.2rem;
      &:nth-child(3n), &:nth-child(2n), &:nth-child(4n) {
        margin-right: 3.2rem;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
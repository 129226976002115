@media @xs-max {
	.setup-gallery {
		&__navs {
			left: 0;
		}
		
		&__setup {
			margin-top: 0;
		}
		
		&__setup-slide-content {
			padding-left: @side-padding-mob;
			padding-right: @side-padding-mob;
		}
		
		&__position-slider {
			visibility: visible;
		}
		
		&__setup-position:not(._slide) {
			position: absolute;
			visibility: hidden;
		}
	}
}
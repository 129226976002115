@media @md-max {
	.security {
		&__inner {
		
		}
		
		&__title {
		
		}
		
		&__container {
			margin-left: -2rem;
			margin-right: -2rem;
		}
		
		&__item {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		&__item-head {
		
		}
		
		&__item-number {
		
		}
		
		&__item-img {
			
			
			&--alert {
			
			}
			
			&--identity {
			
			}
			
			&--control {
			
			}
			
			&--payments {
			
			}
			
			&--access {
			
			}
		}
		
		&__item-content {
		
		}
		
		&__item-title {
		
		}
		
		&__item-text {
		
		}
		
		
	}
}

@media @sm-max {
	.security {
		//margin-top: 9.5rem;
		//margin-bottom: 13rem;
		&__inner {
			//padding-top: 9.5rem;
			//padding-bottom: 13rem;
		}
		
		&__title {
			font-size: 4rem;
			margin-bottom: 4rem;
		}
		
		&__container {
			margin-left: -2.5rem;
			margin-right: -2.5rem;
		}
		
		&__item {
			width: calc(~'100%/3');
			padding-left: 2.5rem;
			padding-right: 2.5rem;
		}
		
		&__item-head {
		
		}
		
		&__item-number {
		
		}
		
		&__item-img {
			
			
			&--alert {
			
			}
			
			&--identity {
			
			}
			
			&--control {
			
			}
			
			&--payments {
			
			}
			
			&--access {
			
			}
		}
		
		&__item-content {
		
		}
		
		&__item-title {
		
		}
		
		&__item-text {
		
		}
		
		
	}
}

@media @xs-max {
	.security {
		//margin-top: 3.5rem;
		//margin-bottom: 4.5rem;
		&__inner {
			//padding-top: 3.5rem;
			//padding-bottom: 4.5rem;
		}
		
		&__title {
			font-size: 2.7rem;
			margin-bottom: 1.8rem;
		
		}
		
		&__container {
			margin-left: -1rem;
			margin-right: -1rem;
			justify-content: flex-start;
		}
		
		&__item {
			width: calc(~'100%/2');
			padding-left: 1rem;
			padding-right: 1rem;
		}
		
		&__item-head {
		
		}
		
		&__item-number {
		
		}
		
		&__item-img {
			
			
			&--alert {
			
			}
			
			&--identity {
			
			}
			
			&--control {
			
			}
			
			&--payments {
			
			}
			
			&--access {
			
			}
		}
		
		&__item-content {
		
		}
		
		&__item-title {
		
		}
		
		&__item-text {
		
		}
		
		
	}
}
@media @xs-max {
	.review-article {
		padding: 2.5rem 2rem;

		&__head {
			padding-left: 5rem;
			margin-bottom: 1.5rem;
			min-height: 5rem;
		}

		&__author-ava {
			width: 5rem;
			height: 5rem;
			left: -1rem;
			img {

			}
		}

		&__info {

		}

		&__time {
			margin-bottom: .1rem;

			span {

			}
		}

		&__name {
			margin-bottom: 0;
		}

		&__user-rating {

		}

		&__star-rating {

		}

		&__text {
			padding-left: 0;
		}
	}
}
.top {
  width: 100%;
  margin-top: 13rem;
  margin-bottom: 12rem;
  position: relative;
  z-index: 10;
  &__inner {
    width: 100%;
    background-color: @color-grey-light;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2.6rem;
    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 5.3rem;
      left: 0;
      top: -5.2rem;
      background-image: url("../img/png/ragged-pattern.png");
      background-size: auto;
      background-position: top;
      background-repeat: repeat-x;
      z-index: 1;
    }
    
    &::before {
      transform: scale(1,-1);
    }
    
    &::after {
      top: auto;
      bottom: -5.2rem;
    }
  }
  
  &__content {
    position: relative;
    z-index: 5;
  }
  
  &__title {
    .h2;
    color: @color-violet-darklight;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  &__nav-tabs {
    margin-bottom: 7rem;
  }
  
  &__tab-items {
    width: 100%;
  }
  
  &__tab-item {
    width: 100%;
    display: none;
    //animation-name: fadeIn;
    //animation-duration: .3s;
    
    &._open {
      display: block;
    }
  }
  
  &__cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  
  &__cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -3.5rem;
    margin-top: -3.5rem;
  }
  
  &__cards-item {
    width: calc(~'(100% - 3.5rem * 6) / 6');
    margin-right: 3.5rem;
    margin-top: 3.5rem;
    
  }
  
  &__more-btn {
    align-self: center;
    margin-top: 6rem;
  }
  
  &__tabs-select {
    margin-bottom: 4rem;
  }
}
@media @xs-max {
	.slider-navs {
		
		&__btn {
			
			
			svg {
			
			}
			
			&._prev {
				padding-left: 1.8rem;
				svg {
				
				}
			}
			
			&._next {
				padding-right: 1.8rem;
				svg {
				
				}
			}
			
			&:hover {
				svg {
				
				}
			}
			
			&._disabled {
				svg {
				
				}
				
			}
		}
	}
}
.selection {
  display: inline-block;
  cursor: pointer;
  .no-selection();
  
  & input {
    display: none;
  }
  
  &__text {
    display: inline-block;
    min-width: 2rem;
    height: 2rem;
    border: 0.1rem solid @color-orange;
    border-radius: @radius;
    padding: 0.1rem 0.2rem 0;
    
    .font();
    text-align: center;
    
    transition: @time;
  }
  
  & input:checked ~ &__text {
    background-color: @color-orange;
    color: white;
  }
}
@media @lg-max {
	.categories {
		&__container {
		
		}
		
		&__item {
			min-height: 8.5rem;
		}
		
		&__item-img {
			height: 8rem;
			left: 3rem;
		}
	}
}

@media @md-max {
	.categories {
		&__container {
		
		}
		
		&__item {
		
		}
		
		&__item-img {
		
		}
	}
}

@media @sm-max {
	.categories {
		&__container {
		
		}
		
		&__item {
			width: calc(~'(100% - (.2rem * 2)) / 2');
		}
		
		&__item-img {
		
		}
	}
}

@media @xs-max {
	.categories {
		&__container {
		
		}
		
		&__item {
			min-height: 11rem;
			width: calc(~'(100% - (.2rem * 2)) / 2');
			
			flex-grow: 1;
		}
		
		&__item-img {
			left: 2rem;
		}
	}
}
.product-overview {
  
  &__anchors-list {
    margin-bottom: 2.2rem;
  }
  
  &__group {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__group-head {
    margin: 0 0 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  &__group-arrow {
    display: none;
  }
  
  &__group-title {
    margin: 0;
    margin-right: auto;
    flex-grow: 1;
    
    font-family: @oswald;
    font-weight: @regular;
    font-size: 2.7rem;
  }
  
  &__more {
    flex-shrink: 0;
    
    .font();
    font-size: 1.3rem;
    color: @color-violet-lightest;
    
    &:hover {
      color: @color-violet-lightest2;
    }
  }
}

@media @sm {
  .product-overview {
    &__group-content {
      display: block !important;
    }
  
    &__group-content &__more {
      display: none;
    }
  }
}
.search-prev-results {
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
  
  & > * {
    flex-shrink: 0;
  }
  
  &__main {
    .g-scroll-y;
    height: 50%;
    flex: 1 1 50%;
  }
  
  &__group {
    padding: 0 6rem;
    margin-bottom: 1.8rem;
  }
  
  &__group-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.8rem;
    z-index: 2;
    
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #383240;
    }
  }
  
  &__group-title {
    position: relative;
    background: #1f1928;
    padding-right: 1.5rem;
    
    .font();
    color: fade(white, 20);
    font-size: 1.4rem;
    font-weight: 400;
  }
  
  &__group-amount {
    margin-left: 0.6rem;
  }
  
  &__group-show-all {
    background: #1f1928;
    padding-left: 1.5rem;
    
    .font();
    color: @color-blue;
    
    &:hover {
      color: white;
    }
  }
  
  &__list {
  
  }
  
  &__item {
    margin-bottom: 1.3rem;
    
    .font();
    
    & a {
      font: inherit;
    }
    
    & a:hover {
      color: @color-blue;
    }
  }
  
  &__item-note {
    margin-left: 1.2rem;
    
    color: fade(@color-violet-lightest2, 60);
    
    transition: @time;
  }
  
  &__item &__item-note:hover {
    color: @color-violet-lightest2
  }
  
  &__btn {
    background: #1b1524;
    padding: 1.6rem 6rem;
    margin-top: 0.8rem;
    width: 100%;
    justify-content: flex-start;
    
    text-transform: uppercase;
    color: @color-blue;
    
    &:hover {
      background-color: @color-violet;
    }
    
    & svg {
      width: 0.4rem;
      height: 0.6rem;
      fill: currentColor;
      margin-left: 0.6rem;
      
    }
  }
}

.categories {
	position: relative;
	z-index: 10;
	.font;
	&__container {
		font: inherit;
		display: flex;
		flex-wrap: wrap;
		margin-right: -.2rem;
		margin-top: -.2rem;
	}
	
	&__item {
		font: inherit;
		background-color: @color-violet-light;
		display: flex;
		width: calc(~'(100% - (.2rem * 4)) / 4');
		margin-right: .2rem;
		margin-top: .2rem;
		min-height: 10.5rem;
		
		position: relative;
		align-items: center;
		z-index: 1;
		transform: translate3d(0,0,0);
		will-change: transform;
		transition: transform @time, z-index @time, box-shadow @time, filter @time;
		overflow: hidden;
		
		&:nth-child(4n) {
			transform-origin: right;
		}
	}
	
	&__item-content {
		font: inherit;
		position: relative;
		z-index: 3;
		padding: 1rem 3rem;
		width: 100%;
		text-align: right;
		transform: translate3d(0,0,0);
		will-change: transform;
		transition: transform @time;
		transform-origin: right;
	}
	
	&__item-title {
		.h3;
		margin-bottom: .4rem;
	}
	
	&__item-text {
		font: inherit;
		color: white;
		font-size: 1.2rem;
	}
	
	&__item-img {
		position: absolute;
		bottom: 0;
		object-fit: contain;
		object-position: bottom;
		height: 9.5rem;
		left: 6rem;
		width: auto;
		z-index: 1;
		transform: translate3d(0,0,0);
		will-change: transform;
		transition: transform @time;
		transform-origin: bottom left;
	}
	
	@media @md {
		&__item:hover {
			z-index: 5;
			transform: scale(1.1);
			box-shadow: 0 1.8rem 5.4rem 0 fade(black, 45%);
			filter: brightness(1.1);
			
			.categories__item-content {
				transform: scale(0.9);
			}
			.categories__item-img {
				transform: scale(0.9);
			}
		}
	}
	
	
}

.create-extra-options {
  
  &__block {
    margin-bottom: 3rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__block + &__block {
    margin-top: 3.5rem;
  }
  
  &__table {
    width: 100%;
    
    & th,
    & td {
      padding: 0.9rem 1.5rem;
    }
    
    & th:first-child,
    & td:first-child {
      padding-left: 0;
    }
    
    & td:nth-last-child(2) {
      width: 11rem;
    }
    
    & td:last-child,
    & td:last-child {
      padding-right: 0;
      width: 9.5rem;
    }
    
    & th {
      padding-top: 0;
      font-size: 1.4rem;
      color: fade(white, 60);
    }
    
    & th:first-child {
      color: @color-blue;
    }
    
    & tbody td {
      border-bottom: 0.1rem solid #493752;
    }
  
  }
  
  &__table-border td {
    position: relative;
    padding: 0;
    border: 0 !important;
    
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;
      background: linear-gradient(to right, #602b68, #db5121);
    }
  }
  
  &__input {
    display: block;
    width: 8rem;
    
    & .input__input,
    & .selectbox-select-text {
      padding-left: 1.5rem;
    }
  }
  
  & .sell-order__save-section-btn {
    border-radius: @radius;
  }
}
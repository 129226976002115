.menu-trigger {
  width: 3.4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &__burger {
    width: 2.3rem;
    height: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    
    span {
      display: block;
      width: 100%;
      height: .2rem;
      background-color: currentColor;
    }
  }
  
  &._open &__burger {
    opacity: 0;
  }
  
  & .badge {
    right: -0.8rem;
  }
  
  &__close {
    opacity: 0;
    position: absolute;
    z-index: 8;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    
    transition: @time;
    
    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: white
    }
  }
  
  &._open &__close {
    opacity: 1;
  }
}
@media @xs-max {
  .create-extra-options {
  
  
    &__table td,
    &__table th {
      padding: .9rem .3rem;
    }
  
    &__table td,
    &__table th {
      
      &:first-child {
        padding-right: 1rem;
      }
    }
  
    &__table th {
      padding-top: 0;
    }
    
    &__table td:nth-last-child(2) {
      width: 7.6rem;
    }
  
    &__table td:last-child {
      padding-right: 0;
      width: 7.3rem;
    }
    
    &__input {
      width: 7rem;
    }
    
    &__block {
      margin-top: 0 !important;
    }
  }
}
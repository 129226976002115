@media @md-max {
  .creator-order {
    background-position: calc(~"100% + " 8.5rem) 14rem, 3% top;
  }
}

@media @sm-max {
  .creator-order {
    background-size: auto 68rem, 140rem auto;
    background-position: calc(~"100% + " 18.5rem) 14rem, 0% -4rem;
    
    &__title {
      margin: 2rem 0 2rem;
      padding-bottom: 0;
      
      font-size: 5rem;
      
    }
  }
}

@media @xs-max {
  .creator-order {
    background-size: auto 68rem, 60rem auto;
    background-position: calc(~"100% +" 23.5rem) 42rem, 0% -1rem;
    
    &__title {
      margin: 2rem 0 2rem;
      
      font-size: 3rem;
      text-align: left;
    }
    
    &__toggle-info-content {
      right: 50%;
      transform: translateX(50%);
      border-bottom-right-radius: @radius;
    }
  }
}
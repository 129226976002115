@media @xs-max {
  .quantity-discount {
    
    &__content {
      padding: 2rem;
    }
    
    &__row {
      flex-direction: column;
      margin: 0 0 2rem;
    }
    
    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    &__sub-content {
      border-top: 0.1rem solid #493752;
      margin: 0 -2rem;
      padding: 2rem 2rem 0;
    }
  }
}
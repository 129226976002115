@space: 1%;

.datepicker {
	.font();
	width: 17.5em;
	z-index: -1;
	margin: 0 auto;
	
	left: 0;
	top: 0;
	position: fixed;
	
	-ms-touch-action: none;
	touch-action: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	
	background-color: #1f1928;
	box-shadow: @box-shadow;
	//padding: 2rem 1.5rem;
	
	
	&.datepicker-dropdown {
		position: absolute;
	}
	
	& * {
		font: inherit;
	}
	
	&__item,
	&__bar-item-prev,
	&__bar-item-current,
	&__bar-item-next {
		//line-height: 1.3;
		
		cursor: pointer;
		
		display: block;
		
		list-style: none;
		padding: 0;
		text-align: center;
		width: (100% - @space * 14 ) / 7;
		margin: .35em @space;
		
		transition: @time;
		
		&:not(.muted):hover {
			//background-color: #e5f2ff;
		}
		
		&.muted {
			visibility: hidden;
			height: 0;
			margin-top: 0;
			margin-bottom: 0;
		}
		
		&.highlighted {
			color: @color-violet-lightest;
		}
		
		&.picked {
			//background-color: @color-yellow;
			//color: black;
			color: @color-blue;
		}
		
		&.disabled {
			opacity: .3;
			cursor: default;
			pointer-events: none;
		}
		
		&.disabled.highlighted {
			//background-color: #e5f2ff;
		}
	}
	
	&__item {
		&:hover {
			color: @color-blue;
		}
	}
	
	&__bar-item-prev,
	&__bar-item-current,
	&__bar-item-next {
		&:hover {
			color: @color-blue;
		}
	}
	
	&__bar,
	&__list,
	&__list-week {
		width: 100%;
		
		display: flex;
		flex-wrap: wrap;
		padding-left: 1.07em;
		padding-right: 1.07em;
	}
	
	&__list {
		padding-top: .45em;
		padding-bottom: 1.07em;
	}
	
	&__bar,
	&__list-week {
		flex-wrap: nowrap;
	}
	
	&__list-week {
		padding: .45em 0;
		padding-left: 1.07em;
		padding-right: 1.07em;
		.datepicker__item {
			color: fade(white, 30%)
			
		}
	}
	
	&__bar {
		border-bottom: 1px solid @color-violet-lightdark;
		padding-bottom: .9em;
		padding-top: 1.07em;
	}
	
	&__bar-item-prev,
	&__bar-item-current,
	&__bar-item-next {
		margin: 0;
		//line-height: 0;
	}
	
	&__bar-item-current {
		flex-grow: 1;
		//font-size: 1.36em;
		//font: inherit;
		//line-height: 1;
		margin: 0 .2em;
	}
	
	&__list:not([data-view="days"]) {
		.datepicker__item {
			line-height: 2.5em;
			//width: calc(~'(100% - (.27em) * 10) / 5');
			width: (100% - @space * 10 ) / 5;
			
		}
	}
	
	&__list-week {
		.datepicker__item {
			cursor: default;
			pointer-events: none;
		}
	}
	
	&__bar-item-prev,
	&__bar-item-next {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: .05em;
		svg {
			width: .5em;
			height: .9em;
			fill: currentColor;
			pointer-events: none;
		}
	}
	
	&__bar-item-prev {
		svg {
			transform: scale(-1, 1);
		}
	}
	
	&__bar-item-next {
		justify-content: flex-end;
	}
}

.datepicker-hide {
	display: none
}

.datepicker-inline {
	position: relative;
	left: auto;
	top: auto;
}
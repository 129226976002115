@games-list-col-width: 23.5%;

.games-list {
  
  &__sub-title {
    margin: 0 0 1.2rem;
    
    font-family: @source-sans;
    font-weight: @regular;
    color: fade(white, 60);
    font-size: 1.4rem;
  }
  
  &__search {
    display: flex;
    border-radius: @radius;
    overflow: hidden;
    background: #40384b;
    margin-bottom: 2.6rem;
    
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }
  
  &__search-input {
    width: 50%;
    flex-grow: 1;
    background: transparent;
    padding: 1.2rem 1.5rem;
    
    font-size: 1.4rem;
    color: white;
  }
  
  &__search-btn {
    flex-shrink: 0;
    background: transparent;
    padding: 0.6rem 1.2rem 0.4rem;
    
    & svg {
      width: 1.2rem;
      height: 1.3rem;
      fill: white;
      
      transition: @time;
    }
    
    &:hover svg {
      fill: @color-blue
    }
  }
  
  // - - - -
  
  &__main {
  
  }
  
  &__list {
    display: flex;
    justify-content: space-between;
  }
  
  &__col {
    width: @games-list-col-width;
    margin-right: (100% - @games-list-col-width * 4) / 2;
    flex-grow: 1;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  &__letter {
    padding: 0 1.1rem;
    margin: 2.5rem 0 0.6rem ;
    font-size: 2rem;
    font-family: @source-sans;
    font-weight: @semibold;
    color: @color-blue;
    
    &:first-child {
      margin-top: 0;
    }
  }
  
  &__item-wrap {
    display: inline-block;
    width: 100%;
  }
  
  &__item {
  
    & input {
      display: none;
    }
  }
  
  &__item-content {
    position: relative;
    padding: 0.6rem 3rem 0.8rem  1rem;
    display: flex;
    cursor: pointer;
    border: 0.1rem solid transparent;
    border-radius: @radius;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-0.6rem);
      width: 1.4rem;
      height: 1.1rem;
      background: url(../img/svg/tick-blue.svg) no-repeat center;
      background-size: contain;
      opacity: 0;
      
      transition: @time;
    }
  }
  
  &__item input:checked ~ &__item-content {
    border-color: @color-blue;
    
    &::after {
      opacity: 1;
    }
  }
  
  &__item-name {
    position: relative;
  }
  
  &__game-logo-wrap {
    position: absolute;
    z-index: 10;
    bottom: 0.7rem;
    left: calc(~"100% +" 0.4rem);
    box-shadow: 0 1.8rem 5.4rem rgba(0, 0, 0, 0.43);
    visibility: hidden;
    opacity: 0;
    border-radius: @radius;
    overflow: hidden;
    transform: translateY(0.5rem);
    pointer-events: none;
    
    transition: @time-modal;
  }
  
  &__item:hover &__game-logo-wrap {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  &__game-logo {
    max-width: none;
  }
}
.anchors-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  
  &__item {
    margin: 0 2.8rem 0.6rem 0;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  &__anchor {
    .font();
    font-size: 1.6rem;
    color: @color-violet-lightest;
    
    &:hover {
      color: @color-violet-lightest2;
    }
    
    &._active {
      color: @color-blue;
    }
  }
}
.amount {
  display: flex;
  background: white;
  
  &__input {
    width: 50%;
    flex-grow: 1;
    padding: 1rem;
    background: transparent;
  }
  
  &__btns-wrap {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  
  &__btn {
    width: 2.8rem;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.6rem;
    background: transparent;
    
    &._minus {
      padding-top: 0;
      padding-bottom: 0.6rem;
    }
    
    &::before {
      content: '';
      border-top: .5rem solid @color-violet-darklight;
      border-left: .4rem solid transparent;
      border-right: .4rem solid transparent;
    }
    
    &._plus::before {
      transform: rotate(180deg);
    }
    
    &:hover::before {
      border-top-color: @color-violet-light-2;
    }
  }
  
  &._min &__btn._minus,
  &._max &__btn._plus {
    opacity: 0.3;
    pointer-events: none;
  }
}
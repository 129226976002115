@header-line-height: 0.2rem;

.main-header {
  width: 100%;
  z-index: 180;
  max-width: 1920px;
  margin: 0 auto;
  background: @transparent;
  border: none;
  padding: 0;
  display: block;
  height: auto;
  
  .font();
  
  &__inner {
    height: @header-height - @header-line-height;
    display: flex;
  }
  
  &__line {
    width: 100%;
    height: @header-line-height;
    background: linear-gradient(to right, #00a7a9, #90159b);
  }
  
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem 0 3rem;
    flex-grow: 1;
    background: linear-gradient(to right, @color-violet-darklight, #581f59);
  }
  
  &__main-logo {
    align-self: center;
    min-width: 11rem;
  }
  
  &__main-nav {
    display: flex;
    padding: 0 3rem;
    //flex-shrink: 0;
    //margin: 0 auto;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
  }
  
  &__main-nav-col {
    //margin: 0 3rem 0 0;
    min-height: 2rem;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  &__user-bar {
    
    &._logged {
      flex-grow: 1;
    }
  }
  
  &__notice-wrap {
    display: flex;

  }
  
  &__basket-btn,
  &__notice-toggle {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
  }
  
  &__notice-toggle {
    border: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: -.2rem;
      background-color: #1f1928;
      box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
      z-index: 1;
      opacity: 0;
      transition: opacity @time;
    }
    
    & svg {
      width: 1.4rem;
      height: 1.7rem;
      position: relative;
      z-index: 5;
    }
    
    & .badge {
      right: 0.5rem;
    }


  }
  
  
  &__basket-btn {
    
    & svg {
      width: 1.7rem;
      height: 1.6rem;
    }
  
    & .badge {
      right: 0.2rem;
    }
  }
  
  
  &__chat-wrap {
    display: flex;
    flex-shrink: 0;
  }
  
  &__chat-toggle {
    padding: 2rem;
    background-color: @color-violet-light;
    transition: background-color @time;

    border: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: -.2rem;
      background-color: #1f1928;
      box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
      z-index: 1;
      opacity: 0;
      transition: opacity @time;
    }
    
    & svg {
      width: 1.7rem;
      height: 1.7rem;
      z-index: 5;
    }
    
    &:hover {
      background-color: @color-orange;
      color: white;
    }
  }
  
  &__notice,
  &__chat {
    display: none;
  }
  
  &__notice-wrap._open &__notice,
  &__chat-wrap._open &__chat {
    display: block;
    animation: fadeIn @time-modal;
  }

  &__notice-wrap._open &__notice-toggle,
  &__chat-wrap._open &__chat-toggle {
    &::before {
      opacity: 1;
    }
  }
}
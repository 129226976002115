.hide-text {
	font: inherit;
	color: inherit;
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	&._hide {
		max-width: 4.3em;
		cursor: pointer;
	}
}
@sub-menu-game-menu-width: 14.612%;

.sub-menu {
  position: absolute;
  z-index: 20;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(~"100vh - 100%");
  background: #1f1928;
  //display: flex;
  flex-direction: column;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 0.4rem;
  }
  
  &__tabs {
    display: flex;
    flex-shrink: 0;
  }
  
  &__tab-btn {
    flex: 1 1 30%;
    padding: 1.2rem 3rem 1.3rem;
    margin-right: 1px;
    background: #383240;
    
    .font();
    color: @color-blue;
    text-transform: uppercase;
    font-size: 1.4rem;
    
    &:last-child {
      margin-right: 0;
    }
    
    &:hover {
      color: @color-blue;
    }
    
    &._open {
      background: #1f1928;
    }
  }
  
  &__blocks {
    padding: 3rem 3rem 0;
    overflow-y: auto;
    flex-shrink: 0;
    height: 50%;
    flex-grow: 1;
  }
  
  &__block {
    display: none;
    flex-wrap: wrap;
    
    &._open {
      display: flex;
      animation: fadeIn @time * 1.5;
    }
  }
  
  &__game-menu {
    width: @sub-menu-game-menu-width;
    margin-right: (100% - (@sub-menu-game-menu-width * 6)) / 5;
    margin-bottom: 3.6rem;
    
    &:nth-child(6n) {
      margin-right: 0;
    }
  }
}
@temp-structure-width-md:  23%;
@temp-structure-width-sm:  46.43%;

@color-scheme-width-sm:  46.43%;

@media @md-max {
  .store-settings {
    
    &__template-structure-list {
      padding: 2rem 3rem 0;
    }
    
    &__template-structure-item {
      width: @temp-structure-width-md;
      margin-right: (100% - @temp-structure-width-md * 4) / 3;
      margin-bottom:  1.8rem;
    }
  }
}


@media @sm-max {
  .store-settings {
  
    &__template-structure-list {
      padding: 2rem @side-padding-mob 0;
      margin-left: -@side-padding-mob;
      margin-right: -@side-padding-mob;
    }
  
    &__template-structure-item {
      width: @temp-structure-width-sm;
      margin-right: 100% - @temp-structure-width-sm * 2;
      //margin-bottom: 2rem;
    
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  
    &__color-scheme-item {
      width: @color-scheme-width-sm;
      margin-right: 100% - @color-scheme-width-sm * 2;
    
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    
    &__products-list {
      display: block;
      width: calc(~"100% +" @side-padding-md * 2);
      margin-left: -@side-padding-md;
      margin-right: -@side-padding-md;
    }
    
    //&__import-products {
    //  order: 4;
    //}
    //
    //&__products-list,
    //&__list-pagination,
    //&__load-more-wrap {
    //  order: 6;
    //}
    
    &__import-products {
      display: none;
      margin: -3rem 0 3rem;
    }
    
    &__products-filter-wrap {
      display: none;
      margin-top: -3.1rem;
    }
    
    &__products-filter {
      border: 0.1rem solid #40384b;
      border-radius: @radius;
      padding: 3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 3rem;
      
      & > form {
        display: flex;
        justify-content: space-between;
        width: 64%;
      }
      
      
    }
  
    &__products-filter-item {
      width: 31.6%;
    }
  
    &__products-filter > form &__products-filter-item {
      width: 46.75%;
    }
  }
}

@media @xs-max {
  .store-settings {
  
    &__tabs-select {
      margin-bottom: 2.4rem;
    }
  
    &__section-head {
      margin-bottom: 1.8rem;
    }
  
    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }
  
    &__footer-note  {
      width: 100%;
      margin: 2rem 0 0;
    }
  
    &__products-list {
      width: calc(~"100% +" @side-padding-mob * 2);
      margin-left: -@side-padding-mob;
      margin-right: -@side-padding-mob;
    }
  
    &__import-products {
      display: block !important;
      order: 14;
      margin: 0 0 2.2rem;
    
      &._tablet {
        display: none !important;
      }
  
      & .import-products__toggle {
        margin-top: 0;
      }
    }
  
    &__products-list-toolbar,
    &__products-filter-wrap {
      order: 16;
    }
  
    &__products-list,
    &__products-list ~ * {
      order: 20;
    }

    &__user-product-menu {
      margin-bottom: 1.8rem;
    }

    &__products-filter {
      flex-direction: column;
      padding: 2rem 2rem 0;
  
      & > form {
        width: 100%;
        flex-direction: column;
      }
    }

    &__products-filter-item {
      width: 100% !important;
      margin-bottom: 2rem;

      //&:last-child {
      //  margin-bottom: 0;
      //}
    }

    &__products-list-toolbar {
      margin-bottom: 2rem;
    }

    &__products-list {
      margin-bottom: 4rem;
    }

    &__list-pagination ul li:nth-child(3):nth-last-child(7),
    &__list-pagination ul li:nth-child(7):nth-last-child(3) {
      display: none;
    }
  
    &__products-filter-wrap {
      margin-top: -2.1rem;
    }
  }
}
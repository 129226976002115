.pagination {
  display: flex;
  align-items: flex-start;
  .font();
  font-size: 1.2rem;
  
  &__list {
    display: flex;
    flex-wrap: wrap;
    
    font: inherit;
  }
  
  &__item {
    margin: 0 .8rem 0.4rem 0;
    
    font: inherit;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  &__link {
    font: inherit;
    color: white;
    font-size: 1.2rem;
    opacity: 0.6;
    
    a&:hover {
      opacity: 1;
      color: white;
    }
    
    a&::after {
      content: '';
      position: relative;
      //top: -0.1rem;
      //right: 0;
      display: inline-block;
      width: 0.4rem;
      height: 0.6rem;
      background: url(../img/svg/arrow-right.svg) no-repeat center;
      background-size: contain;
      margin-left: 0.8rem;
    }
    
    &._back {
      margin-right: 4rem;
      display: flex;
      opacity: 1;
      
      color: @color-violet-lightest;
      
      &:hover {
        color: @color-violet-lightest2;
      }
    }
    
    &._back::after {
      order: -1;
      align-self: center;
      transform: rotate(180deg);
      margin: 0 0.8rem 0 0;
    }
  }
  
  &__item:last-child &__link {
    opacity: 0.2;
  }
}
@avatar-width: 5.2rem;
@avatar-marg: .8rem;

.user-info {
  display: flex;
  position: relative;
  padding: 0 1.5rem;

  .font;
  transition: background-color @time, box-shadow @time;
  
  &._open {
    background-color: #1f1928;
    box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
  }
  
  &__toggle {
    //width: 10rem;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
    
    transition: @time;
    
    &:hover {
      color: @color-blue;
    }
    
    &:hover .dropdown-toggle::after {
      border-top-color: @color-blue;
    }
  }
  
  &__avatar {
    width: @avatar-width;
    height: @avatar-width;
    padding: .3rem;
    position: relative;
    border-radius: 100%;
    //box-shadow: 0 0 10rem @color-blue;
    margin-right: @avatar-marg;
    flex-shrink: 0;
    z-index: 1;
    //background-image: url("../img/png/no-image-small.png");
    //background-repeat: no-repeat;
    //background-size: cover;
    transition: box-shadow @time;
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 3;
    }
    
    &::after {
      content: '';
      position: absolute;
      left: -.1rem;
      top: -.1rem;
      right: -.1rem;
      bottom: -.1rem;
      //background-image: url("../img/png/ava-border.png");
      //background-repeat: no-repeat;
      //background-size: contain;
      border: .2rem solid @color-blue;
      box-shadow: 0 0 .4rem .1rem @color-blue, inset 0 0 .4rem .1rem @color-blue;
      border-radius: 100%;
      z-index: 5;
    }
  }
  
  &__avatar-shadow {
    display: block;
    position: absolute;
    width: 30rem;
    height: 7rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    opacity: .8;
    
    transition: @time;
    
    &::before {
      position: absolute;
      content: '';
      width: @avatar-width - .8rem;
      height: @avatar-width - .8rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      box-shadow: 0 0 5rem @color-blue, 0 0 7rem @color-blue, 0 0 10rem @color-blue;
    }
  }
  
  &._open &__avatar-shadow {
    opacity: 0;
  }
  
  &._open &__avatar {
    box-shadow: 0 0 6rem 0.7rem @color-blue;
  }
  
  &__name-wrap {
    font: inherit;
    color: inherit;
    position: relative;
    width: calc(~"100% -" @avatar-width + @avatar-marg);
    //flex: 0 1 50px;
  }
  
  &__name {
    font: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1;
    z-index: 5;
  }
  
  &__name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //max-width: 180/14em;
  }
  
  &__status {
    font: inherit;
    font-size: 1.2rem;
    letter-spacing: 0;
    color: @color-blue;
    text-transform: none;
    line-height: 1;
    position: absolute;
    bottom: 100%;
  }
  
  &__user-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 20rem;
  }
  
  &._open &__user-menu {
    display: block;
    animation: fadeIn @time-modal;
  }
}
@temp-structure-width: 21.833%;
@color-scheme-width: 22.153%;

.store-settings {
  display: flex;
  flex-direction: column;
  
  &__tabs {
    position: relative;
    justify-content: flex-start;
    margin-bottom: 3.5rem;
  }
  
  &__import-products {
    margin-top: -3.5rem;
    margin-bottom: 3.5rem;
    
    & .import-products__toggle {
      margin-top: -4rem;
    }
  }
  
  &__user-product-menu {
    margin-bottom: 3.8rem;
  }
  
  &__products-list-toolbar {
    margin-bottom: 3rem;
  }
  
  &__section {
    margin-bottom: 3.8rem;
  }
  
  
  &__section-content {
  
  }
  
  &__section-head {
    display: flex;
    align-items: center;
    margin-bottom: 2.6rem;
  }
  
  &__section-title {
    margin: 0;
    font-size: 2.7rem;
  }
  
  &__section-help {
    margin-left: 0.8rem;
  }
  
  &__template-structure-list {
    display: flex;
    flex-wrap: wrap;
    background: #362940;
    padding: 4rem 4rem 1rem;
  }
  
  &__template-structure-item {
    width: @temp-structure-width;
    margin-right: (100% - @temp-structure-width * 4) / 3;
    margin-bottom: 3rem;
    
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  
  &__color-scheme-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -3rem;
  }
  
  &__color-scheme-item {
    width: @color-scheme-width;
    margin-right: (100% - @color-scheme-width * 4) / 3;
    margin-top: 3rem;
    
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  
  &__footer {
    display: flex;
    align-items: center;
  }
  
  &__footer-note {
    margin-left: 2.8rem;
    
    .font();
    font-size: 1.6rem;
    
    & a {
      font: inherit;
      color: #a27ba8;
    }
    
    & a:hover {
      color: #c3a2d3;
    }
  }
  
  &__products-list {
    margin-bottom: 4.8rem;
  }
  
  & &__list-pagination {
    margin: 0 0 2rem;
  }
  
  &__list-pagination ul li {
    border-color: #493752 !important;
  }
  
  &__list-pagination ul li:hover {
    background-color: #493752 !important;
    border-color: #493752 !important;
  }
  
  & .catalog-pagination ul li.active {
    background-color: #00aba5 !important;
    border-color: #00aba5 !important;
  }
  
  &__list-pagination ul li a,
  &__list-pagination ul li span {
    color: white !important;
    .font()
  }
  
  &__load-more-wrap {
    text-align: center;
  }
  
  &__load-more {
    padding: 1.4rem 2.4rem;
  }
}
.set-price {

  &__content {
    display: flex;
    padding: 2.6rem 3rem;
    background: #362940;
  }

  &__item {
    margin-right: 3rem;
    flex: 1 1 auto;
    width: 20%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__input {
    width: 100%;

    & .input__input {
      padding-left: 1.5rem;
    }
  }


}
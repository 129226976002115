.helps-fixed-mob {
  position: fixed;
  z-index: 100;
  top: 8.3rem;
  right: 2rem;
  background: #1a6686;
  border-radius: @radius;
  
  &__ico {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.2rem;
    padding-left: 0.1rem;
    border-radius: @radius;
    background: #00acd1;
    box-shadow: 0 0.7rem 1.7rem rgba(0, 172, 209, 0.44);
    
    font-family: 'Arial Black', 'Arial', 'Gadget', sans-serif;
    font-weight: 900;
    font-size: 2.5rem;
    text-align: center;
  }
  
  &__title {
    max-width: 0;
    padding: 0 0 0.2rem;
    font-size: 1.6rem;
    .clipText();
    
    transition: @time-modal;
  }
  
  &._has-title &__title {
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
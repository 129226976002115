.sell-order-page {
	padding-top: 0;
	display: flex;
	width: 100%;
	
	&__sell-order {
		flex-grow: 1;
		
		padding-top: 4rem;
		padding-bottom: 6rem;
		width: 20%;
	}
	
	&__side-col {
		background-color: #20192c;
		width: 49rem;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
	}
}
@media @md-max {
  .games-list {
  
    
    &__col:last-child &__game-logo-wrap {
      left: auto;
      right:  calc(~"100% +" 0.4rem);
    }
  }
}

@media @sm-max {
  .games-list {
  
    &__game-logo-wrap {
      display: none;
    }
  }
}
@media @xs-max {
  .product-overview {
  
    &__anchors-list {
      display: none;
    }
    
    &__group {
      margin-bottom: 2.4rem;
    }
  
    &__group-head {
      margin: 0;
      cursor: pointer;
      transition: padding-bottom @time;
    }
  
    &__group-head &__more {
      display: none;
    }
  
    &__more {
      display: inline-block;
      margin-top: 1.4rem;
    }
  
    &__group-arrow {
      position: relative;
      top: 0.1rem;
      flex-shrink: 0;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: @radius;
      border: 0.1rem solid @color-violet-lightdark-3;
      margin-right: 0.8rem;
  
      transition: @time;
      
      &::after {
        border-top-color: white;
        margin: 0;
        
        transition: @time;
      }
    }
    
    &__group._open &__group-arrow {
      border-color: @color-violet-lightdark-3;
      background-color:@color-violet-lightdark-3;
      
      &::after {
        transform: rotate(180deg);
      }
      
    }
    
    &__group-content {
      display: none;
    }
  
    &__group._always-open &__group-content,
    &__group._open &__group-content {
      display: block;
    }
  
    &__group._always-open &__group-head,
    &__group._open &__group-head {
      padding-bottom: 1.2rem;
    }
  }
}
@media @sm-max {
	.partners {
		//margin-top: 5rem;
		//margin-bottom: 8rem;
		&__title {
			font-size: 2.7rem;
		}
		
		&__carousel {
		
		}
		
		&__carousel-wrap {
		
		}
		
		&__slide {
			
			
			img {
			
			}
		}
	}
}

@media @xs-max {
	.partners {
		//margin-top: 4.5rem;
		//margin-bottom: 5rem;
		&__title {
			font-size: 2rem;
			margin-bottom: 2.5rem;
		}
		
		&__carousel {
		
		}
		
		&__carousel-wrap {
		
		}
		
		&__slide {
			width: 15rem;
			margin-right: 1rem;
			
			img {
			
			}
		}
	}
}
@media @xs-max {
  .special-offer {
    &__toolbar {
      margin-left: 0;
      margin-top: 2.5rem;
    }
    
    &__content {
      padding-left: @side-padding-mob;
      padding-right: @side-padding-mob;
    }
  }
}
.seller-bar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	.font;
	
	&__content {
		width: 100%;
		background-color: @color-violet-lightdark-3;
		display: flex;
		font: inherit;
		color: inherit;
	}
	
	&__col {
		display: flex;
		width: 20%;
		flex: 1 1 20%;
		border-left: 1px solid @color-violet-darklight;
		font: inherit;
		color: inherit;
		&:first-child {
			border-left: 0;
		}
	}
	
	&__col-inner {
		display: flex;
		flex-grow: 1;
		font: inherit;
		color: inherit;
		padding: .9rem 3rem;
		min-height: 5rem;
	}
	
	&__col--info {
		.seller-bar__col-inner {
			justify-content: space-between;
		}
	}
	
	&__ava-wrap {
		width: 3.2rem;
		height: 3.2rem;
		border-radius: 100%;
		border: .1rem solid @color-blue;
		padding: .2rem;
		box-shadow: 0 0 .2rem .1rem @color-blue, inset 0 0 .2rem .1rem @color-blue;
		flex-shrink: 0;
		margin-right: 1rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 100%;
		}
	}
	
	&__label {
		font: inherit;
		font-size: 1.2rem;
		color: fade(white,60%);
		margin-bottom: 0.3rem;
	}
	
	&__btn {
		flex-grow: 1;
		height: 100%;
		&::before {
			border-radius: 0;
		}
		
		span {
		
		}
	}
	
	&__open-btn {
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 5;
	}
	
	&__open-btn-decor {
		display: block;
		width: 8.6rem;
		height: .9rem;
		fill: @color-violet-lightdark-3;
	}
	
	&__open-btn-arrow {
		display: block;
		width: .6rem;
		height: .9rem;
		fill: white;
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) rotate(-90deg);
	}
	
	&__info-list {
		font: inherit;
		color: fade(white, 60%);
		font-size: 1.2rem;
		
		li {
			font: inherit;
			color: inherit;
			margin-bottom: .1rem;
			
			&>* {
				margin-right: 1rem;
				
				&:last-child {
					margin-right: 0;
				}
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		b {
			font: inherit;
			font-size: 1.6rem;
			color: white;
		}
		
		&--right {
			text-align: right;
		}
	}
	
	&__name {
		a {
			font: inherit;
			color: inherit;
			transition: color @time;
			
			&:hover {
				color: @color-blue;
			}
		}
	}
	
	&__link {
		font: inherit;
		color: @color-violet-lightest;
		transition: color @time;
		
		&:hover {
			color: @color-violet-lightest2;
		}
	}
}
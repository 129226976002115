@how-it-work-width: 30%;

@media @sm-max {
  .how-it-work {
    
    &__content {
      padding-bottom: 0;
      margin-bottom: -4rem;
    }
   
    &__list {
      flex-wrap: wrap;
      justify-content: center;
    }
    
    &__item {
      width: @how-it-work-width;
      margin-right: (100% - @how-it-work-width * 3) / 2;
      margin-bottom: 8rem;
      flex: 0 0 auto;
      
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

@media @xs-max {
  .how-it-work {
    
    &__content {
      padding-bottom: 0;
      margin-bottom: -2rem;
    }

    
    &__title {
      margin-bottom: 3.8rem;
    }
    
    &__list {
    }
    
    &__item {
      width: 46%;
      padding-right: 0;
      margin-right: 0;
      margin-bottom: 2.8rem;
      
      &:nth-child(2n) {
        margin-left: 8% !important;
      }
  
      &::after {
        right: 0;
      }
    }
  }
}
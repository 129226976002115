@media @lg-max {
	.tags-crumbs {
		&__list {
			flex-direction: column;
			flex-grow: 1;
		}
		
		&__item {
		
		}
		
		&__item-name {
			width: 7rem;
		}
	}
}

@media @xs-max {
	.tags-crumbs {
		width: auto;
		margin-left: -@side-padding-mob;
		margin-right: -@side-padding-mob;
		padding: 1.8rem @side-padding-mob;
		padding-right: 13rem;
		
		&__img-wrap {
			right: auto;
			left: 50%;
			transform: translateX(-24%);
		}

		&__list {
			margin-top: -1.5rem;
		}

		&__item {
			flex-wrap: wrap;
			margin-top: 1.5rem;
		}
		
		&__item-name {
			width: auto;
			margin-bottom: .6rem;
		}
		
		&__item-content {
			width: 100%;
		}
	}
}
@media @lg-max {
	.discounts {
		&__item {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

@media @xs-max {
	.discounts {
		&__row {
			flex-wrap: wrap;
		}

		&__item {
			width: 100%;
			border-left-width: 1px;
			border-top-width: 0;

			&:first-of-type {
				border-top-width: 1px;
				border-bottom-left-radius: 0;
				border-top-right-radius: @radius;
			}

			&:last-of-type {
				border-top-right-radius: 0;
				border-bottom-left-radius: @radius;

				.discounts__item-badge {
					border-top-right-radius: 0;
				}
			}
		}

		&__pattern {
			display: none;
		}
	}
}
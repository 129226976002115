@media @sm-max {
  .import-products {
    
    &__content-wrap {
      display: block;
    }
    
    &__content {
      padding-bottom: 3.6rem;
    }
  }
}

@media @xs-max {
  .import-products {
    
    &__toggle {
      width: 100%;
      
    }
    
    &._open &__toggle {
      padding-bottom: 1.2rem;
    }
    
    &__content-wrap {
      display: none;
      padding: 0;
    }
  
    &__content {
      margin-bottom: 2rem;
      padding: 1rem 2rem 5rem;
      border-top-left-radius: 0;
    }
    
    &__inputs-wrap {
      flex-direction: column;
    }
    
    &__or {
      margin: 1rem 0 0.2rem;
      align-self: center;
    }
    
    &__note {
      margin: 4rem 0 0;
      text-align: center;
    }
  }
}
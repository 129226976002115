@media @md-max {
  .user-bar {
    &__user-info {
      margin: 0 0.5rem;
    }
    
    &__user-info-wrap {
      width: auto;
      margin-right: 1.5rem;
    }

    &__sign-in {
      margin-right: 0;

      svg {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 1160px) {
  .user-bar {
  
    &__user-info {
     //padding: 0;
    }
  }
}

@media @xs-max {
  .user-bar {

    &__user-info {
      margin: 0 2rem;
    }
  }
}
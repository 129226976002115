.steps-tabs {
  display: flex;
  
  &__tab {
    display: block;
    position: relative;
    border: 0.1rem solid #493752;
    border-radius: @radius + 0.1rem;
    width: 20%;
    flex: 1;
    margin-right: 3.5rem;
    padding: 1.5rem 5rem 3.2rem 1.8rem;
    
    transition: @time;
    
    &:last-child {
      margin-right: 0;
    }
    
    &._active {
      background: @color-mint;
      border-color: @color-mint;
      box-shadow: 0 1.6rem 5rem rgba(0, 171, 186, 0.44);
    }
    
    &._filled {
      border-color: @color-mint;
    }
    
    &:not(._disabled):hover {
      border-color: @color-mint;
    }
  }
  
  &__title {
    position: relative;
    z-index: 42;
    font-size: 2.7rem;
    font-family: @oswald;
    font-weight: @regular;
    text-transform: uppercase;
    
    transition: color @time;
  }
  
  &__tab:not(._active):not(._disabled):not(._filled):hover &__title {
    color: @color-blue;
  }
  
  &__tab._disabled {
    pointer-events: none;
    border-color: #493752;
    .steps-tabs__title {
      color: #493752;
    }
    
    .steps-tabs__list {
      li {
        color: #493752;
      }
    }
  }
  
  &__tick {
    position: absolute;
    z-index: 42;
    top: 50%;
    right: 1.8rem;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    
    transition: @time;
   
    & svg {
      width: 1.4rem;
      height: 1.1rem;
      fill: @color-mint
    }
  }
  
  &__tab._active &__tick svg {
    fill: white
  }
  
  &__tab._filled &__tick {
    visibility: visible;
    opacity: 1;
  }
  
  &__list {
    position: absolute;
    top: 100%;
    left: 1.8rem;
    height: 2.9rem;
    transform: translateY(-2.9rem);
    overflow: hidden;
    
    & li {
      margin-bottom: 0.4rem;
      
      color: @color-violet-lightest;
      font-size: 1.2rem;
      
      transition: color @time;
    }
    
    & li:last-child {
      margin-bottom: 0;
    }
    
    &._main::after {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, fade(@color-violet-darklight, 0) 0.8rem, @color-violet-darklight);
    }
  }
  
  &__tab:not(._active):not(._disabled):not(._filled):hover &__list li {
    color: @color-blue;
  }
  
  &__tab._active &__list li {
    color: white;
  }
  
  &__tab._active &__list._main {
  
  
    &::after {
      background: linear-gradient(to bottom, fade(@color-mint, 0) 0.8rem, @color-mint);
    }
  }
  
  &__tab._filled &__list {
    //display: none;
  }
  
  
  &__dropdown {
    //display: none;
    position: absolute;
    z-index: 41;
    left: -0.1rem;
    top: -0.1rem;
    background: @color-violet-lightdark;
    width: calc(~"100% + " 0.2rem);
    min-height:  calc(~"100% + " 0.2rem);
    border-radius: @radius;
    //opacity: 0.5;
    padding: 5rem 1.9rem 2rem;
    box-shadow: 0 1.6rem 5.4rem rgba(0, 0, 0, 0.3);
    visibility: hidden;
    opacity: 0;
    
    transition: @time;
  }
  
  &__tab._active  &__dropdown {
    background-color: @color-mint;
  }
  
  &__tab._filled:hover &__dropdown {
    visibility: visible;
    opacity: 1;
  }
  
  &__dropdown &__list {
    position: static;
    transform: none;
    height: auto;
  }
  
  &__closed-ico {
    position: absolute;
    top: 100%;
    left: 1.8rem;
    transform: translateY(-3.2rem);
    
    & svg {
      width: 1rem;
      height: 1rem;
      fill: #493752
    }
  }
}
.item-lang {
	display: inline-flex;
	.font();
	align-items: baseline;
	
	&__lang {
		display: inline-block;
		font: inherit;
		font-size: 1.2rem;
		color: @color-blue;
	}
	
	&__btn-del {
		padding: 0.5rem;
		border: 0.1rem solid #5d4d65;
		border-radius: @radius;
		
		font-size: 1.6rem;
		margin-left: 1rem;
		//top: 0.2rem;
		margin-bottom: -.2rem;
		&:hover {
			background-color: #5d4d65;
			
			color: white;
		}
	}
}
@media @sm-max {
  .search-main {
    padding-left: 1rem;
    
    &__select {
      width: 50%;
      
      text-transform: none;
    }
  }
}
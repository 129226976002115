.input {
  display: inline-block;
  .font();
  
  &__input-wrap {
    font: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  &__title {
    display: inline-block;
    
    margin-bottom: 1.2rem;
    width: 100%;
    
    font: inherit;
    color: fade(white, 60);
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    
    &._row {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  
  &__list-item {
    margin-bottom: 1.2rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__list._row &__list-item {
    margin-right: 2.8rem;
    
    &:last-child {
      margin-right: 0;
    }
  }

  &__input {
    width: 100%;
    padding: @input-padding;
    
    font: inherit;
    text-overflow: ellipsis;
    resize: none;
    &--edit {
      padding-right: 6rem;
    }
    
    &:disabled {
      opacity: 0.2;
    }
  }
  
  &__error {
    display: block;
    overflow: hidden;
    max-height: 0;
    margin: 0;
    
    font: inherit;
    font-size: 1.2rem;
    color: @color-orange;
    
    transition: @time;
  }
  
  &._error &__error {
    max-height: 7rem;
    margin-top: 0.3rem;
  }
  
  //--new
  
  &__row {
    flex-grow: 1;
    font: inherit;
  }
  
  &__edit {
    cursor: pointer;
    flex-shrink: 0;
    position: absolute;
    right: 1rem;
  
    .font();
    font-size: 1.4rem;
    color: @color-violet-lightest;
  
    &:hover {
      color: @color-violet-lightest2;
    }
    
    svg {
      fill: white;
			margin-right: .6rem;
    }
  }
  
  &__edit--save {
    svg {
      fill: @color-mint;
    }
  }
  
  &__input-ico {
    position: absolute;
    z-index: 7;
    right: 1rem;
    width: 1.7rem;
    height: 1.7rem;
    pointer-events: none;
    fill: @color-violet-darklight;
    transition: fill @time;
  }
  
  &__input:focus ~ &__input-ico {
    fill: @color-mint;
  }
  
  &__section-help {
    margin-left: .8rem;
    vertical-align: -0.05em;
  }
  
  &__head {
    margin-bottom: 1.2rem;
    font: inherit;
    display: flex;
    align-items: flex-end;
    &>* {
      margin-bottom: 0;
    }
    
    .input__title {
      width: auto;
    }
  }
  
  &__lang-wrap {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    font: inherit;
  }
  
  &__lang {
    display: inline-block;
    font: inherit;
    font-size: 1.2rem;
    color: @color-blue;
  }
  
  &__btn-del {
    padding: 0.5rem;
    border: 0.1rem solid #5d4d65;
    border-radius: @radius;
    
    font-size: 1.6rem;
    margin-left: 1rem;
    //top: 0.2rem;
    margin-bottom: -.2rem;
    &:hover {
      background-color: #5d4d65;
      
      color: white;
    }
  }
  
  &--decor-top {
    .input__title {
      max-width: 48%;
    }
    
    .input__input-wrap {
      &::before {
        position: absolute;
        content: '';
        border-bottom: 1.5rem solid white;
        border-left: 1.3rem solid transparent;
        border-right: 1.3rem solid transparent;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__input-ico ~ &__input {
    padding-right: 3rem;
  }

  &__note {
    margin: 0.9rem 0 0;

    font-size: 1.2rem;
    color: fade(white, 60%)
  }
}

@import "select";
@media @xs-max {
  .user-menu {
    padding: 3rem @side-padding-mob;
    background-color: @transparent;
    
    &__list {
      margin: 0;
      padding: 0;
    }
  
    &__item {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 2.2rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    &__link {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-right: 0.8rem;
      
      & svg {
        top: 0.5rem;
      }
    }
  }
}
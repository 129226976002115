.nav-circle {
	display: inline-block;
	background-color: @transparent;
	border: none;
	width: 3.8rem;
	height: 3.8rem;
	cursor: pointer;
	font-size: 0;
	line-height: 0;
	opacity: .3;
	transition: opacity @time;
	
	svg {
		width: 100%;
		height: 100%;
		fill: white;
		
	}
	
	&._next {
		svg {
			transform: scale(-1,1);
		}
	}
	
	&:hover {
		opacity: .8;
	}
	
	&._disabled {
		opacity: .1;
		pointer-events: none;
	}
	
	&._lock {
		pointer-events: none;
		visibility: hidden;
	}
}
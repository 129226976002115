.download-block {
	.font();
	
	&__head {
		margin-bottom: 1.2rem;
		font: inherit;
		display: flex;
		align-items: flex-end;
		&>* {
			margin-bottom: 0;
		}
	}
	
	&__section-help {
		margin-left: .8rem;
		vertical-align: -0.05em;
	}
	
	&__title {
		display: inline-block;
		width: 100%;
		font: inherit;
		color: fade(white, 60);
	}
	
	&__inner {
		font: inherit;
		background-color: #362940;
	}
	
	&__dropzone {
		padding: 3rem;
		
		.dropzone__area::before {
			padding-top: 0;
			height: 18rem;
		}
		
		.dropzone__btn {
			box-shadow: 0 1.6rem 5rem rgba(0,171,186,.44);
		}
	}
	
	&__file-list {
		font: inherit;
		margin-top: -3rem;
	}
	
	&__file-item {
		font: inherit;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		align-items: baseline;
		padding: 2.6rem 4.5rem 2.6rem 3rem;
		border-bottom: 1px solid @color-violet-darklight;
		&:last-child {
			border-bottom: 0;
		}
	}
	
	&__file-name {
		font: inherit;
		font-size: 1.6rem;
		margin-right: 1.5rem;
	}
	
	&__file-size {
		font: inherit;
		color: fade(white, 30);
	}
	
	&__file-del {
		position: absolute;
		right: 3rem;
		top: 3rem;
		
		color: @color-orange;
		
		&:hover {
			color: white;
		}
	}
}
.similar-slider {
	.font;
	margin-left: 10rem;
	position: relative;
	
	&::before {
		content: '';
		position: absolute;
		width: 10.8rem;
		left: 0;
		top: 5rem;
		bottom: 0;
		background: linear-gradient(140deg, rgb(96,83,105) 35%, rgb(237,237,241) 80%);
		z-index: 7;
	}
	
	&__container {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
		position: relative;
		z-index: 10;
		overflow: hidden;
		pointer-events: none;
		padding-top: 5rem;
		padding-bottom: 5rem;
		padding-left: 3rem;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	
	&__wrap {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
		position: relative;
		z-index: 5;
		pointer-events: auto;
	}
	
	&__slide {
		flex-shrink: 0;
		width: 25rem;
		margin-right: 3.5rem;
		
		transition: @time-modal;
		
		//&:not(.swiper-slide-visible) .card__to-cart {
		//	 &::before {
		//		 box-shadow: none;
		//	 }
		//}
		//&:hover {
		//	transform: none;
		//}
	}
	
	&__navs {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		
		
		.slider-navs__btn {
			background-color: #605369;
		}
	}
}
.index-page {
  padding-top: 0;
  
  &__security {
    margin-top: 4.5rem;
    margin-bottom: 8rem;
  }
  
  &__game-slider {
  
  }
  
  &__top {
    margin-bottom: 3.5rem;
  }
  
  &__partners {
  
  }
  
  &__news {
    margin-top: 10rem;
  }
}

@media @sm {
  .index-page {
    padding-top: 0;
    //overflow: hidden;
    
    &__security {
      &::before {
        position: absolute;
        content: '';
        width: 96rem;
        height: 96rem;
        background-image: url(../img/png/highlight.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        left: 0;
        top: -50rem;
        z-index: 6;
        pointer-events: none;
      }
    }
    
    &__game-slider {
      &::before {
        position: absolute;
        content: '';
        width: 114rem;
        height: 125.2rem;
        background-image: url(../img/png/highlight-blue-big.png);
        background-repeat: no-repeat;
        background-position: right top;
        background-size: contain;
        right: 0;
        top: -25rem;
        z-index: 6;
        pointer-events: none;
      }
    }
  
    &__partners {
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 145.8rem;
        background-image: url(../img/png/highlight-mint-big.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        left: 50%;
        transform: translateX(-50%);
        top: -77rem;
        z-index: 6;
        pointer-events: none;
      }
    }
  
    &__news {
      &::before {
        position: absolute;
        content: '';
        width: 122.3rem;
        height: 103.9rem;
        background-image: url(../img/png/highlight-violet-big.png);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
        right: 0;
        top: -13rem;
        z-index: 6;
        pointer-events: none;
      }
    }
  }
}
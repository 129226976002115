.add-lang {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.font();
	width: 100%;
	
	&__label {
		font: inherit;
		margin: 0 1rem 0 0;
		color: fade(white, 60);
		text-align: right;
		display: inline-flex;
		align-items: center;
	}
	
	&__help {
		margin-right: 1rem;
		text-align: left;
	}
	
	&__select-wrap {
		display: flex;
	}
	
	&__select {
		width: 14rem;
	}
	
	&__btn {
		width: 3.9rem;
		height: 3.9rem;
		background: @color-mint;
		border-radius: 0 @radius @radius 0;
		flex-shrink: 0;
		
		& svg {
			width: 1.1rem;
			height: 1.1rem;
			fill: white;
		}
		
		&:hover {
			background-color: @color-mint-light;
		}
	}
}
@media @md-max {
  .page-user {
    
    &__title {
      padding: 0 @side-padding-md;
    }
  }
}

@media @sm-max {
  .page-user {
    padding-bottom: 4rem;
    
    &__title {
      margin-bottom: 2.2rem;
      padding: 0 @side-padding-mob;
    }
  }
}
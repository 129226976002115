.card {
  display: flex;
  flex-direction: column;
  .font;

  &__img-wrap {
    width: 100%;
    height: 14rem;
    position: relative;
    z-index: 5;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    flex-grow: 1;
    font: inherit;
    color: @color-violet-darklight;
    background-color: white;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 1.5rem 2.5rem .9rem;
    flex-grow: 1;
  }

  &__label {
    display: inline-block;
    font-size: 1.2rem;
  }

  &__title {
    .h3;
    font-size: 1.6rem;
    color: @color-violet-darklight;
    margin-bottom: .5rem;

    a {
      font: inherit;
      color: inherit;
    }
  }

  &__to-cart {
    width: 100%;

    &::before {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    svg {
      width: 1.5rem;
      height: 1.4rem;
      margin-right: .8rem;
    }
  }

  &__price-box {
    font: inherit;
    color: @color-violet-darklight;
    padding: .9rem 2.5rem 1.5rem;
  }

  &__available {
    display: flex;
    align-items: baseline;
    font: inherit;
    font-size: 1.2rem;
    color: @color-violet-darklight;
    margin-bottom: .3rem;

    svg {
      width: 1.4rem;
      height: 1.1rem;
      margin-right: .5rem;
      fill: @color-mint;
    }
  }

  &__price {
    .h3;
    color: inherit;
    word-break: break-word;
  }

  &__price-val {
    margin-right: .5rem;
  }

  &__price-coin {
    margin-right: 1rem;
    top: .1em;
  }

  &__price-val-conv {
    font-size: 12/20em;
    font-weight: @regular;
    opacity: .6;
  }

  &__seller {
    display: flex;
    font: inherit;
    color: fade(@color-violet-darklight, 60%);

    padding: 1.2rem 2rem;
    border-top: 1px solid @color-grey-light;
  }

  &__seller-avatar {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    border: .1rem solid #c0c3cb;
    padding: .1rem;
    flex-shrink: 0;

    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__seller-name-wrap {
    font: inherit;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: .7rem;
    width: 50%;
    flex-grow: 1;
  }

  &__seller-name {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__seller-rating {
    display: inline-block;
    font-size: 1.2rem;
    color: fade(@color-violet-darklight, 60%);
  }

  &__seller._online {
    color: inherit;

    .card__seller-avatar {
      border-color: @color-mint;
    }
  }
}

.card--small {
  .card__img-wrap {
    height: 9.6rem;
  }

  .card__content {
    background-color: @color-violet-lightdark;
  }

  .card__header,
  .card__price-box {
    padding-left: 0;
    padding-right: 0;
  }

  .card__title,
  .card__price {
    color: white;
    font-weight: normal;
    text-transform: none;
  }

  .card__title {
    a:hover {
      color: @color-blue;
    }
  }
}

@media @sm {
  .card {
    &__title {
      a:hover {
        color: @color-mint;
      }
    }

    &__seller {
      display: flex;
      font: inherit;
      color: fade(@color-violet-darklight, 60%);

      padding: 1.2rem 2rem;
      border-top: 1px solid @color-grey-light;

      &:hover {
        color: @color-mint;
      }
    }

    &__seller._online {
      color: inherit;

      &:hover {
        color: @color-mint;
      }

      .card__seller-avatar {
        border-color: @color-mint;
      }
    }
  }

  .card {
    transform: translate3d(0, 0, 0);
    transition: transform @time;

    &:hover {
      transform: scale(1.05);
    }
  }

  .card--small {

    .card__title {

      a:hover {
        color: @color-blue;
      }
    }
  }
}
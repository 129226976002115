.products-list-toolbar {
  display: flex;
  align-items: center;
  
  &__editor {
    display: flex;
    align-items: center;
    border: 0.1rem solid #493752;
    border-radius: @radius;
    padding: 0.8rem 1rem;
    min-width: 18.2rem;
  }

  &__editor-selection {
    flex-shrink: 0;
  }
  
  &__editor-btn {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    margin-left: 1.8rem;
    overflow: hidden;
    
    
    .font();
    color: #a27ba8;
    
    & svg {
      flex-shrink: 0;
      fill: white;
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.6rem;
    }
    
    & > span {
      .clipText();
    }
  }
  
  &__editor._empty &__editor-btn {
    display: none;
  }

  &__editor-no-selected {
    display: none;
    flex-grow: 1;
    text-align: center;
    .clipText();
  }

  &__editor._empty &__editor-no-selected {
    display: block;
    margin-left: 1rem;

    .font();
    color: #a27ba8;
  }
  
  &__types-wrap {
    display: flex;
    margin: 0 auto;
  }
  
  &__types {
    width: 19rem;
    margin: 0 1rem;
  }
  
  &__sort {
    width: 19rem;
  }
  
  &__search {
    flex-shrink: 0;
    margin-left: 2rem;
  }
}
@media @sm-max {
	.sell-order {
		&__import-products {
			.import-products__content-wrap {
				display: none;
			}
		}
	}
}

@media @xs-max {
	.sell-order {
		background-position: -30rem top,calc(100% + 10rem) 140rem;
		
		&__title {
			padding-right: 0;
			font-size: 2.7rem;
			margin-bottom: 2.5rem;
		}
		
		&__import-products {
			margin-top: 0;
			margin-bottom: 3rem;
		}
		
		&__steps-tabs {
			margin-bottom: .5rem;
		}
		
		&__product-setup {
			width: auto;
			margin-left: -@side-padding-mob;
			margin-right: -@side-padding-mob;
			margin-bottom: 2rem;
		}
		
		&__add-tags {
			margin-bottom: 4rem;
		}
		
		&__tabs-select {
			margin-bottom: 3.5rem;
		}
	}
}
.star-rating {
	display: flex;
	align-items: center;
	padding: 0.6rem;

	.font;

	.no-selection();

	&__stars {
		display: flex;
		flex-shrink: 0;
	}
	
	&__star {
		display: inline-block;
		font-size: 0;
		line-height: 0;
		width: 1.2rem;
		height: 1.2rem;
		color: @color-grey-light-2;
		transition: color @time;
		margin-right: .3rem;
		flex-shrink: 0;
		&:last-child {
			margin-right: 0;
		}
		
		svg {
			width: 100%;
			height: 100%;
			fill: currentColor;
		}
	}
	
	&__star._active {
		color: @color-yellow;
	}
	
	&__review-amount {
		margin-left: 1.2rem;
		font-size: 1.2rem;
	}
}

.star-rating--middle {
	.star-rating__star {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: .4rem;
		color: fade(white, 15%);
		&:last-child {
			margin-right: 0;
		}
		
		
		
		&._active {
			color: @color-yellow;
		}
	}
}
@media @xs-max {
  .product-gallery {
    
    &__navs {
      left: 0;
    }

    &__zoom {
      display: none;
    }
  }
}
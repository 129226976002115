.accordion {
	&__item {
		width: 100%;
		padding-bottom: 2.9rem;
		margin-bottom: 2.6rem;
		border-bottom: 1px solid #493752;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&._open {
			.section-header__toggle {
				background-color: #55375d;
				border-color: #55375d;
				&::before  {
					transform: rotate(180deg);
				}
			}
		}
	}
	
	&__item-header {
		margin-bottom: 0;
	}
	
	&__item-content {
		margin-top: 2.5rem;
		width: 100%;
		display: none;
	}
}
@media @xs-max {
	.add-lang {
		flex-wrap: wrap;
		&__label {
			margin-right: 0;
			margin-bottom: .8rem;
			width: 100%;
			justify-content: flex-end;
		}
	}
}
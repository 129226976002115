.payments {
  display: flex;
  flex-wrap: wrap;
  
  &__item {
    width: 2.6rem;
    height: 2.6rem;
    background: no-repeat center;
    background-size: contain;
    margin-right: 1.4rem;
    
    opacity: 0.6;
    
    font-size: 0;
    
    &:last-child {
      margin-right: 0;
    }
  }
}
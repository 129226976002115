.custom-shop-page {
	padding-top: 0;
	
	&__big-banner {
		width: 100%;
	}
	
	&__security {
		
		//&::before {
		//	position: absolute;
		//
		//	content: '';
		//	width: 109.4rem;
		//	height: 109.4rem;
		//	background: radial-gradient(ellipse at center, #122c45, @transparent 60%);
		//	z-index: 1;
		//}
	}
}
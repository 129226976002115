.g-link {
	display: inline-block;
	.font;
	font-size: inherit;
	color: @color-violet-light-2;
	transition: color @time;
	
	&:hover {
		color: @color-mint;
	}
	
	&._disabled {
		color: fade(@color-violet-darklight, 60%);
		//pointer-events: none;
	}
}

.g-link._arrow-right svg {
	display: inline-block;
	width: .4rem;
	height: .6rem;
	vertical-align: middle;
	fill: currentColor;
	margin-left: .5rem;
}

.g-link--light {
	color: @color-violet-lightest;
	
	&:hover {
		color: @color-violet-lightest2;
	}
}

.g-link--blue {
	color: @color-blue;
	
	&:hover {
		color: @color-blue-light;
	}
}
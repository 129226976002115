@media @xs-max {
  .review-group {
  
    &__new-review-top {
      &::before {
        right: 2rem;
      }
    }
  
    &__new-review-rating {
      position: absolute;
      top: 100%;
      margin-top: .5rem;
      left: 50%;
      transform: translateX(-50%);
  
      .star-rating__star {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }
    }
  
    &__new-review-block {
      padding-top: 6rem;
    }

    &__list {

    }

    &__item {

    }

    &__review-article,
    &__review-comment {

    }

    &__review-comment {
      padding-left: 3rem;
      padding-top: 2.5rem;
      border-top: 1px solid #493752;
      .review-article__head,
      .review-article__text {

      }

      .review-article__head {
        min-height: auto;
      }

      .review-article__name {

      }
    }

    .review-article__head {

    }

    .review-article__time {

    }

    .review-article__name {

    }
  
    .catalog-pagination {
      margin-top: 4rem;
    }
  }
}
.buying {
  position: relative;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.1rem;
    min-width: 1px;
    height: calc(~"100% - 4.9rem");
    background: linear-gradient(to bottom, #00aba5, #55375d);
  }
  
  &::after {
    left: auto;
    right: 0;
  }
  
  &._cut::before,
  &::after {
    background: linear-gradient(to bottom, #00aba5, #db5121);
  }
  
  &__decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.2rem;
    height: 0.1rem;
    min-height: 1px;
    background: #00aba5;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      background: #00aba5;
      box-shadow: 0 0.2rem 1rem #00aba5;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    
    &._left {
    
    }
    
    &._left::before,
    &._right {
      left: auto;
      right: 0;
    }
  }
  
  &__title {
    padding: 0 3.5rem;
    margin-bottom: 1rem;
    transform: translateY(-1.3rem);
    
    text-shadow: 0.1rem 0.3rem 1rem #00aba5;;
    
    .font();
    font-weight: @semibold;
    font-size: 2rem;
    color: #2dd6dc;
    text-align: center;
  }
  
  &__main {
    padding: 0 3rem;
  }
  
  &__group {
    width: 100%;
    margin-bottom: 2.8rem;
  }
  
  &__info {
    margin: 0 0.1rem;
    padding: 1.2rem 3rem;
    display: flex;
    background: @color-violet-lighter;
  }
  
  &__info-item {
    width: 50%;
    display: flex;
    
    &:first-child {
      padding-right: 2rem;
    }
  
    & svg {
      flex-shrink: 0;
      width: 1.8rem;
      height: 1.8rem;
      fill: white;
      margin-right: 0.8rem;
    }
  }
  
  &__info-item-content {
    flex-grow: 1;
  }
  
  &__info-item-title,
  &__info-item p {
    .font();
  }
  
  &__info-item p {
    color: @color-violet-lightest;
  }
  
  &__value-wrap {
    padding: 1.8rem 3rem;
    display: flex;
    justify-content: space-between;
  }
  
  &__amount-wrap,
  &__price-wrap {
    width: 47%;
    .font();
    
  }
  
  &__bonuses {
    margin-bottom: 0.7rem;
    
    font: inherit;
    color: @color-blue;
  }
  
  &__amount {
    margin-bottom: 0.6rem;
  }
  
  &__available {
    display: flex;
    align-items: center;
  
    font: inherit;
    color: fade(white, 60);
    font-size: 1.2rem;
    
    & svg {
      margin-right: 0.5rem;
      width: 1.4rem;
      height: 1.1rem;
      fill: @color-blue
    }
  }
  
  &__price-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  &__price {
    display: inline-block;
    position: relative;
    padding-right: 2.2rem;
    
    font: inherit;
    color: white;
    font-weight: @semibold;
    font-size: 2rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.7rem;
      height: 1.7rem;
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
  
  &__old-price {
    font: inherit;
    font-size: 1.2rem;
    color: fade(white, 60);
  }
  
  &__btns-wrap {
    display: flex;
  }
  
  &__btn {
    width: 50%;
    flex-grow: 1;
  
    &:first-child {
      z-index: 6;
    }
  
    &::before {
      border-radius: 0;
    }
    
    &:first-child::before {
      border-bottom-left-radius: @radius;
    }
    
    &:last-child::before {
      border-bottom-right-radius: @radius;
    }
  }
  
  //---new
  
  &__header {
    display: flex;
    margin-bottom: 1rem;
    
    .buying__title {
      padding: 0 1rem;
      margin-bottom: 0;
    }
    
    .buying__decor {
      position: relative;
      flex-grow: 1;
      min-width: 2.2rem;
    }
  }
  
  &__main-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  
    .buying__group {
      width: 10%;
      flex: 1 1 25%;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  
  &__info--mod {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  &__info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  
  &__info-input {
    width: 10%;
    flex: 0 1 56.6%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;

    .input__title {
      width: auto;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  
  &__info-items {
    display: flex;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    .buying__info-item {
      width: auto;
    }
  
    .buying__info-item-content {
      display: flex;
      p {

      }
    }
    
    .buying__info-item-title {
      margin-right: .5rem;
      white-space: nowrap;

    }
  }
  
  &__discounts {
    padding: 0 3rem;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }
  
  &__values-block {
    padding: 0 3rem;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }
}
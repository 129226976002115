.user-count {
  display: flex;
  align-items: center;
  .font;

  &__ico {
    width: 1.7rem;
    flex-shrink: 0;
  }

  &__value {
    font: inherit;
    color: white;
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: .02em;
    text-transform: uppercase;
    margin: 0 .5rem;
    span {
      font-size: 12/14em;
      opacity: .6;
    }
  }

  &__btn {
    width: 1.7rem;
    height: 1.7rem;
    border: 0;
    background-color: @transparent;
    cursor: pointer;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      fill: white;
      transition: opacity @time;
    }

    .svg-def {
      width: 1.2rem;
      height: 1.2rem;
      opacity: .6;
    }

    .svg-hover {
      opacity: 0;
      width: 1.6rem;
      height: 1.6rem;
      fill-rule: evenodd
    }
  }

  &__btn:hover {
    .svg-def {
      opacity: 0;
    }

    .svg-hover {
      opacity: 1;
    }
  }
}
.partners {
	margin-top: 8.5rem;
	margin-bottom: 10rem;
	position: relative;
	
	&__inner {
		position: relative;
		z-index: 7;
	}
	
	&__title {
		.h2;
		font-size: 3rem;
		text-align: center;
		margin-bottom: 4rem;
	}
	
	&__carousel {
		width: 100%;
		display: flex;
		justify-content: flex-start;
	}
	
	&__carousel-wrap {
		display: flex;
	}
	
	&__slide {
		display: block;
		width: 22rem;
		margin-right: 3rem;
		flex-shrink: 0;
		
		img {
			width: 100%;
		}
	}
}
@media @lg-max {
	.sell-order-page {
		
		
		&__sell-order {
			padding-right: 7rem;
		}
	}
}

@media @md-max {
	.sell-order-page {
		
		&__sell-order {
			padding-right: 3rem;
		}
		
		&__side-col {
			width: 29rem;
		}
	}
}

@media @sm-max {
	.sell-order-page {
		
		&__sell-order {
			padding-right: 3rem;
		}
		
		&__side-col {
			width: 0;
		}
	}
}

@media @xs-max {
	.sell-order-page {
		
		&__sell-order {
			padding-right: @side-padding-mob;
			padding-top: 1.5rem;
			padding-bottom: 4.5rem;
		}
		
		&__side-col {
			width: 0;
		}
	}
}
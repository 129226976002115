.discounts {
	.font();
	
	&__row {
		display: flex;
	}
	
	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 33.33%;
		width: calc(~'(100%) / 3');
		padding: 1.8rem 3rem;
		border: 1px solid @color-violet-lightdark-3;
		border-left-width: 0;
		
		&--step-2 {
			background-color: fade(#493752, 20);
			.discounts__text {
				color: @color-blue;
			}
		}
		
		&--step-3 {
			background-color: fade(#493752, 40);
			.discounts__text {
				color: @color-blue;
			}
		}
		
		&:first-of-type {
			border-top-left-radius: @radius;
			border-bottom-left-radius: @radius;
			border-left-width: 1px;
		}
		
		&:last-of-type {
			border-top-right-radius: @radius;
			border-bottom-right-radius: @radius;
			
			.discounts__item-badge {
				border-top-right-radius: @radius;
			}
		}
	}
	
	&__price-block {
		margin-bottom: auto;
	}
	
	&__pattern {
		width: 0;
		display: flex;
		flex-grow: 1;
		background: repeating-linear-gradient(
						-45deg,
						transparent .4rem,
						transparent 1rem,
						@color-violet-lightdark-3 1.2rem,
						transparent 1rem,
		);
	}
	
	&__text {
		font: inherit;
		font-size: 1.4rem;
		color: fade(white, 60);
		margin-top: 1.5rem;
	}
	
	&__item-badge {
		font: inherit;
		font-size: 1.4rem;
		color: fade(white, 60);
		
		position: absolute;
		top: -1px;
		right: -1px;
		background-color: @color-violet-lightdark-3;
		border-bottom-left-radius: @radius;
		padding: .3rem 1rem .4rem;
	}
	
	
}
.values-block {
	.font();
	display: flex;
	
	&__item {
		position: relative;
		flex: 1 1 50%;
		font: inherit;
		//margin-right: 2rem;
		
		&--left {
			
			.values-block__input-wrap {
				margin-right: 2.2rem;
				border: .1rem solid @color-mint;
				box-shadow: 0 0 3rem 0.1rem fade(@color-mint, 40);
				
				&::before {
					content: '';
					position: absolute;
					right: -1.2rem;
					top: -1px;
					bottom: -1px;
					width: 3.5rem;
					background-color: white;
					border: .1rem solid @color-mint;
					border-left-width: 0;
					box-shadow: 0 0 3rem 0.1rem fade(@color-mint, 35);
					transform: skewX(-26.5deg);
				}
				
				&::after {
					font: inherit;
					font-size: 1.4rem;
					line-height: 1;
					
					content: '=';
					position: absolute;
					right: -2.7rem;
				}
				
			}
			
			.values-block__input {
				padding-right: 0;
				//border: .1rem solid @color-mint;
				//box-shadow: 0 0 3rem 0.1rem fade(@color-mint, 50);
				
			}
			
			.values-block__input-ico {
				right: 0;
			}
		}
		
		&--right {
			
			.values-block__input-wrap {
				margin-left: 2.2rem;
				border: .1rem solid @color-orange;
				box-shadow: 0 0 3rem 0.1rem fade(@color-orange, 40);
				
				&::before {
					content: '';
					position: absolute;
					left: -1.2rem;
					top: -1px;
					bottom: -1px;
					width: 3.5rem;
					background-color: white;
					border: .1rem solid @color-orange;
					border-right-width: 0;
					box-shadow: 0 0 3rem 0.1rem fade(@color-orange, 35);
					transform: skewX(-26.5deg);
				}
				
			}
			
			.values-block__item-head {
				margin-left: 2.2rem;
			}
			
			.values-block__input {
				padding-left: 0;
			}
		}
	}
	
	&__item-head {
		font: inherit;
		line-height: 1.25;
		font-size: 1.4rem;
		margin-bottom: 0.7rem;
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 5;
	}
	
	&__label {
		font: inherit;
		font-size: 1.4rem;
		display: inline-block;
		color: fade(white, 60);
		b {
			font: inherit;
			color: white;
		}
	}
	
	&__bonuses {
		font: inherit;
		color: @color-blue;
	}
	&__available {
		display: flex;
		align-items: center;
		
		font: inherit;
		color: fade(white, 60);
		font-size: 1.2rem;
		
		&--right {
			margin-left: auto;
		}
		
		& svg {
			margin-right: 0.5rem;
			width: 1.4rem;
			height: 1.1rem;
			fill: @color-blue
		}
	}
	
	&__input-wrap {
		position: relative;
		z-index: 3;
		display: flex;
		align-items: center;
		font: inherit;
	}
	
	&__input {
		width: 100%;
		padding: @input-padding;
		background-color: white;
		position: relative;
		z-index: 2;
		font: inherit;
		
		&:disabled {
			opacity: 0.2;
		}
	}
	
	&__item-bottom {
		display: flex;
		margin-top: .7rem;
		font: inherit;
		font-size: 1.4rem;
		line-height: 1.25;
		position: relative;
		z-index: 5;
	}
	
	&__input-decor {
		display: none;
		align-items: center;
		content: '';
		position: absolute;
		bottom: 0;
		left: 3.8rem;
		right: 3.8rem;
		height: 6.7rem;
		transform: skewX(-26.5deg);
		z-index: 1;
		
		span {
			position: absolute;
			content: '';
			display: block;
			background-color: @color-violet-light-2;
			
			&:nth-child(1) {
				width: 1px;
				height: 50%;
				left: 0;
				bottom: 0;
			}
			
			&:nth-child(2) {
				height: 1px;
				left: 0;
				right: 0;
			}
			
			&:nth-child(3) {
				width: 1px;
				height: 50%;
				right: 0;
				top: 0;
			}
		}
	}
	
	&__input-ico {
		position: absolute;
		z-index: 7;
		right: 1rem;
		width: 1.7rem;
		height: 1.7rem;
	}


}

.values-block--small {
	display: block;

	.values-block__item {
		flex: 0 1 auto;
		padding-bottom: 4.5rem;

		&:last-child {
			padding-bottom: 0;
		}

		&--left {

			.values-block__input-wrap {


				&::after {
					display: none;
				}

			}

			.values-block__input {
				padding-right: 0;
			}
		}
	}
	
	.values-block__input-decor {
		display: flex;
	}

	.values-block__item-head {
		flex-wrap: wrap-reverse;
	}

	.values-block__item-bottom {
		flex-direction: column;
	}

	.values-block__available--right {
		margin-left: 0;
	}
}
@media @xs-max {
  .user-product-menu {
  
    &__separator {
      display: none;
    }
    
    a&__link {
      margin: 0 2rem 0.8rem 0;

      font-size: 1.4rem;
      font-family: @source-sans;

      &:last-child {
        margin-right: 0;
      }
    }
    
    &__link._active {
      order: 50;
      width: 100%;
      margin-top: 0.4rem;
    }

    &__link svg {
      width: 0.4rem;
      height: 0.6rem;
      vertical-align: 0.2rem;
    }
  }
}
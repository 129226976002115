.search {
	//width: 100%;
	position: relative;
	transition: width @time;
	&__input-wrap {
		position: relative;
		font-family: @source-sans;
		font-weight: @regular;
		line-height: @line-height;
		font-size: 1.4rem;
		color: white;
		display: flex;
		align-items: center;
		min-height: 5rem;
		//width: calc(~'25% - .1rem');
		//min-width: 29rem;
		padding: 1.6rem 2rem 1.6rem 3rem;
		//background-color: fade(white, 10%);
		background-color: @color-violet-lighter;
		//cursor: pointer;
		z-index: 6;
		
		//&::before {
		//	position: absolute;
		//	left: 0;
		//	top: 0;
		//	right: 0;
		//	bottom: 0;
		//	content: '';
		//	background-color: @color-violet-lighter;
		//	z-index: 5;
		//}
	}
	&__input {
		display: flex;
		flex-grow: 1;
		font: inherit;
		background-color: @transparent;
		border: none;
		color: inherit;
		transition: color @time;
		cursor: pointer;
		margin-right: 1.5rem;
		position: relative;
		z-index: 9;
		&::placeholder {
			font: inherit;
			color: inherit;
		}
	}
	
	&__submit {
		margin-right: 2rem;
		color: @color-blue;
		z-index: 9;
		svg {
			width: 1.2rem;
			height: 1.3rem;
		}
	}
	
	&__more {
		border: 0;
		color: inherit;
		padding: 0 1rem;
		opacity: .6;
		align-self: stretch;
		position: relative;
		z-index: 9;
		flex-shrink: 0;
		
		span {
			margin-right: 1rem;
		}
		svg {
			width: .4rem;
			height: .6rem;
			//vertical-align: middle;
		}
		
		&:hover {
			opacity: 1;
		}
	}
	
	&__input:hover {
		color: @color-blue;
	}
	
	&__search-prev-results {
		position: absolute;
		width: 100%;
		left: 0;
		top: 100%;
		background: #1f1928;
		display: none;
		z-index: 20;
		
		max-height: 60vh;
	}
}

.search._open {
	width: calc(~'100% - 7rem');
	
	.search__search-prev-results {
		display: flex;
		animation: fadeIn @time-modal;
	}
}
.product-gallery {
  position: relative;
  padding-top: 5rem;
  
  &._disabled {
    padding-top: 0;
  }
  
  &::before {
    content: '';
    position: absolute;
    width: 3rem;
    right: 100%;
    top: 0;
    bottom: 5rem;
    background-color: #3e374a;
    z-index: 6;
  }
  
  &._disabled::before {
    display: none;
  }
  
  &__navs {
    position: absolute;
    top: 0;
    left: -3rem;
    z-index: 8;
    
    .slider-navs__btn {
      background-color: #3e374a;
    }
  }
  
  &._disabled &__navs {
    display: none;
  }
  
  &__container {
    position: relative;
    overflow: hidden;
  }
  
  &__zoom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 14rem;
    background: linear-gradient(to top, rgb(54,41,64) 5%, rgba(54,41,64,0));
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1.6rem;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition: opacity @time;
  }
  
  &__container:hover &__zoom {
    opacity: 0.9;
  }
  
  &__zoom-content {
    display: flex;
    align-items: center;
    
    .font();
    font-size: 1.2rem;
  
    & svg {
      width: 1.2rem;
      height: 1.3rem;
      fill: white;
      margin-right: 0.6rem;
      margin-top: 0.2rem;
    }
  }
  
  &__wrap {
    display: flex;
  }
  
  &__slide {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 100%;
    padding: 28.013% 0;
  }
  
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.input-group {
	width: 100%;
	&>*:last-child {
		margin-bottom: 0;
	}
	
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: -1.5rem -1.5rem 1.5rem;
	}
	
	&__input {
		flex: 1 1 40%;
		width: 40%;
		margin: 1.5rem 1.5rem 0;
	}


	&__delete-input {
		margin-left: 1rem;
		display: flex;

		& .icon-btn__icon {
			height: 3.9rem;
		}
	}
	
	&__add-lang {
		margin-top: 2rem;
		margin-bottom: 1.5rem;
	}

}
@media @sm-max {
	.items-choice {
		&__item {
			width: calc(~'(100% - (3.5rem * 3)) / 3');
		}
	}
}

@media @xs-max {
	.items-choice {
		
		&__container {
			margin-right: -2rem;
			margin-top: -2rem;
		}
		
		&__item {
			width: calc(~'(100% - (2rem * 2)) / 2');
			margin-right: 2rem;
		}
	}
}
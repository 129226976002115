.text {
  
  .font();
	font-size: 1.6rem;
  color: fade(white, 60);
	
	&._small {
		font-size: 1.4rem;
		color: fade(white, 60);
	}
  
  & * {
    font: inherit;
    color: inherit;
  }
  
  & > *:first-child {
    margin-top: 0;
  }
  
  & > *:last-child {
    margin-bottom: 0;
  }
	
	
	&::after {
		content: '';
		display: table;
		width: 100%;
	}
	
	& p,
	& ul,
	& ol,
	& table,
	& img {
		margin: 1.2em 0;
		
		font-size: inherit;
		line-height: inherit;
		color: inherit;
	}
	
	
	// - - - -
	
	
	ul,
	ol {
		font: inherit;
		line-height: 1.2em;
		padding-left: 0.7em;
		margin: 2em 0;
	}

	li {
		font: inherit;
		position: relative;
		padding-left: 1.1em;
		margin-bottom: 0.8em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul li {
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			left: 0;
			top: 0.35em;
			width: .5em;
			height: .5em;
			background: @color-blue;
			border-radius: .6em;
		}
	}

	ol li {
		counter-increment: li;

		&::before {
			position: absolute;
			top: 0;
			right: 100%;
			margin-right: -.6em;
			content: counter(li) ".";
			display: inline-block;
			line-height: inherit;
			width: auto;
			font: inherit;
			//font-size: 24/ 18em;
			color: @color-blue;
		}
	}
	
	
	// - - - -
	
	
	& img {
		display: block;
		margin: 1.8em auto;
		max-width: 100%;
	}
	
	
	// - - - -
	
	
	& table {
		margin: 1.8em 0;
		border-collapse: separate;
		max-width: 100%;
		width: 100%;
		display: block;
		overflow-x: auto;
		border-spacing: 0 0.1rem
	}
	
	& tbody {
	
	}
	
	& tr {
	}
	
	& th,
	& td {
		padding: 1.4rem 1.4rem 1.5rem;
		text-align: left;
		background: @color-violet-lighter;
		border-bottom: 1px solid transparent;
		width: 10%;
		
		&:first-child {
			padding-left: 2.8rem;
		}
		
		&:last-child {
			padding-right: 2.8rem;
		}
	}
	
	& th {
	
	}
	
	& td {
	
	}
	
	& object,
	& video,
	& iframe {
		margin: 1.2em 0;
		max-width: 100%;
	}
	
}
.news {
	margin-top: 12.5rem;
	margin-bottom: 10rem;
	position: relative;
	&__header {
		margin-bottom: 1rem;
		position: relative;
		z-index: 12;
		
		&>*:last-child {
			margin-bottom: 0;
		}
	}
	
	
	
	&__title {
		.h2;
		text-align: center;
		margin-bottom: 3.5rem;
	}
	
	& > .news__title {
		margin-bottom: 1rem;
	}
	
	&__tab-items {
		width: 100%;
		position: relative;
		z-index: 11;
	}
	
	&__tab-item {
		width: 100%;
		display: none;
		//animation-name: fadeIn;
		//animation-duration: .3s;
		
		&._open {
			display: block;
		}
	}
}
.game-slider {
	width: 100%;
	position: relative;
	//margin-top: 17rem;
	//margin-bottom: 25.5rem;
	margin-top: 7rem;
	margin-bottom: 7rem;
	.font;
	
	&__title {
		.h2;
		text-align: center;
	}
	
	&__inner {
		font: inherit;
		color: inherit;
		margin-right: 13rem;
		position: relative;
		z-index: 11;
		
		&::before {
			content: '';
			position: absolute;
			width: 3rem;
			left: 100%;
			top: 5rem;
			bottom: 5rem;
			background-color: @color-violet-lighter;
			z-index: 1;
		}
	}
	
	&__container {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
		position: relative;
		z-index: 11;
		overflow: hidden;
		pointer-events: none;
		padding-top: 5rem;
		padding-bottom: 5rem;
		direction: rtl;
	}
	
	&__wrap {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
		position: relative;
		z-index: 5;
		pointer-events: auto;
	}
	
	&__slide {
		font: inherit;
		color: inherit;
		display: flex;
		width: 27.5rem;
		min-height: 44rem;
		flex-shrink: 0;
		position: relative;
		margin-left: .2rem;
		z-index: 1;
		transition: z-index @time, visibility @time, opacity @time;
		
		//&.swiper-slide-prev {
		//	visibility: hidden;
		//	opacity: 0;
		//}
	}
	
	&__slide-images {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		background-color: #36273e;
		box-shadow: none;
		transform: translate3d(0,0,0);
		transition: top @time,left @time,right @time,bottom @time,box-shadow @time;
	}
	
	&__slide-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		object-fit: cover;
		z-index: 1;
		transition: opacity @time;
	}
	
	&__slide-logo {
		transition: opacity @time;
		position: relative;
		z-index: 5;
	}
	
	&__slide-about {
		font: inherit;
		color: inherit;
		position: relative;
		z-index: 5;
		text-align: center;
		width: 100%;
		padding-bottom: 13rem;
		transition: opacity @time, visibility @time;
		opacity: 0;
		visibility: hidden;
	}
	
	&__slide-title {
		font: inherit;
		color: inherit;
		width: 100%;
		font-size: 1.4rem;
		font-weight: @regular;
		margin: 0;
		text-transform: uppercase;
		padding-bottom: 3.9rem;
		border-bottom: 1px solid fade(white,10%);
		margin-bottom: 3.8rem;
	}
	
	&__slide-list {
		font: inherit;
		color: inherit;
		font-size: 1.4rem;
		font-weight: @regular;
		text-transform: uppercase;
		
		li {
			font: inherit;
			color: inherit;
			margin-bottom: 2.4rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__slide-link {
		font: inherit;
		color: inherit;
		transition: color @time;
		
		&:hover {
			color: @color-blue;
		}
	}
	
	&__navs {
		position: absolute;
		top: 0;
		right: -3rem;
		z-index: 1;
	}
	
	&__search {
		position: absolute;
		bottom: 0;
		right: -3rem;
		//z-index: 1;
		width: calc(~'27.5rem * 2 + .2rem + 3rem ');
	}
	
	&__slide:hover {
		z-index: 5;
		.game-slider__slide-images {
			top: -7%;
			right: -7%;
			bottom: -7%;
			left: -7%;
			box-shadow: 0 0.3rem 1.5rem 0 fade(black, 45%);
		}
		.game-slider__slide-bg {
			opacity: .04;
		}
		
		
		.game-slider__slide-about {
			opacity: 1;
			visibility: visible;
		}
	}
	
	&__bottom {
		margin-top: 6rem;
		text-align: center;
	}
}

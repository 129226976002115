@media @md-max {
  .promo {
    background-position: calc(~"100% -" 18.7rem) calc(~"100% +" 0.1rem), left calc(~"100% +" 1.5rem), calc(~"100% +" 10rem) calc(~"100% +" 1.1rem);
    
    &__main {
      padding-right: 47.5rem;
    }
  }
}

@media @sm-max {
  .promo {
    background-size: auto 5.5rem, auto, auto 21rem;
    background-position: calc(~"100% -" 9.3rem) calc(~"100% -" 0.9rem), left calc(~"100% +" 1.5rem), calc(~"100% +" 12.5rem) calc(~"100% -" 0.2rem);
    
    &__content {
      min-height: 25rem;
    }
    
    &__main {
      padding-right: 31.5rem;
      padding-bottom: 10.5rem;
    }
  }
}

@media @xs-max {
  .promo {
    background-size: auto 5.5rem, auto, auto 21rem;
    background-position: calc(~"100% -" 9.3rem) calc(~"100% -" 0.9rem), left calc(~"100% +" 1.5rem), calc(~"100% +" 12.5rem) calc(~"100% -" 0.2rem);
    
    &__sub-title {
      margin: 0 0 1.5rem;
      //font-size: 2.7rem;
    }
    
    &__main {
      padding-right: 0;
      padding-bottom: 25rem;
    }
  }
}
* {
	//  padding: 0;
	//  box-sizing: border-box;
	flex: 0 1 auto;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

html {
	//min-width: @min-width;
	//width: 100%;
	//overflow-x: hidden;
	//font-size: @root-font-size;
	font-size: 10px;
	-webkit-overflow-scrolling: touch;
}

body {
	//position: relative;
	//width: 100%;
	//margin: 0;
	//padding: 0;
	background-color: #1f1928;
	//font-family: @source-sans;
	//font-size: @font-size;
	//line-height: @line-height;
	//font-weight: @regular;
	word-wrap: break-word;
	//color: white;
	//overflow-x: hidden;
	//font-size: 14px;
}

html._stop-scroll {
	overflow: hidden;
}

.g-container {
	width: 100%;
	padding-right: @side-padding;
	padding-left: @side-padding;
	margin-right: auto;
	margin-left: auto;
}

@media @md-max {
	.g-container {
		padding-right: @side-padding-md;
		padding-left: @side-padding-md;
	}
}

@media @xs-max {
	.g-container {
		padding-right: @side-padding-mob;
		padding-left: @side-padding-mob;
	}
}

img {
	max-width: 100%;
	display: block;
	height: auto;
}

a {
	transition: @time;
}

.coin-icon {
	width: 1.7rem;
	height: 1.7rem;
	background: url(../img/png/coin-ico.png) no-repeat center;
	background-size: contain;
	display: inline-block;
}

@media @lg {
	._device-only {
		display: none !important;
	}
}

@media @sm {
	._xs-only {
		display: none !important;
	}
}

@media @md {
	._mobile-only {
		display: none !important;
	}
}
@media @xs-max {
	._not-xs {
		display: none !important;
	}
	._block-xs {
		display: block !important;
	}
}

@media @sm-max {
	body {
		cursor: pointer;
	}
	
	._not-mob {
		display: none !important;
	}
}

@media @md-max {
	._pc-only {
		display: none !important;
	}
}

@media @lg-max {
	._xl-only {
		display: none !important;
	}
}

@media @xl {
	._not-xl {
		display: none !important;
	}
}

.g-scroll-y {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling:touch;
}

.g-scroll-x {
	overflow-y: hidden;
	overflow-x: auto;
	-webkit-overflow-scrolling:touch;
}

.g-scroll-xy {
	overflow: auto;
	-webkit-overflow-scrolling:touch;
}

//@media @xl {
//  html {
//    font-size: @root-font-size * 1.3;
//  }
//
//  body {
//    max-width: 2560px;
//  }
//}

//@media @xxl {
//  html {
//    font-size: @root-font-size * 1.5;
//  }
//
//  .page-wrap {
//    max-width: 3070px;
//  }
//}
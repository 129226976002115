.creator-order {
  background: @color-violet-darklight;
  background-image: url(../img/png/creator-order-soldier.png), url(../img/jpg/creator-oredr-page-bg.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: right 7rem, center top;
  padding-bottom: 6rem;
  
  &__title {
    margin: 5rem 0 6rem;
    
    text-align: center;
    font-size: 7rem;
    line-height: 1.2em;
  }
  
  &__info-content-wrap {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.2rem;
      background: linear-gradient(to left, @color-orange 20rem, transparent)
    }
  }
  
  &__toggle-info-content {
    position: absolute;
    z-index: 11;
    left: auto;
    right: 0;
    top: calc(~"100% - " 0.2rem);
    padding: 1.2rem 1.8em 1.3rem;
    background: @color-orange;
    border-bottom-left-radius: @radius;
    
    font-size: 1.2rem;
    text-transform: uppercase;
    
    transition: box-shadow @time, background-color @time;
    
    &::before {
      content: "";
      display: inline-block;
      vertical-align: .18em;
      border-top: .4rem solid #fff;
      border-left: .3rem solid transparent;
      border-right: .3rem solid transparent;
      margin-right: .7rem;
      transform: rotate(0);
      transition: .2s;
    }
    
    &:hover {
      background-color: #d25528;
      box-shadow: 0 1.9rem 5.4rem rgba(210, 85, 40, 0.44);
      color: white;
    }
    
    & ._hide {
      display: none;
    }
  }
  
  &__info-content-wrap._open &__toggle-info-content {
    
    & ._show {
      display: none;
    }
    
    & ._hide {
      display: inline;
    }
    
    &::before {
      transform: rotate(180deg);
    }
  }
}
@media @xs-max {
  .specifications {
    margin-left: -2rem;
    margin-right: -2rem;

    table {

    }

    tbody {

    }

    tr {
      display: flex;
      flex-direction: column;
    }

    tr:nth-child(2n - 1) {
      td,th {

      }
    }

    td,th {
      width: 100%;

      &:first-child {
        border-radius: 0;
        width: 100%;
        padding-bottom: 0;
      }

      &:last-child {
        border-radius: 0;
        width: 100%;
      }

      span {


      }
    }
  }
}
@media @lg-max {
	.shop-tabs {
		
		
		&__items {
		
		}
		
		&__item {
			
			
			
			&._open {
			
			}
		}
		
		&__content {
		
		}
		
		&__content-inner {
		
		}
		
		&__description {
		
		}
		
		&__title {
		
		}
		
		&__text {
		
		}
		
		&__btn {
		
		}
		
		&__img-wrap {
			
			
			img {
			
			}
		}
		
		&__navs {
		
		}
		
		&__btn-nav {
		
		}
	}
}

@media @md-max {
	.shop-tabs {
		
		
		&__items {
		
		}
		
		&__item {
			
			
			
			&._open {
			
			}
		}
		
		&__content {
		
		}
		&__content-inner {
			flex-direction: column;
			align-items: center;
			padding-top: 5rem;
		}
		
		&__description {
			padding: 0 3rem;
			margin-bottom: 3rem;
			text-align: center;
			display: block;
		}
		
		&__title {
		
		}
		
		&__text {
		
		}
		
		&__btn {
		
		}
		
		&__img-wrap {
			margin: 0;
			padding: 0 2rem;
			
			img {
				width: 51.3rem;
			}
		}
		
		&__navs {
		
		}
		
		&__btn-nav {
		
		}
	}
}

@media @sm-max {
	.shop-tabs {
		
		
		&__items {
		
		}
		
		&__item {
			
			
			
			&._open {
			
			}
		}
		
		&__content {
		
		}
		
		&__content-inner {
		
		}
		
		&__description {
		
		}
		
		&__title {
			font-size: 4rem;
		}
		
		&__text {
		
		}
		
		&__btn {
		
		}
		
		&__img-wrap {
			
			img {
			
			}
		}
		
		&__navs {
		
		}
		
		&__btn-nav {
			width: 50%;
		}
	}
}

@media @xs-max {
	.shop-tabs {
		
		
		&__items {
		
		}
		
		&__item {
			display: block;
			
			
			&._open {
				.shop-tabs__content {
					//display: block;
				}
			}
		}
		
		&__content {
			display: none;
		}
		
		&__content-inner {
			padding-top: 4.5rem;
		}
		
		&__description {
			padding: 0 2rem;
		}
		
		&__title {
			font-size: 2.7rem;
		}
		
		&__text {
		
		}
		
		&__btn {
		
		}
		
		&__img-wrap {
			
			img {
			
			}
		}
		
		&__navs {
		
		}
		
		&__btn-nav {
			width: 100%;
			padding: 1rem 4rem 1rem 2rem;
			position: relative;
			&::after {
				display: block;
				position: absolute;
				right: 2rem;
				top: 50%;
				transform: translateY(-50%);
				content: "";
				border-top: .4rem solid currentColor;
				border-left: .3rem solid @transparent;
				border-right: .3rem solid @transparent;
				margin-left: .7rem;
				transition: border-top-color @time, transform @time;
			}
			
			&._open {
				&::after {
					transform: translateY(-50%) scale(1,-1);
				}
			}
		}
	}
}
@media @sm-max {
  .create-buy-order {
    
    &__title {
      
      &--main {
        font-size: 4rem;
      }
    }
  }
}

@media @xs-max {
  .create-buy-order {
    
    &__title {
      margin-bottom: 2.5rem;
      
      &--main {
        font-size: 2.8rem;
      }
    }
    
    &__steps-tabs {
      margin-bottom: 2rem;
    }
  
    &__class-col {
      width: 100%;
      margin-right: 0;
    }
  }
}
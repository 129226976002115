@media @md-max {
  .products-list {
    
    &__status-text {
      display: none;
    }
    
    &__toolbar-row {
    }
  }
}

@media @sm-max {
  .products-list {
    
    & td {
      
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    
    & td:nth-child(2) {
      padding-left: 1rem;
      width: 34%;
    }
    
    & td:nth-child(3),
    & th:nth-child(3),
    & td:nth-child(4),
    & th:nth-child(4) {
      display: none;
    }
    
    & td:nth-child(8) {
      text-align: center;
    }
    
    &__bg-img {
      display: none;
    }
    
    &__number {
      margin-top: 0;
    }
    
    &__name {
      margin-bottom: 1rem;
    }
    
    &__categories-list {
      flex-direction: row;
      margin-bottom: 1rem;
      
      & > li {
        margin-right: 0.8rem;
      }
    }
    
    &__category {
      white-space: nowrap;
    }
    
    &__available {
      .font();
    }
    
    &__status {
      display: inline-block;
      margin: 0 auto;
    }
    
    &__toolbar-row td {
      padding: 0 !important;
    }
  }
}

@media @xs-max {
  .products-list {
    
    & tbody {
      display: block;
      margin-bottom: 0.1rem;
    }
    
    & tr {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
    
    & tr:first-child {
      padding: 2.3rem @side-padding-mob 1.6rem;
    }
    
    & tbody td {
      padding: 0 !important;
      border: 0 !important;
    }
    
    & td:nth-child(2) {
      width: 100%;
    }
    
    & td:last-child {
      position: static;
    }
    
    &__checkbox {
      position: absolute;
      z-index: 11;
      top: 2rem;
      left: @side-padding-mob;
    }
    
    &__number {
      padding-left: 3rem;
      margin-bottom: 2rem;
      width: 100%;
    }
    
    &__name {
      margin-bottom: 1.4rem;
    }
    
    &__status {
      position: absolute;
      top: 2.6rem;
      right: 2.8rem;
    }
    
    &__mob-row {
      display: flex;
    }
    
    &__orders {
      margin-right: 1.6rem;
      
      .font()
    }
    
    &__available {
      opacity: 0.6;
    }
    
    &__more {
      z-index: 11;
      width: 2.6rem;
      height: 3rem;
      left: auto;
      top: auto;
      right: 1.9rem;
      bottom: 1.4rem;
      border: 0 !important;
    }
    
    &__product._open .products-list__more {
      background: transparent;
      
      & span {
        background: @color-blue;
      }
    }
    
    &__toolbar-wrap {
      padding: 0;
    }
  
    &__toolbar {
      width: 100%;
      padding: 1.6rem @side-padding-mob 0;
    }
  }
}
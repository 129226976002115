@media (max-width: 1366px) {
  .page-product {
    
    &__main-info {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

@media @sm-max {
  .page-product {
    
    &__title {
      font-size: 4rem;
    }
    
    &__main {
      margin-right: 0;
      width: 100%;
    }
    
    &__main-info-wrap {
      width: 46.365%;
    }
    
    &__buying-wrap {
    
    }
  
    &__buying-wrap-full {
      margin-bottom: 4rem;
    }

    &__product {
      margin-bottom: 4rem;
    }
    
    &__side {
      width: 0;
      
      & > * {
        display: none;
      }
    }
  }
}

@media @xs-max {
  .page-product {
    padding-top: 0;
  
    &__full-wrap {
      padding-top: 1.8rem;
    }
    
    //&__main-wrap {
    //  display: block;
    //}
    //
    //&__main {
    //  width: 100%;
    //}
    
    &__title {
      margin-bottom: 1.8rem;
      
      font-size: 2.7rem;
    }
    
    &__special-offer {
      display: none;
    }
    
    &__toolbar-wrap {
      margin-bottom: 4rem;
    }
    
    &__toolbar {
      margin-left: 0;
      width: 100%;
      justify-content: space-between;
    }
  
    &__star-rating {
      padding-left: 9.5rem;
    }
    
    &__product {
      display: block;
    }
    
    &__main-info-wrap,
    &__buying-wrap {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    
    &__main-info {
      padding-left: @side-padding-mob;
      padding-right: @side-padding-mob;
      margin-bottom: 3rem;
    }
    
    &__product-gallery,
    &__main-info {
      margin-left: -@side-padding-mob;
      margin-right: -@side-padding-mob;
      width: auto !important;
      min-width: 100%;
    }
    
    &__tags-list  {
      margin-bottom: 3.8rem;
    }
    
    &__tabs-select {
      margin-bottom: 3.2rem;
    }
  }
}
.overview-setting {
	
	&__content {
		width: 100%;
	}
	
	&__item {
		padding-bottom: 2.9rem;
		margin-bottom: 2.6rem;
		border-bottom: 1px solid #493752;
		
		//&:last-child {
		//	padding-bottom: 0;
		//	margin-bottom: 0;
		//	border-bottom: 0;
		//}
	}
	
	&__item-head {
		margin-bottom: 2.5rem;
		//align-items: flex-start;
		//
		//.section-header__short-btn-wrap {
		//	padding-top: .5rem;
		//}
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__content-row {
		margin-bottom: 2rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}
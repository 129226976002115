.shop-tabs {
	width: 100%;
	position: relative;
	z-index: 7;
	
	&__items {
		width: 100%;
	}
	
	&__item {
		width: 100%;
		display: none;
		//animation-name: fadeIn;
		//animation-duration: .3s;
		
		
		&._open {
			display: block;
			
		}
	}
	
	&__content {
		width: 100%;
	}
	
	&__content-inner {
		width: 100%;
		display: flex;
		.font;
		padding-top: 10.8rem;
	}
	
	&__description {
		font: inherit;
		color: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 0 12rem 3rem 13rem;
	}
	
	&__title {
		.h2;
		margin-bottom: 1.9rem;
	}
	
	&__text {
		font: inherit;
		color: inherit;
		font-size: 1.6rem;
	}
	
	&__btn {
		margin-top: 3.5rem;
	}
	
	&__img-wrap {
		margin-top: auto;
		margin-left: auto;
		flex-shrink: 0;
		
		img {
			width: 56.3rem;
			
		}
	}
	
	&__navs {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	
	&__btn-nav {
		.font;
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		width: 25%;
		flex-grow: 1;
		padding: 1rem 3rem;
		min-height: 8.2rem;
		position: relative;
		transition: color @time, transform @time, z-index @time, opacity @time;
		z-index: 3;
		&:hover {
			//color: white;
			
			//@media @md {
			//	transform: scale(1.1);
			//	z-index: 5;
			//}
		}
	}
}
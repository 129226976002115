@media @md-max {
  .similar-slider {
    margin-left: 0;

    &::before {

    }

    &__container {

    }

    &__wrap {

    }

    &__slide {

    }

    &__navs {

    }
  }
}

@media @sm-max {
  .similar-slider {

    &::before {

    }

    &__container {

    }

    &__wrap {

    }

    &__slide {

    }

    &__navs {

    }
  }
}

@media @xs-max {
  .similar-slider {
    &::before,
    &::after {
      display: none;
    }

    &__container {
      padding-right: 3rem;
    }

    &__wrap {

    }

    &__slide {
      width: 100%;
      margin-right: 3rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &__navs {
      left: 3rem;
    }
  }
}
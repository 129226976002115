@media @sm-max{
  .setting-radio {
    &__color-scheme-label {
      display: none;
    }
  }
}

@media @sm-max {
  .setting-radio {
    &__title {
      margin: 0.8rem 0 .1rem;
    }
  
    &__lock {
    
      width: 3.6rem;
      height: 3.6rem;
    
      & svg {
        top: -0.1rem;
        width: 1.2rem;
        height: 1.5rem;
      }
    }
    
    &__color-scheme {
      padding: 3rem 1.5rem;
    }
  }
}

@media (max-width: 400px) {
  .setting-radio {
    
    &__dots {
      
      & span {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.2rem;
      }
    }
    
   
  }
}
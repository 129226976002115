@media @xs-max {
	.download-block {
		
		&__head {
			margin-bottom: .8rem;
		}
		
		&__dropzone {
			padding: @side-padding-mob;
			
			.dropzone__area {
				border: 0;
				&::before {
					display: none;
				}
			}
			
			.dropzone__btn {
				position: relative;
				width: 100%;
				min-height: 8.5rem;
			}
			
		}
		
		&__file-list {
			margin-top: -@side-padding-mob;
		}
		
		&__file-item {
			padding-left: @side-padding-mob;
			padding-right: 4rem;
		}
		
		&__file-del {
			right: @side-padding-mob;
		}
	}
}
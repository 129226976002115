.page-product {
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 1920px auto;
  //background-attachment: fixed;
  padding-top: 0;
  
  &__full-wrap {
    //background: green;
    padding-top: 4rem;
    background-image: url(../img/png/highlight.png), url(../img/png/highlight-2.png);
    background-repeat: no-repeat;
    background-position: left top, center 70%;
  }
  
  &__main-wrap {
    display: flex;
  }
  
  &__main {
    margin-right: 7rem;
    flex-grow: 1;
    width: 50%;
  }
  
  &__title {
    padding: 0;
    margin-bottom: 2.4rem;
    margin-left: -0.3rem;
    
    font-size: 5rem;
    line-height: @line-height;
    font-family: @oswald;
    font-weight: @semibold;
  }
  
  &__toolbar-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 3rem;
    margin-top: -1rem;
  }
  
  &__toolbar {
    position: relative;
    z-index: 5;
    margin-left: 13rem;
    min-height: 3.2rem;
  }
  
  &__special-offer {
    margin-top: -3rem;
    align-self: flex-start;
  }
  
  &__star-rating {
    position: relative;
    z-index: 10;
    padding-left: 11rem;
    margin-bottom: -3.6rem;
    margin-top: 4.5rem;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
    
    & .star-rating__star {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.5rem;
      color: #4f475e;
    }
    
    & .star-rating__star._active {
      color: @color-yellow;
    }
    
    & .star-rating__review-amount {
      color: @color-violet-lightest;
    }
    
    & .star-rating__review-amount:hover {
      color: @color-violet-lightest2;
    }
  }
  
  &._product-gallery-disabled &__star-rating {
    margin-bottom: 1.4rem;
    padding-left: 0;
  }
  
  &__product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5.5rem;
  }
  
  &__main-info-wrap {
    width: 45.906%;
    flex-grow: 1;
    margin-right: 5.7%;
  }
  
  &__main-info {
    position: relative;
    background: #362940;
    padding: 3rem 2.8rem;
    margin-bottom: 4rem;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 7.7rem;
      height: 3rem;
      background: @color-violet-lightdark-3;
      opacity: 0.5;
    }
    
    &::after {
      width: 3rem;
      height: 12.5rem;
      bottom: 3rem;
    }
  }
  
  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  
  &__tags-list-item {
    margin: 0 1rem 1rem 0;
    
    &:last-child {
      margin: 0;
    }
  }
  
  &__buying-wrap {
    width: 48.388%;
    max-width: 39rem;
  }
  
  &__buying {
    margin-bottom: 1.8rem;
  }
  
  &__side {
    flex-shrink: 0;
    width: 23rem;
    padding-bottom: 3rem;
  }
  
  &__tabs {
    justify-content: flex-start;
    margin-bottom: 3.4rem;
  }
  
  &__tab-block {
    display: none;
    margin-bottom: 4rem;
  }
  
  &__tab-block._open {
    display: block;
    animation: fadeIn @time-modal;
  }
  
  &__side-slider {
    margin: 6rem 0 0;
  }
  
  &__buying-small-wrap {
    margin-top: 6rem;
  }
  
  &__tags-crumbs {
    margin-bottom: 3rem;
  }
  
  &__buying-wrap-full {
    margin-bottom: 6rem;
  }
  
  &__buying-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .page-product__tags-list {
      margin-bottom: 0;
    }
  }
  
  &__special-offer-mod {
    margin-bottom: 3rem;
    align-items: stretch;
  }
}


.page-product__buying-wrap .price  {
  margin-bottom: 2rem
}

.page-product__buying-wrap .responded {
  display: block;
  margin: 2rem 0;
}

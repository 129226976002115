.deals {
  padding: 2rem 2rem 0;
  //background-color: #1f1928;
  .font;
  z-index: 30;
  width: 100%;
  //max-height: 50vh;
  //overflow-y: auto;

  &__header {
    font: inherit;
    color: inherit;
    display: flex;
    padding: 1rem 1rem 1rem 2rem;
    background-color: @color-violet-lightdark;
  }

  &__header-title {
    font: inherit;
    color: inherit;
    font-size: 1.2rem;
    opacity: .6;
  }

  &__header-navs {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__nav-btn {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .6rem;
    position: relative;
  }
  
  &__nav-btn--close {
    svg {
      transition: opacity @time;
    }
    
    &::after {
      position: absolute;
      content: "";
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      border-top: .5rem solid currentColor;
      border-left: .4rem solid @transparent;
      border-right: .4rem solid @transparent;
      transition: opacity @time;
      opacity: 0;
    }
  }

  &__items {
    font: inherit;
    color: inherit;
  }

  &__item {
    font: inherit;
    color: inherit;
    background-color: @color-violet-lightdark;
    padding: 1.8rem 2rem;
    margin-bottom: 1px;
    position: relative;
    
    &:first-child {
      margin-top: -.6rem;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__item-close {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: .8rem;
    top: .8rem;
    z-index: 20;
    opacity: 0;
  }
  
  &__item:hover {
    .deals__item-close {
      opacity: 1;
    }
  }

  &__item-title {
    font: inherit;
    color: inherit;
    text-transform: uppercase;
    margin-bottom: .7rem;
  }

  &__item-status {
    font: inherit;
    color: inherit;
    display: block;
    margin-bottom: 1.5rem;

    &._done {
      color: #4fb544;
    }

    &._progress {
      color: #eead21;
    }
  }

  &__item-log {
    font: inherit;
    color: inherit;
  }

  &__item-log-item {
    font: inherit;
    color: inherit;
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-log-time {
    font: inherit;
    color: inherit;
    display: inline-block;
    font-size: 1.2rem;

    span {
      margin-right: .7rem;
    }
  }
  
  &._fixed {
    position: fixed;
    top: 7.2rem;
  
    .deals__nav-btn--pin {
      color: @color-blue;
    }
  }
  
  &._closed {
    .deals__nav-btn--close {
      svg {
        opacity: 0;
      }
      
      &::after {
        opacity: 1;
      }
    }
  }
}
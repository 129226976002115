.page-thanks {
	padding-top: 15rem;
	padding-bottom: 3.5rem;
	
	background-image: url(../img/png/highlight.png);
	background-repeat: no-repeat;
	background-position: left top;
	
	&__content {
		max-width: 62rem;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-bottom: 5.5rem;
	}
	
	&__title {
		padding: 0;
		.h2();
		margin-bottom: 1.8rem;
	}
	
	&__text {
		margin-bottom: 5rem;
	}

	&__status-bar {
		margin-bottom: 9rem;
	}
	
	&__share-box {
		margin-bottom: 3.5rem;
	}
	
	&__text-bottom {
		text-align: center;
		color: white;
	}
}
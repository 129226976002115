.dropdown {
  width: 100%;
  display: inline-block;
  font-family: @source-sans;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.15;
  color: white;
  position: relative;
  z-index: 10;
  
  &__selected {
    color: inherit;
    display: inline-block;
    font: inherit;
    background-color: @color-violet-darklight;
    padding: 1.2rem 3.5rem 1.2rem 2rem;
    border-radius: @radius;
    cursor: pointer;
    transition: color @time, border-radius @time;
    text-transform: uppercase;
    justify-content: space-between;
    position: relative;
    z-index: 5;

    &::after {
      position: absolute;
      margin: 0;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  
  &__list {
    font: inherit;
    color: inherit;
    position: absolute;
    background-color: @color-violet-darklight;
    left: 0;
    right: 0;
    display: none;
    top: 100%;
    padding: 1rem 2rem;
    z-index: 1;

    &::after {
      position: absolute;
      content: '';
      top: -4rem;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: @radius;
      z-index: -1;
      box-shadow: 0 1.8rem 5.4rem 0 rgba(0, 0, 0, 0.45);
      pointer-events: none;
    }

    li {
      font: inherit;
      color: inherit;
      margin-bottom: 1.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  &__link {
    display: inline-block;
    font: inherit;
    color: inherit;
    text-transform: uppercase;
    transition: color @time;
  }

  &__selected:hover,
  &__link:hover {
    color: @color-blue;
  }

  &._open {
    .dropdown__selected {
      color: @color-blue;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        border-top-color: currentColor;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .dropdown__list {

    }
  }

  &._lang {
    .dropdown__selected {
      min-width: 16.5rem;
    }
  }

  &._top {
    .dropdown__list {
      top: auto;
      bottom: 100%;

      &::after {
        top: 0;
        bottom: -4rem;
      }
    }
    
    &._open {
      .dropdown__selected {
        border-radius: 0 0 @radius @radius;
      }
    }
  }
}

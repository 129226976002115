.similar {
	width: 100%;
	margin-top: 5.3rem;
	&__inner {
		width: 100%;
		background-color: @color-grey-light;
		position: relative;
		padding-top: 2.7rem;
		padding-bottom: 4rem;
		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 5.3rem;
			left: 0;
			top: -5.2rem;
			background-image: url("../img/png/ragged-pattern.png");
			background-size: contain;
			background-position: top;
			background-repeat: repeat-x;
			z-index: 1;
		}
		
		&::before {
			transform: scale(1,-1);
		}
		
		&::after {
			top: auto;
			bottom: -5.2rem;
		}
	}
	
	&__content {
		position: relative;
		z-index: 5;
	}
	
	&__title {
		.h2;
		color: @color-violet-darklight;
		text-align: center;
	}
}
@media @xs-max {
	.status-bar {
		
		&__panel {
			padding-top: 2.2rem;
		}
		
		&__item-text:not(._bottom) {
			position: absolute;
			bottom: 100%;
			margin-bottom: .4rem;
			&._left {
				left: -1rem;
			}
			
			&._right {
				right: -1rem;
			}
		}
	}
}
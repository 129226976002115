@media @md-max {
	.game-slider {
		
		
		&__title {
			//font-size: 4rem;
		}
		
		&__inner {
			margin-right: 3rem;
			
			&::before {
			
			}
		}
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
		}
		
		&__slide-images {
		
		}
		
		&__slide-bg {
		
		}
		
		&__slide-logo {
		
		}
		
		&__slide-about {
		
		}
		
		&__slide-title {
		
		}
		
		&__slide-list {
			
			li{
			
			}
		}
		
		&__slide-link {
		
		}
		
		&__navs {
		
		}
		
		&__search {
		
		}
		
		&__slide:hover {
			
			.game-slider__slide-images {
			
			}
			.game-slider__slide-bg {
			
			}
			
			
			.game-slider__slide-about {
			
			}
		}
		
		&__bottom {
		
		}
	}
	
}

@media @sm-max {
	.game-slider {
		//margin-top: 9.5rem;
		//margin-bottom: 13rem;
		
		&__title {
			font-size: 4rem;
		}
		
		&__inner {
			
			&::before {
			
			}
		}
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
		}
		
		&__slide-images {
		
		}
		
		&__slide-bg {
		
		}
		
		&__slide-logo {
		
		}
		
		&__slide-about {
		
		}
		
		&__slide-title {
		
		}
		
		&__slide-list {
			
			li{
			
			}
		}
		
		&__slide-link {
		
		}
		
		&__navs {
		
		}
		
		&__search {
		
		}
		
		&__slide:hover {
			
			.game-slider__slide-images {
			
			}
			.game-slider__slide-bg {
			
			}
			
			
			.game-slider__slide-about {
			
			}
		}
		
		&__bottom {
		
		}
	}
	
}

@media @xs-max {
	.game-slider {
		//margin-top: 3.5rem;
		//margin-bottom: 4.5rem;
		
		&__title {
			font-size: 2.7rem;
			text-align: left;
			padding-right: 16rem;
			padding-left: 2rem;
		}
		
		&__inner {
			margin-right: 0;
			margin-top: -2.7rem;
			
			&::before {
				display: none;
			}
		}
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			width: 15.9rem;
			min-height: 25.5rem;
		}
		
		&__slide-images {
		
		}
		
		&__slide-bg {
		
		}
		
		&__slide-logo {
		
		}
		
		&__slide-about {
			padding: 1.5rem .5rem;
		}
		
		&__slide-title {
			padding-bottom: 1.5rem;
			margin-bottom: 2rem;
		}
		
		&__slide-list {
			
			li{
				margin-bottom: 2.3rem;
			}
		}
		
		&__slide-link {
		
		}
		
		&__navs {
			right: 0;
		}
		
		&__search {
			width: 100%;
			right: 0;
		}
		
		&__slide:hover {
			
			.game-slider__slide-images {
				top: -1rem;
				left: -.2rem;
				right: -.2rem;
				bottom: -1rem;
			}
			.game-slider__slide-bg {
			
			}
			
			.game-slider__slide-logo {
				opacity: 0;
			}
			
			
			.game-slider__slide-about {
			
			}
		}
		
		&__bottom {
			margin-top: 4rem;
		}
	}
	
}
@media @md-max {
  .helps-side {
  
    &__item-main-wrap {
      padding-left: 3rem;
    }
  
    &__item-main {
      flex-direction: column;
    }
  
    &__item-text {
      width: 100%;
      padding-right: 3rem;
      flex-grow: 0;
    }
    
    &__item-title {
      padding-right: 3rem;
    }
    
    &__item-img-wrap {
      align-self: flex-start;
      margin-top: 2.8rem;
      margin-left: auto;
      max-width: 100%;
    }
  }
}

@media @sm-max {
  .helps-side {
    display: none;
    position: fixed;
    z-index: 192;
    bottom: 0;
    right: 0;
    width: 29rem;
    height: calc(~"100% -" @header-height-mob);
    overflow: hidden;
    
    &::before {
      font-size: 23rem;
      top: -8.5rem;
      right: -2rem;
    }
    
    &__mob-close {
      position: absolute;
      z-index: 20;
      right: 1rem;
      top: 1rem;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: @radius;
      background: #00acd1;
      box-shadow: 0 0.7rem 1.7rem rgba(0, 172, 209, 0.44);
      
      & svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    
    &__item-main-wrap,
    &__item-text,
    &__item-links {
      padding-left: 0;
      padding-right: 0;
    }
    
    &__list {
      height: 100%;
    }
    
    &__item {
      padding: 2.2rem 0;
    }
    
    &__item-main-wrap,
    &__item-links {
      padding-left: 2rem;
      padding-right: 2rem;
      flex-shrink: 0;
    }

    &__item-main {
      height: 50%;
    }

    &__item-text {
      max-width: none;
    }
  }
}

@media @xs-max {
  .helps-side {
    width: 100%;
    height: 100%;

    &__item-main {
      flex-direction: row;
    }

    &__item-text {
      flex-grow: 1;
    }

    &__item-img-wrap {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}

@media (max-width: 490px) {
  .helps-side {

    &__item-main {
      flex-direction: column;
    }

    &__item-text {
      flex-grow: 0;
    }

    &__item-img-wrap {
      margin-top: 2.8rem;
      margin-left: 0;
    }
  }
}
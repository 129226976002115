.attention-label {
	display: block;
	position: relative;
	width: 1rem;
	height: 1rem;
	background-color: @color-pink;
	box-shadow: 0 0 .2rem .1rem @color-pink;
	border-radius: 100%;
	z-index: 10;
	transition: visibility @time, opacity @time;
	&__shadow {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		left: -8rem;
		top: -8rem;
		right: -8rem;
		bottom: -8rem;
		overflow: hidden;
		z-index: -1;
		pointer-events: none;
		&::before {
			position: relative;
			content: '';
			display: block;
			width: .8rem;
			height: .8rem;
			border-radius: 100%;
			box-shadow: 0 0 5rem 1.5rem @color-pink;
		}
	}
	
	&._left {
		.attention-label__shadow {
			transform: translateX(50%);
			justify-content: flex-start;
			
			&::before {
				transform: translateX(-50%);
			}
		}
	}
}
.special-offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  &__btn {
    display: block;
    padding: 0.9rem 1rem 1rem 1.2rem;
    background: #c1215e;
    
    .font();
    font-size: 1.2rem;
    text-transform: uppercase;
    
    &::after {
      border-top-color: white !important;
      opacity: 0.5;
      
      //transition: @time;
    }
    
    &:hover::after {
      opacity: 1;
    }
  }
  
  &._open &__btn::after {
    transform: rotate(180deg);
  }
  
  &__content {
    display: none;
    position: relative;
    padding: 2.6rem 2.8rem;
    background: #c1215e linear-gradient(to right, rgb(192,30,98) 0%, rgb(209,71,35) 100%);
  }
  
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & svg {
      width: 1rem;
      height: 1rem;
      fill: white;
    }
    
    &:hover {
      opacity: 0.6;
    }
  }
  
  &__text {
  
  }
  
  &__head {
    display: flex;
    width: 100%;
  }
  
  &__toolbar {
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 3rem;
  }
}
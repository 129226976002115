.combinations-list {
  width: 100%;
  
  & tbody {
    background: #362940;
  }
  
  & th,
  & td,
  &__number,
  &__name,
  &__categories-list,
  &__link,
  &__expire,
  &__status-text {
    .font();
  }
  
  & tbody tr:first-child td {
    border-top: 0.1rem solid @color-violet-darklight;
  }
  
  & th,
  & td {
    padding: 1.1rem 1rem;
    vertical-align: middle;
  }
  
  & th {
    padding-top: 0.7rem;
    padding-bottom: 0.8rem;
    border-top: 0.1rem solid #493752;
    
    color: fade(white, 60);
  }
  
  & th:first-child {
    padding-left: 0;
    color: @color-blue;
  }
  
  & td:first-child {
    padding-left: 2rem;
  }
  
  & tbody tr:first-child td {
    border-top: .1rem solid #261e34;
  }
  
  & th._input-col {
    width: 16.4rem;
  }
  
  &__th-help {
    vertical-align: -0.1rem;
    margin-left: 0.3rem;
  }
  
  &__sub-title {
    color: fade(white, 30);
  }
  
  &__sub-title,
  &__title {
    margin: 0.5rem 0;
  }
  
  &__price-note {
    margin: -0.2rem 0 0.3rem;
    font-size: 1.2rem;
    color: @color-blue;
  }
  
  &__input-wrap {
    margin-bottom: 0.8rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  & th._more,
  & td._more {
    position: relative;
    width: 3rem;
    padding: 0;
  }
  
  &__input {
    display: block;
    width: 7.4rem;
    
    & .input__input {
      padding-left: 1.5rem;
    }
  }
  
  &__more {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    max-width: 100%;
    height: 100%;
    border-left: 0.1rem solid @color-violet-darklight;
    
    & span {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: white;
      margin: 0.2rem 0;
    }
    
    &:hover span {
      background-color: @color-blue;
    }
  }
  
  &__combination._open &__more {
    background: #3e374a;
    border-color: #3e374a;
  }
  
  &__info-list-row {
    background: #3e374a;
    
    & td {
      padding: 0;
      padding-right: 2rem;
    }
  }
  
  &__info-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0.8rem 0;
  }
  
  &__param {
    margin: 0.9rem 5rem 0.9rem 0;
    display: flex;
    align-items: center;
    
    font-size: 1.4rem;
    color: fade(white, 60);
    
    & svg {
      width: 1.3rem;
      height: 1.4rem;
      fill: white;
      margin-right: 1rem;
      margin-top: 0.1rem;
    }
  }
  
  &__param-value {
    margin-left: 0.8rem;
    
    color: white;
  }
  
  &__coin {
    width: 1.3rem;
    height: 1.3rem;
    background: url(../img/png/coin-ico.png) no-repeat center;
    background-size: contain;
    margin-left: 0.4rem;
  }
}
@media @sm-max {
  .main-footer-menu {

    &__title {
      display: inline-block;
      &::after {
        display: inline-block;
        vertical-align: 0.17em;
        content: "";
        border-top: .4rem solid fade(white,50%);
        border-left: .3rem solid @transparent;
        border-right: .3rem solid @transparent;
        margin-left: .7rem;
        transition: border-top-color @time, transform @time;
      }
    }

    &__list {
      display: none;
    }

    &__item {
      margin-bottom: 1.5rem;
    }

    &__link {

    }
  }

  .main-footer-menu._open {
    .main-footer-menu__title::after {
      transform: rotate(180deg);
    }
  }
}

@media @md {
  .main-footer-menu {
    &__list {
      display: block!important;
    }
  }
}
.user-product-menu {
  display: flex;
  flex-wrap: wrap;
  
  font-family: @oswald;
  font-size: 2.7rem;
  line-height: @line-height;
  color: white;
  text-transform: uppercase;
  
  &__link {
    font: inherit;
    
    & svg {
      width: 0.8rem;
      height: 1.3rem;
      fill: @color-blue;
      vertical-align: 0.4rem;
      margin-left: 0.2rem;
      
      transition: @time;
    }
  }
  
  a&__link {
    color: @color-blue;
  }
  
  &__link:hover {
    color: white;
    
    & svg {
      fill: white
    }
  }
  
  &__separator {
    margin: 0 1rem;
  }
}
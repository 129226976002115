.product-setup {
	width: 100%;
	
	&._has-gallery {
		padding-top: 5rem;
	}
	
	&__inner {
		background-color: #362940;
		width: 100%;
		padding-bottom: 3rem;
		
		&>*:last-child {
			margin-bottom: 0;
		}
	}
	
	&__gallery {
		width: 100%;
		margin-bottom: 3.5rem;
		
		
	}
	
	&__param-group {
		margin-bottom: 4.5rem;
		
	}
	
	
}
.dropdown-dots {
	position: relative;
	&__trigger {
		min-height: 4rem;
		padding: 1rem 1.4rem;
		flex-shrink: 0;
		z-index: auto;
		
		span {
			display: block;
			width: .5rem;
			height: .5rem;
			border-radius: 50%;
			font-size: 0;
			background-color: white;
			margin: .2rem;
			flex-shrink: 0;
			transition: background-color @time;
			position: relative;
			z-index: 8;
		}
		
		&::before {
			z-index: 1;
		}
		
		&::after {
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: @color-violet-lighter;
			z-index: 4;
			border-bottom-right-radius: @radius;
			border-bottom-left-radius: @radius;
			opacity: 0;
			transition: opacity @time-modal, top @time-modal;
		}
	}
	
	&__block {
		display: none;
		padding: 2rem;
		position: absolute;
		bottom: 100%;
		margin-bottom: 1rem;
		right: 0;
		
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		&::before {
			border-top-right-radius: @radius;
			border-top-left-radius: @radius;
			border-bottom-left-radius: @radius;
			box-shadow: @box-shadow;
			z-index: 2;
		}
		
		&::after {
			background-color: @color-violet-lighter;
			border-top-right-radius: @radius;
			border-top-left-radius: @radius;
			border-bottom-left-radius: @radius;
			z-index: 5;
		}
	}
	
	&__block-inner {
		display: flex;
		position: relative;
		z-index: 10;
	}
	
	&__block-item {
		margin-right: 2rem;
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	&:first-child {
		.dropdown-dots__block {
			right: auto;
			left: 0;
			
			&::before,
			&::after {
				border-bottom-left-radius: 0;
			}
		}
	}
	
	&._open {
		.dropdown-dots__trigger {
			&::before {
				background-color: fade(@color-orange, 0);
				box-shadow: @box-shadow!important;
			}
			
			&::after {
				opacity: 1;
				top: -1rem;
			}
			span {
				background-color: @color-blue;
			}
		}
	}
}
.setting-radio {
  text-align: center;
  
  &__content-wrap {
    display: block;
    .no-selection()
  }
  
  &__title,
  &__link {
    .font();
    
    font-size: 1.2rem;
    text-decoration: none;
    text-transform: none;
    font-weight: @regular;
  }
  
  &__title {
    margin: 1.6rem 0 0.4rem;
  }
  
  &__link {
    color: #a266ab;
  }
  
  & input[type="radio"] {
    display: none;
  }
  
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #5d4d65;
    border-radius: 0.3rem;
    cursor: pointer;
    
    transition: @time;
  }
  
  &__content:hover {
    border-color: white;
  }
  
  & input[type="radio"]:checked ~ &__content {
    background-color: #362940;
    border-color: @color-orange;
    box-shadow: 0 1.9rem 5.4rem rgba(219, 81, 33, 0.44);
  
  }
  
  &__checkbox {
    position: absolute;
    z-index: 5;
    top: -1.3rem;
    left: -1.3rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: @color-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    
    transition: @time;
    
    & svg {
      width: 1.4rem;
      height: 1.1rem;
      fill: white
    }
  }
  
  & input[type="radio"]:checked ~ &__content &__checkbox {
    opacity: 1;
  }
  
  &__dots {
    position: absolute;
    top: 0.4rem;
    right: 0.6rem;
    display: flex;
    
    & span {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: #5d4d65;
      margin-right: 0.3rem;
      
      transition: @time;
      
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  & input[type="radio"]:checked ~ &__content &__dots span {
    background: @color-orange;
  }
  
  &__lock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.2rem;
    height: 6.2rem;
    border-radius: 50%;
    background: @color-violet-darklight;
    
    & svg {
      position: relative;
      top: -0.2rem;
      width: 2rem;
      height: 2.6rem;
      fill: #6a617b;
    }
  }
  
  &__main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__template-structure {
    width: 100%;
    //max-width: 22.2rem;
  }
  
  &__color-scheme {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3rem;
    background: @color-violet-darklight;
  }
  
  &__color-scheme-item {
    width: 100%;
    height: 4rem;
  }
  
  &__color-scheme-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.8rem 0.5rem;
    background: #302741;
    border-radius: @radius;
    opacity: 0;
    
    .font();
    color: #827796;
    white-space: nowrap;
    
    transition: @time;
    
    & svg {
      width: 1.8rem;
      height: 1.2rem;
      fill: #6a617b;
      margin-right: 0.4rem;
    }
  }
  
  //&__color-scheme:hover &__color-scheme-label {
  //  opacity: 1;
  //}
  
  & input[type="radio"]:not(:checked) ~ &__content:hover &__color-scheme-label {
    opacity: 1;
  }
}
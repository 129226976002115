.messages {
	background-color: @transparent;
	position: absolute;
	top: 100%;
	right: -5.5rem;
	.font;
	color: white;
	max-height: calc(~'100vh - 100% + 7rem');
	overflow-y: auto;
	padding: 0 5.5rem 7rem;
	display: none;
	
	&__inner {
		box-shadow: @box-shadow;
		width: 30rem;
		font: inherit;
		color: inherit;
		background-color: #1f1928;
	}
	
	&__items {
		font: inherit;
		color: inherit;
	}
	
	&__item {
		font: inherit;
		color: inherit;
		padding: 2.7rem 3rem;
		border-bottom: 1px solid @color-violet;
		position: relative;
		background-color: #1f1928;
		transition: background-color @time;
		
		&:last-child {
			border-bottom: 0;
		}
		
		&>*:last-child {
			margin-bottom: 0;
		}
	}
	
	&__item-close {
		width: 1.6rem;
		height: 1.6rem;
		position: absolute;
		right: 1.2rem;
		top: 1.2rem;
		z-index: 20;
		opacity: 0;
	}
	
	&__author {
		font: inherit;
		color: inherit;
		display: flex;
		margin-top: -.7rem;
		margin-bottom: 1rem;
	}
	
	&__author-ava {
		flex-shrink: 0;
		margin-right: 1rem;
		width: 5.5rem;
		height: 5.5rem;
		border-radius: 100%;
		overflow: hidden;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	&__author-info {
		font: inherit;
		color: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	
	&__time {
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
		opacity: .6;
		display: inline-block;
		margin-bottom: 1rem;
		
		span {
			margin-right: .7rem;
		}
	}
	
	&__title {
		font: inherit;
		color: inherit;
		text-transform: uppercase;
		margin-bottom: 1.3rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__author-name {
		font: inherit;
		color: inherit;
		text-transform: uppercase;
	}
	
	&__text {
		font: inherit;
		color: inherit;
		line-height: 1.7rem;
		opacity: .6;
		margin-bottom: 1.3rem;
		//overflow: hidden;
	}
	
	//&__text._cut {
	//	max-height: calc(~'1.7rem * 6');
	//}
	
	&__link {
		font: inherit;
		color: @color-blue;
		transition: color @time;
		display: inline-block;
		
		&:hover {
			color: white;
		}
	}
	
	&__attention-label {
		position: absolute;
		left: -.5rem;
		top: 5rem;
		visibility: hidden;
		opacity: 0;
	}
	
	&__reply-wrap {
		font: inherit;
		color: inherit;
		width: 100%;
	}
	
	&__reply-dropdown {
		font: inherit;
		color: inherit;
		width: 100%;
		position: relative;
		z-index: 5;
		margin-top: -1.6rem;
		display: none;
	}
	
	&__reply-form {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	
	&__reply-textarea {
		font: inherit;
		width: 100%;
		resize: none;
		color: #2d233c;
		padding: .5rem 1rem;
	}
	
	&__reply-btn {
		width: 100%;
		
		
		&::before {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
	
	&__item:hover {
		.messages__item-close {
			opacity: 1;
		}
	}
	
	&__item._attention {
		background-color: #1b1524;
		.messages__attention-label {
			visibility: visible;
			opacity: 1;
		}
	}
}

@media @sm-max {
  .promo-code {
    flex-direction: column;
    align-items: flex-start;
    
    &__main,
    &__form {
      margin: 0 0 1rem;
    }
    
    &__select,
    &__input {
      width: 100%;
    }
    
    //&__form-btn.g-btn {
    //  width: 100%;
    //  justify-content: flex-start;
    //}
    
    &__or {
      margin-left: 0;
    }
    
    //
    //&__label {
    //  bottom: auto;
    //  top: 0;
    //}
  }
}

@media @xs-max {
  .promo-code {
    width: 100%;
    
    &__main {
      margin: 0 0 1rem;
    }
    
    &__form {
      padding-top: 2.6rem;
    }
    
    &__select,
    &__input {
      width: 100%;
    }
    
    &__form-btn.g-btn {
    }
    
    &__label {
      bottom: auto;
      top: 0;
    }
  }
}
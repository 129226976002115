.game-menu {
  
  &__img {
    width: 100%;
    height: 6.7rem;
    object-fit: cover;
    margin-bottom: 2.4rem;
  }
  
  &__title {
    margin-bottom: 1.4rem;
    
    .font();
    color: #db5424;
    
    & a {
      color: #db5424;
    }
    & a:hover {
      color: @color-blue;
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  &__link {
    margin-bottom: 1.3rem;
  
    .font();
    font-size: 1.3rem;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    &:hover {
      color: @color-blue;
    }
  }
}
.dropdown-toggle {
  &::after {
    display: inline-block;
    vertical-align: 0.18em;
    content: "";
    border-top: .4rem solid fade(white, 50%);
    border-left: .3rem solid @transparent;
    border-right: .3rem solid @transparent;
    margin-left: .7rem;
    
    transition: @time;
  }
  
  &:hover {
    &::after {
      border-top-color: @color-blue;
    }
  }
}
.quantity-discount {

  &__content {
    padding: 1.1rem 3rem 3rem;
    border: 0.1rem solid #493752;
    border-bottom: 0;
    border-radius: 0.2rem 0.2rem 0 0;
  }

  &__row {
    display: flex;
    margin: 1.5rem 0 1.5rem;
  }

  &__item {
    flex: 1 1 auto;
    width: 20%;
    margin-right: 3rem;

    &:last-child {
      margin-right: 0;
    }
  }
  
  &__input {
    width: 100%;
    display: block;
    
    & .input__input {
      padding-left: 1.5rem;
    }
  }
}
.order-history {
	text-align: center;
	
	&__title {
		.h4();
		margin-bottom: 1.8rem;
	}
	
	&__table {
		width: 100%;
		.font();
		font-size: 1.4rem;
		text-transform: uppercase;
		
		
		tbody {
			font: inherit;
		}
		
		tr {
			font: inherit;
		}
		
		td {
			font: inherit;
			border: 1px solid @color-violet-lightdark-3;
			border-right-width: 0;
			border-left-width: 0;
			border-bottom-width: 0;
			padding: 1.2rem 2rem;
			
			
			&:first-child {
				border-left-width: 1px;
				width: 10rem;
			}
			
			&:nth-child(2) {
				//width: 58.9%;
			}
			
			&:last-child {
				width: 14rem;
				border-right-width: 1px;
				text-align: right;
			}
		}
		
		tr:first-child {
			td:first-child {
				border-top-left-radius: @radius;
			}
			
			td:last-child {
				border-top-right-radius: @radius;
			}
		}
		
		tr:last-child {
			td {
				border-bottom-width: 1px;
			}
			
			td:first-child {
				border-bottom-left-radius: @radius;
			}
			
			td:last-child {
				border-bottom-right-radius: @radius;
			}
		}
	}
	
	&__link {
		font: inherit;
		color: @color-blue;
		
		&:hover {
			color: white;
		}
		
		&--orange {
			color: @color-orange;
			
			&:hover {
				color: @color-blue;
			}
		}
	}
	
	&__cell-content {
		width: 100%;
		display: flex;
		font: inherit;
	}
	
	&__cell-text {
		width: 10rem;
		flex: 1 1 10rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.slider-pagination {
	display: flex;
	flex-wrap: nowrap;
	&__item {
		display: block;
		width: 1.4rem;
		height: 1.4rem;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		padding: .3rem;
		opacity: .3;
		transition: opacity @time;
		margin: .3rem;
		
		&::after {
			display: block;
			content: '';
			position: relative;
			background-color: white;
			width: 100%;
			height: 100%;
			border-radius: 100%;
		}
		
		&:hover {
			opacity: 1;
		}
		
		&._active {
			opacity: 1;
			pointer-events: none;
		}
	}
	
	&._lock {
		pointer-events: none;
		visibility: hidden;
	}
}
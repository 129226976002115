@media @xs-max {
	.input-group {
		
		
		&__input {
			flex: 1 1 100%;
			width: 100%;
			margin: 1.5rem 1.5rem 0;
			
			& > .input__title {
				margin-bottom: .8rem;
			}
			
			.input__head {
				margin-bottom: .8rem;
			}
		}
		
		
		
	}
}
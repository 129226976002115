.promo {
  //background: @color-violet-darklight;
  background-image: url(../img/png/gun-1_detail.png), url(../img/png/background-divider.png), url(../img/png/gun-1.png);
  background-repeat: no-repeat, repeat-x, no-repeat;
  background-position: calc(~"100% -" 28.7rem) calc(~"100% +" 0.1rem), left calc(~"100% +" 1.5rem), right calc(~"100% +" 1.1rem);
  
  &__content {
    padding-top: 2rem;
    min-height: 30rem;
  }
  
  &__main {
    padding-right: 55rem;
    padding-bottom: 14rem;
  }
  
  &__sub-title {
    margin: 0 0 2.5rem;
    
    font-size: 2.7rem;
  }
  
  &__text {
    line-height: 1.4em;
  }
}
@media @xs-max {
  .section-header {
  
   
    
    &._wrap {
      flex-wrap: wrap;
  
      //& > .section-header__title  {
      //  flex-grow: 1;
      //  width: 50%;
      //}
			//
      //.section-header__titles-wrap {
      //  flex-grow: 1;
      //  width: 50%;
      //}
    }
  
    &._wrap &__short-btn {
      //margin-left: 0;
    }
  
    &._wrap &__short-btn-wrap {
      //width: 100%;
      //margin-top: 1rem;
    }
  
    &._wrap &__select {
      width: 100%;
      margin: 2.5rem 0 0;
    }
  
    &__subtitle {
      margin-bottom: .3rem;
    }
    
  }
}
@media @lg-max {
  .buying {

    //---new

    &__info--mod {
      padding-top: 1.4rem;
    }

    &__main-row {
      .buying__group {
        flex-basis: 40%;
      }
    }

    &__info-input {
      flex: 1 1 40%;
      flex-wrap: wrap;

      .input__title {
        margin-right: 0;
        margin-bottom: 1.2rem;
      }

      .input__row {
        width: 100%;
      }
    }

    &__info-items {
      width: 10%;
      flex: 1 1 40%;
      flex-wrap: wrap;
      margin-top: -1.5rem;
      align-self: flex-end;
      .buying__info-item {
        width: 100%;
        padding-right: 0;
        margin-top: 1.5rem;
      }
    }

  }
}

@media @xs-max {
  .buying {
    &__main,
    &__info,
    &__value-wrap,
    &__discounts,
    &__values-block {
      padding-left: @side-padding-mob;
      padding-right: @side-padding-mob;
    }

    &__main-row {
      .buying__group {
        flex-basis: 100%;
      }
    }

    &__info-row {
      flex-wrap: wrap;
    }

    &__info-input,
    &__info-items {
      flex-basis: 100%;
    }

    &__info-input {
      margin-bottom: 2rem;
    }

    &__info-items {
      flex-wrap: nowrap;
      margin-top: 0;

      .buying__info-item-title {
        white-space: normal;
        width: 100%;
        margin-right: 0;
      }

      .buying__info-item {
        width: auto;
        flex: 1 1 40%;
        margin-top: 0;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .buying__info-item-content {
        flex-wrap: wrap;
        align-content: flex-start;
      }
    }
  }
}
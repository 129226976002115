@rating-bg: linear-gradient(to right, #e99717, #db5121);
@rating-bg-2: #584263;
@rating-bg-3: #773281;
@rating-bg-4: #00aba5;
@rating-bg-5: #ab6505;
@rating-bg-6: #ab2778;

.rating-range {
  padding: 7rem 0 4rem;

  &__slider,
  &__sub-slider {
    background: #3e374a;
    border: 0;
    border-radius: @radius;
    box-shadow: none;
    height: 1rem;
  }

  & .noUi-connects {
    display: block;
    cursor: pointer;
  }

  & .noUi-connect {
    display: block;
    background: @rating-bg;
  }

  & .noUi-handle {
    box-shadow: none;
    border: 0.8rem solid white;
    border-radius: 50%;
    background: #e99717;
    //background: transparent;
    top: -8px;
    cursor: pointer;

    &::before,
    &::after {
      display: none;
    }
  }

  & .noUi-horizontal .noUi-handle {
    right: -1.3rem !important;
    left: auto;
    width: 2.6rem;
    height: 2.6rem;
  }

  & .noUi-pips-horizontal {
    height: 4rem;
  }

  & .noUi-pips {
    //left: 0.5rem;
    //width: calc(~"100% - " 1rem);

    & .noUi-marker {
      top: 1.1rem;
      background: #483954;
      width: 1px;
      height: 0.8rem;
    }

    & .noUi-value {
      top: 1.6rem;
      font-size: 1.2rem;
      color: #a27ba8;
    }
  }

  &__tooltip {
    position: absolute;
    bottom: calc(~"100% + " 2rem);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tooltip-title {
    margin: 0 0 0.1rem;

    font-family: @source-sans;
    font-size: 1.4rem;
    color: fade(white, 60);
    text-align: center;
    white-space: nowrap;
    text-wrap: normal;
    word-break: normal;
  }

  &__tooltip-content,
  &__tooltip-editor {
    position: relative;
  }

  &__tooltip-content {
    display: flex;
  }

  &__tooltip._edit &__tooltip-content {
    display: none;
  }

  &__tooltip-value,
  &__tooltip-input {
    padding: 0.4rem 0.5rem 0.5rem;

    font-weight: @regular;
    font-size: 1.8rem;
    font-family: @source-sans;
    white-space: nowrap;
    text-wrap: normal;
    word-break: normal;
    text-align: center;

    transition: color @time;
  }

  &__tooltip-value {

    &._from {
      padding-right: 0;
    }
  }

  &__tooltip-editor {
    display: none;
  }

  &__tooltip._edit &__tooltip-editor {
    display: flex;
  }

  &__tooltip-input {
    background: #3e374a;
    border-radius: @radius;
    width: 6rem;

    color: white;
  }

  &__tooltip-btn {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 2.2rem;
    height: 2rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: white;

      transition: @time;
    }

    &:hover svg {
      fill: @color-mint-light
    }
  }

  &__tooltip-content:hover &__tooltip-value {
    color: @color-blue;
  }

  &__tooltip-content:hover svg {
    fill: @color-blue;
  }

  // - - - -

  &._split {
    padding-bottom: 0;
  }

  &._split &__slider {
    margin-bottom: 6.5rem;
  }

  &__sub-list {
    margin-bottom: 6.6rem;
  }


  &__sub-slider-wrap {
    display: flex;
    margin-top: 11rem;
  }

  &__sub-slider {
    height: 0.6rem;
    width: 50%;
    flex-grow: 1;
    margin-top: 0.6rem;

    & .noUi-handle {
      top: -1rem;
    }
  }

  //&__tooltip-value._from {
  //  display: none;
  //}

  &__sub-slider .noUi-connects {
    overflow: visible !important;
  }

  &__sub-slider .noUi-connect {
    border-radius: 0.3rem;
  }

  &__sub-slider .noUi-touch-area::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 2.1rem;
    height: 4rem;
    border-left: 0.1rem dashed #584263;
  }

  //
  //
  //&__sub-slider .noUi-connect::after {
  //  content: '';
  //  position: absolute;
  //  left: 0;
  //  top: 5.9rem;
  //  width: 100%;
  //  border-top: 0.1rem dashed #584263;
  //}


  &__sub-slider-line {
    position: absolute;
    left: 0;
    top: 5.9rem;
    width: 0;
    border-top: 0.1rem dashed #584263;
  }

  &__sub-slider-wrap:last-child .noUi-touch-area::after,
  &__sub-slider-wrap:last-child &__sub-slider-line {
    display: none;
  }

  &__sub-slider-wrap ~ &__sub-slider-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 1rem;
      height: 6.9rem;
      width: 0.1rem;
      border-left: 0.1rem dashed #584263;
    }
  }

  // - - - -

  &__sub-slider .noUi-connect,
  &__sub-slider .noUi-handle {
    background: @rating-bg-2;
  }

  &__sub-slider-wrap:nth-child(1n) .noUi-connect,
  &__sub-slider-wrap:nth-child(1n) .noUi-handle,
  &__slider .noUi-connect:nth-child(1n) {
    background: @rating-bg-2;
  }

  // -

  &__sub-slider-wrap:nth-child(2n) .noUi-connect,
  &__sub-slider-wrap:nth-child(2n) .noUi-handle,
  &__slider .noUi-connect:nth-child(2n) {
    background: @rating-bg-3;
  }

  &__sub-slider-wrap:nth-child(2n) &__sub-slider-line,
  &__sub-slider-wrap:nth-child(2n) .noUi-touch-area::after {
    border-color: @rating-bg-3;
  }

  // -

  &__sub-slider-wrap:nth-child(3n) .noUi-connect,
  &__sub-slider-wrap:nth-child(3n) .noUi-handle,
  &__slider .noUi-connect:nth-child(3n) {
    background: @rating-bg-4;
  }

  &__sub-slider-wrap:nth-child(3n) &__sub-slider-line,
  &__sub-slider-wrap:nth-child(3n) .noUi-touch-area::after {
    border-color: @rating-bg-4;
  }


  &__sub-slider-wrap:nth-child(2n + 1)::before {
    border-color: @rating-bg-3;
  }

  // -

  &__sub-slider-wrap:nth-child(4n) .noUi-connect,
  &__sub-slider-wrap:nth-child(4n) .noUi-handle,
  &__slider .noUi-connect:nth-child(4n) {
    background: @rating-bg-5;
  }

  &__sub-slider-wrap:nth-child(4n) &__sub-slider-line,
  &__sub-slider-wrap:nth-child(4n) .noUi-touch-area::after {
    border-color: @rating-bg-5;
  }

  &__sub-slider-wrap:nth-child(3n + 1)::before {
    border-color: @rating-bg-4;
  }

  // -

  &__sub-slider-wrap:nth-child(5n) .noUi-connect,
  &__sub-slider-wrap:nth-child(5n) .noUi-handle,
  &__slider .noUi-connect:nth-child(5n) {
    background: @rating-bg-6;
  }

  &__sub-slider-wrap:nth-child(5n) &__sub-slider-line,
  &__sub-slider-wrap:nth-child(5n) .noUi-touch-area::after {
    border-color: @rating-bg-6;
  }

  &__sub-slider-wrap:nth-child(4n + 1)::before {
    border-color: @rating-bg-5;
  }

  // -


  &__sub-slider-wrap:nth-child(5n + 1)::before {
    border-color: @rating-bg-6;
  }

  //&__sub-slider-wrap:nth-child(6n) .noUi-connect::after,
  //&__sub-slider-wrap:nth-child(6n) .noUi-touch-area::after {
  //  border-color: @rating-bg-4;
  //}
  //
  //&__sub-slider-wrap:nth-child(7n)::before {
  //  border-color: @rating-bg-4;
  //}

  // -


  &__slider .noUi-connect:last-child {
    background: @rating-bg;
  }

  &__sub-slider-wrap:last-child &__sub-slider-line,
  &__sub-slider-wrap:last-child .noUi-touch-area::after {
    display: none;
  }

  //&__sub-slider-wrap:last-child::before {
  //  border-color: #e99717;
  //}

  // - - - / -

  &__slider .noUi-origin {
    display: none;
  }

  &__slider .noUi-origin:nth-child(2),
  &__slider .noUi-origin:last-child {
    display: block;
  }

  &__slider .noUi-connects {
    pointer-events: none;
  }

  &__sub-slider-delete {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: @radius;
    border: 0.1rem solid #5d4d65;
    flex-shrink: 0;
    margin-left: 1.4rem;
    cursor: pointer;

    &:hover {
      border-color: #928d94;
    }

    & svg {
      width: 0.8rem;
      height: 0.8rem;
      fill: white
    }
  }
}
.user-bar {
  display: flex;
  font-size: 1.4rem;
  letter-spacing: .02em;
  text-transform: uppercase;
  justify-content: flex-end;

  &__login-wrap {
    display: flex;
    //margin-right: 3rem;
  }

  &__sign-in {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;

    svg {
      width: 1.1rem;
      height: 1.5rem;
      fill: currentColor;
      margin-right: 1rem;
    }
  }

  
  
  &__user-count {
    margin-right: 1.5rem;
    white-space: nowrap;
    flex-shrink: 0;
  }

  &__user-info-wrap {
    display: flex;
    justify-content: flex-end;
    width: 20rem;
    //flex-basis: 20rem;
    flex-shrink: 1;
    flex-grow: 1;
    margin-right: 2rem;
  }
  
  &__user-info {
    //margin-right: 3rem;
    //width: 10rem;
    flex: 0 1 auto;
    max-width: 100%;
  }

  //&__menu-trigger {
  //  display: none;
  //}
}
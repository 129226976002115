@media @lg-max {
	.param-group {
		&__checkbox-item {
			width: calc(~'(100% - 1.5rem * 2) / 2');
			margin-right: 1.5rem;
			margin-top: 1rem;
		}
	}
}

@media @xs-max {
	.param-group {
		&__head {
			padding-left: @side-padding-mob;
			padding-right: @side-padding-mob;
			margin-bottom: 2rem;
			&::after {
				left: @side-padding-mob;
			}
		}
		
		&__items-list {
			padding-left: @side-padding-mob;
			padding-right: @side-padding-mob;
		}
		
		&__item {
			flex-wrap: wrap;
			align-items: stretch;
			margin-bottom: 1.5rem;
			
			&._align-center {
				align-items: stretch;
			}
			
			&._has-range {
				.param-group__item-name {
					min-height: auto;
					padding-top: 0;
				}
			}
		}
		
		&__item-name {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
		}
		
		&__item-subtitle {
			margin-bottom: 0;
		}
		
		&__item-content {
		
		}
		
		&__checkbox-list {
			margin-right: 0;
		}
		
		&__checkbox-item {
			width: 100%;
			margin-right: 0;
			margin-top: 1rem;
		}
		
		&__parameter {
			padding: @side-padding-mob;
		}
		
		&__parameter-list {
			padding: @side-padding-mob;
			
		}
		
		&__item-del {
			margin-left: 1rem;
		}
		
		&__item-add-row {
			margin-top: 0;
			padding-left: 0;
		}
	}
}
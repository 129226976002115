.date-time-picker {
	display: flex;
	.font();
	
	&__text-left {
		align-self: center;
		margin-right: 2rem;
		font: inherit;
		font-size: 1.6rem;
	}
	
	&__date-picker {
		font: inherit;
		width: 10.5rem;
		margin-right: 3rem;
		
		.input__input {
			padding-left: 1rem;
			padding-right: 2.5rem;
		}
	}
	
	&__time-picker {
		display: flex;
		font: inherit;
	}
	
	&__time-item {
		width: 5.5rem;
		margin-right: 2rem;
		font: inherit;
		position: relative;
		
		&::after {
			font: inherit;
			font-size: 1.6rem;
			line-height: 1;
			content: ':';
			position: absolute;
			right: -1.2rem;
			top: 50%;
			transform: translateY(-50%);
		}
		
		&:last-child {
			margin-right: 0;
			&::after {
				display: none;
			}
		}
		
		.selectbox-select-text {
			padding-left: 1rem;
			padding-right: 0;
		}

		.selectbox-dropdown li {
			padding: 1.2rem 1.2rem;
			text-align: center;
		}
	}
}
@media @sm-max {
	.bottom-panel {
		&__inner {
			padding-left: @side-padding-mob;
			padding-right: @side-padding-mob;
		}
	}
}

@media @xs-max {
	.bottom-panel {
		
		
		&__inner {
		
		}
		
		&__content {
		
		}
		
		&__col {
			flex-grow: 1;
			justify-content: space-between;
		}
	}
}
.setup-gallery {
	position: relative;
	
	&__gallery-wrap {
		position: relative;
		width: 100%;
		
		&::before {
			content: '';
			position: absolute;
			width: 3rem;
			right: 100%;
			top: 0;
			bottom: 5rem;
			background-color: #3e374a;
			z-index: 1;
		}
	}
	
	&__navs {
		bottom: 100%;
		left: -3rem;
		position: absolute;
	}
	
	&__gallery {
		overflow: hidden;
		position: relative;
		display: flex;
		width: 100%;
	}
	
	&__gallery-list {
		width: 100%;
		display: flex;
	}
	
	&__gallery-slide {
		position: relative;
		width: 100%;
		display: flex;
		flex-shrink: 0;
		
		&._filled {
			.dropzone__area {
				border-color: transparent;
			}
			
			.setup-gallery__slide-img-wrap {
				opacity: 1;
			}
			
			.setup-gallery__slide-btn-del {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	
	&__slide-img-wrap {
		display: block;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		opacity: 0;
		
		&::after {
			position: absolute;
			content: '';
			height: 40%;
			width: 100%;
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, fade(#362940, 0), #362940);
			z-index: 5;
			pointer-events: none;
		}
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	&__slide-btn-del {
		position: absolute;
		top: 1rem;
		right: 1rem;
		opacity: 0;
		visibility: hidden;
	}
	
	&__setup {
		position: relative;
		display: flex;
		margin-top: -3.5rem;
		width: 100%;
	}
	
	&__setup-list {
		width: 100%;
		display: flex;
	}
	
	&__setup-slide {
		position: relative;
		width: 100%;
		display: flex;
		flex-shrink: 0;
		.font();
		z-index: 2;
		
		&:not(.swiper-slide-active) {
			pointer-events: none;
		}
	}
	
	&__setup-slide-content {
		width: 100%;
		padding: 0 3rem;
		
		&>*:last-child {
			margin-bottom: 0;
		}
	}
	
	&__setup-slide-top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font: inherit;
		margin-bottom: 1.5rem;
	}
	
	&__setup-checkbox {
		display: inline-flex;
		align-items: center;
		font: inherit;
		font-size: 1.2rem;
	}
	
	&__setup-position {
		font: inherit;
		display: inline-flex;
		align-items: center;
	}
	
	&__setup-position-label {
		font: inherit;
		font-size: 1.2rem;
		margin-right: 1rem;
		text-align: right;
	}
	
	&__setup-position-select {
		width: 7rem;
		font: inherit;
		
		&:last-child {
			margin-right: 0;
			&::after {
				display: none;
			}
		}
		
		.selectbox-select-text {
			padding-left: 1rem;
			padding-right: 0;
		}
		
		.selectbox-dropdown li {
			padding: 1.2rem 1.2rem;
			text-align: center;
		}
	}
	
	&__input-group {
		margin-bottom: 1.5rem;
	}
	
	&__position-slider {
		position: absolute;
		bottom: 100%;
		right: 2rem;
		left: 10rem;
		margin-bottom: .6rem;
		display: flex;
		visibility: hidden;
	}
	
	&__position-slider-list {
		width: 100%;
		display: flex;
	}
	
	&__setup-position._slide {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		flex-shrink: 0;
		.font();
		z-index: 2;
		
		&:not(.swiper-slide-active) {
			pointer-events: none;
			//visibility: hidden;
		}
	}
}
@media @md{
	.game-box-custom {
		.game-box-list>li {
		
		}
		
		.game-box-item-box {
		
		}
		
		.game-box-item {
		
		}
	}
}

@media @lg {
	.game-box-custom {
		
		.game-box-list>li {
			width: 23.42vw;
			height: 23.42vw;
		}
		
		.game-box-item-box {
		
		}
		
		.game-box-item {
			transform-origin: 50% 50% -11.71vw;
		}
	}
}

@media @xl {
	.game-box-custom {
		.game-box-list>li {
			width: 45rem;
			height: 45rem;
		}
		
		.game-box-item-box {
		
		}
		
		.game-box-item {
			transform-origin: 50% 50% -22.5rem;
		}
	}
}

@media @sm-max {
	.game-box-custom {
		
		&__title {
			font-size: 4rem;
			margin-bottom: 3rem;
		}
		.game-box-list>li {
		}
		
		.game-box-item-box {
		
		}
		
		.game-box-item {
		
		}
	}
}

@media @xs-max {
	.game-box-custom {
		padding: 0;
		
		&__title {
			font-size: 2.7rem;
		}
		
		.game-box-list {
			grid-template-columns: auto auto !important;
			margin-bottom: -1vw;
		}
		
		.game-box-list>li {
			width: 49.5vw;
			height: 49.5vw;
			margin-bottom: 1vw;
		}
		
		.game-box-item-box {
		
		}
		
		.game-box-item {
		
		}
	}
}


.user-menu {
  width: 100%;
  background: #1f1928;
  padding: 2rem 0 0;
  
  &__list {
    padding: 0 2.5rem;
    margin-bottom: 2.6rem;
  }
  
  &__item {
    margin-bottom: 1.8rem;
  }
  
  &__link {
    display: inline-block;
    position: relative;
    padding-left: 3.4rem;
    
    .font();
    
    &:hover {
      color: @color-blue;
    }
    
    & svg {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 2.2rem;
      height: 1.8rem;
      fill: @color-blue;
      
      transition: filter @time;
    }
    
    //&:hover svg {
    //  filter: drop-shadow( 0 0 6px fade(@color-blue, 80));
    //}
    
    &::before {
      content: '';
      position: absolute;
      top: 0.8rem;
      left: 1rem;
      width: 0.1rem;
      height: 0.1rem;
      background: fade(@color-blue, 80);
      box-shadow: 0 0 0.9em 0.7rem fade(@color-blue, 80);
      border-radius: 50%;
      opacity: 0;
      
      transition: opacity @time;
    }
    
    &:hover::before {
      opacity: 1;
    }
  }
  
  &__logout {
    width: 100%;
    padding: 1.2rem 2.4rem;
    justify-content: flex-start;
    background: #1b1524;
    
    .font();
    color: @color-blue;
    
    &:hover {
      background-color: @color-violet;
    }
    
    & svg {
      width: 0.4rem;
      height: 0.6rem;
      margin-left: 0.8rem;
    }
  }
}
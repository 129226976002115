.sell-order {
	background-image: url(../img/jpg/sell-order-bg-1.jpg), url(../img/jpg/sell-order-bg-2.jpg);
	background-repeat: no-repeat, no-repeat;
	background-position: left top, right 140rem;
	
	&__pagination {
		margin-bottom: 1.8rem;
	}
	
	&__title {
		.h2();
		padding: 0;
		line-height: 1;
		padding-right: 18rem;
	}
	
	&__import-products {
		margin-top: -4.2rem;
		margin-bottom: 4rem;
	}
	
	&__steps-tabs {
		margin-bottom: 4.5rem;
	}
	
	&__input-group {
		margin-bottom: 1.5rem;
	}
	
	&__product-setup {
		margin-top: 4rem;
		margin-bottom: 3.5rem;
	}
	
	&__add-tags {
		width: 100%;
		margin-bottom: 6rem;
	}

	// - - - - p

	&__save-section-btn {
		width: 100%;
		padding: 1.6rem 2rem 1.7rem;
		border-radius: 0 0 @radius @radius;
		background: @color-mint;
		box-shadow: 0 1.9rem 5.4rem rgba(0, 171, 186, 0.44);

		text-transform: uppercase;

		&:hover {
			background-color: @color-mint-light;

			color: white;
		}

		&._disabled {
			background-color: @color-violet-lightdark-3;
			box-shadow: none;

			color: fade(white, 30);
		}
	}

	&__set-price {
		margin-bottom: 4.4rem;
	}
	
	&__quantity-discount {
		margin-bottom: 4.4rem;
	}
	
	&__customers-price {
		margin-bottom: 4.4rem;
	}
	
	&__create-extra-options {
		margin-bottom: 4.4rem;
	}
	
	//tabs
	
	&__tabs {
		justify-content: flex-start;
		margin-bottom: 3.4rem;
	}
	
	&__tab-block {
		display: none;
		//margin-bottom: 4rem;
	}
	
	&__tab-block._open {
		display: block;
		animation: fadeIn @time-modal;
	}
	
	&__footer {
		.font();
		margin-top: 2.6rem;
	}
	
	&__checkbox {
		font: inherit;
		margin-bottom: 3rem;
	}
	
	&__footer-navs {
		display: flex;
		flex-wrap: wrap;
	}
	
	&__footer-btn {
		margin-right: 2rem;
		
		&:last-child {
			margin-right: 0;
		}
		
		&._back {
			background: transparent;
			border: 0.1rem solid #493752;
			border-radius: @radius;
			
			& svg {
				width: 0.4rem;
				height: 0.6rem;
				margin-right: 0.8rem;
			}
			
			&:hover {
				border-color: #5d4d65;
			}
		}
	}
	
	&__download-block {
		margin-top: 2.5rem;
		margin-bottom: 5rem;
	}
	
	&__accordion {
		margin-bottom: 5rem;
	}
}
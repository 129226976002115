@media @md-max {
	.similar {
		
		&__inner {
			padding-bottom: 1rem;
			&::before,
			&::after {
			
			}
			
			&::before {
			
			}
			
			&::after {
			
			}
		}
		
		&__content {
		
		}
		
		&__title {
		
		}
	}
}

@media @sm-max {
	.similar {
		
		&__inner {
			padding-top: 0;
			&::before,
			&::after {
			
			}
			
			&::before {
			
			}
			
			&::after {
			
			}
		}
		
		&__content {
		
		}
		
		&__title {
			font-size: 4rem;
		}
	}
}

@media @xs-max {
	.similar {
		
		&__inner {
			padding-bottom: 0;
			&::before,
			&::after {
			
			}
			
			&::before {
			
			}
			
			&::after {
			
			}
		}
		
		&__content {
		
		}
		
		&__title {
			font-size: 2.7rem;
			margin-bottom: 2.5rem;
		}
	}
}
.header-reg {
	display: flex;
	position: relative;
	
	&__toggle {
		padding: 1rem 1.5rem;
		border: 0;
		
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: -.2rem;
			background-color: #1f1928;
			box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
			z-index: 1;
			opacity: 0;
			transition: opacity @time;
		}
		
		span {
			position: relative;
			z-index: 5;
		}
		
		&::after {
			position: relative;
			z-index: 6;
			border-top-color: currentColor;
		}
		
		svg {
			position: relative;
			z-index: 6;
			width: 1.1rem;
			height: 1.5rem;
			fill: currentColor;
			display: none;
		}
	}
	
	&__block {
		.font;
		position: absolute;
		top: calc(~'100% + .2rem');
		right: 0;
		background: #1f1928;
		display: none;
		min-width: 22.5rem;
		padding: 3rem 2rem;
		text-align: center;
		z-index: 30;
	}
	
	&__title {
		font: inherit;
		color: inherit;
		text-transform: none;
		margin-bottom: 1.3rem;
	}
	
	&__buttons {
		display: flex;
		flex-direction: column;
		padding: 0 1rem;
	}
	
	&__btn {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__sign-btn {
		width: 100%;
		margin-bottom: 2rem;
		display: none;
		svg {
			width: 1.1rem;
			height: 1.5rem;
			fill: currentColor;
			margin-right: 1rem;
		}
	}
	
	&._open &__toggle {
		color: @color-blue;
		&::before {
			opacity: 1;
		}
		
		&::after {
			transform: rotate(180deg);
		}
	}
	
	
	&._open &__block {
		display: block;
		animation: fadeIn @time-modal;
	}
}
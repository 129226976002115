.how-it-work {
  background: #ededf1;
  
  &__content {
    padding: 5rem 0;
  }
  
  &__title {
    margin: 0 0 3rem;
    
    font-size: 2.7rem;
    text-align: center;
    color: @color-violet-darklight;
  }
  
  
  &__list {
    display: flex;
  }
  
  &__item {
    position: relative;
    flex: 1 0 5%;
    margin-right: 4%;
    padding-right: 2rem;
    
    &:last-child {
      margin-right: 0;
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 3.5rem;
      right: 2rem;
      width: 5.3rem;
      height: 1.2rem;
      background: url(../img/svg/arrow-right-fast.svg) no-repeat center;
      background-size: contain;
    }
    
    &:last-child::after {
      display: none;
    }
  }
  
  &__item-title {
    font-family: @source-sans;
    font-weight: @semibold;
    color: @color-violet-darklight;
  }
  
  &__item-ico {
    width: 8rem;
    height: 8rem;
    background: no-repeat left center;
    background-size: contain;
    margin-bottom: 1.8rem;
  }
}
@media @xs-max {
  .inline-alert {
    margin-left: -@side-padding-mob;
    margin-right: -@side-padding-mob;
    
    &__close {
      display: none;
    }
    
    &__text {
      padding-right: 0;
    }
    
    &__footer {
      margin-left: -3.1rem;
    }
    
    &__btn {
      width: 100%;
    }
  }
}
@media @md-max {
  .order-creator-descr {
    
    &__content::before {
      left: 9rem;
    }
    
    &__main {
      padding-left: 47rem;
    }
  }
}

@media @sm-max {
  .order-creator-descr {
    background-position: center top, -14.7rem 17%;
    background-size: auto, auto 60rem;
    
    &__content::before {
      left: 1rem;
      transform: translateY(-44%);
      width: 28rem;
    }
    
    &__main {
      padding-left: 33.5rem;
    }
  }
}

@media @xs-max {
  .order-creator-descr {
    background-position: center top, -14.7rem 17%;
    background-size: auto, auto 60rem;
  
    &__content {
      overflow: hidden;
      padding-top: 7rem;
      padding-bottom: 33rem;
    }
    
    &__content::before {
      left: 1rem;
      top: auto;
      bottom: -9rem;
      width: 25rem;
      transform: none;
    }
    
    &__main {
      padding-left: 0;
    }
  }
}
.order-creator-descr {
  position: relative;
  background: @color-violet;
  background-image: url(../img/png/background-divider-top.png), url(../img/jpg/order-creator-descr-bg.jpg);
  background-position: center top,  left 45%;
  background-repeat: repeat-x, no-repeat;
  padding-top: 40px;
  
  
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42rem;
    padding: 8rem 0;
    
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      left: 11.5rem;
      top: calc(~"50% +" 4rem);
      transform: translateY(-50%);
      width: 35.3rem;
      height: 43.3rem;
      background: url(../img/png/simbol-1.png) no-repeat center;
      background-size: contain;
    }
  }
  
  
  &__main {
    padding-left: 55rem;
    margin: auto 0;
  }
  
  &__title {
    margin: 0 0 2.5rem;
    
    font-size: 2.7rem;
  }
  
  &__text {
    line-height: 1.4em;
  }
}
.dropzone {
	.font();
	width: 100%;
	padding: 3rem 3rem 5rem;
	
	
	&__area {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		border: 1px dashed #4f3f57;
		border-radius: @radius;
		font: inherit;
		cursor: pointer;
		position: relative;
		z-index: 20;
		transition: border-color @time;
		
		&::before {
			content: '';
			position: relative;
			display: block;
			width: 100%;
			padding-top: 48.5%;
		}
		
		& input {
			position: absolute;
			visibility: hidden;
			width: 0;
			height: 0;
			left: 0;
			top: 0;
		}
	}
	
	&__btn {
		position: absolute;
		//top: 50%;
		//left: 50%;
		//transform: translate(-50%, -50%);
		display: flex;
		width: 12rem;
		min-height: 9.6rem;
		font: inherit;
		background-color: @color-mint;
		color: white;
		padding: 1.5rem 1rem 1rem;
		border-radius: @radius;
		transition: background-color @time;
		
		
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	
	&__btn-text {
		font: inherit;
		margin-top: 1rem;
	}
	
	&__btn-plus {
		width: 2.6rem;
		height: 2.6rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		color: inherit;
		
		&::before,
		&::after {
			position: absolute;
			content: '';
			background-color: currentColor;
		}
		
		&::before {
			width: .4rem;
			height: 100%;
			top: 0;
		}
		
		&::after {
			width: 100%;
			height: .4rem;
			left: 0;
		}
	}
	
	&__area:hover &__btn {
		background-color: @color-blue;
	}
}
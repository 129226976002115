.select-game-carousel {
  
  &__sub-title {
    margin: 0 0 0.2rem;
    
    font-family: @source-sans;
    font-weight: @regular;
    color: fade(white, 60);
    font-size: 1.4rem;
  }
  
  &__carousel-outer {
    position: relative;
    
  
    &::after {
      content: '';
      position: absolute;
      top: 1rem;
      left: 100%;
      width: 3rem;
      height: 7.4rem;
      background-color: @color-violet-lighter;
    }
  }
  
  &__carousel-wrap {
    padding: 1rem 0 3rem;
    overflow: hidden;
  }
  
  &__navs {
    position: absolute;
    right: -3rem;
    top: -4rem;
  }
  
  &__carousel {
    position: relative;
    padding: 0 1.5rem;
    background: #362940;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      width: 14rem;
      height: 100%;
      background: linear-gradient(to right, #362940, fade(#362940, 0));
      pointer-events: none;
      opacity: 1;
      
      transition: opacity @time;
    }
    
    &::before {
      left: 0;
    }
    
    &::after {
      right: 0;
      background: linear-gradient(to left, #362940, fade(#362940, 0));
    }
  
  
  
    &._start::before,
    &._end::after {
      opacity: 0;
    }
  }
  
  &__list {
    display: flex;
  }
  
  &__item {
    position: relative;
    flex-shrink: 0;
    width: 14.4rem;
    padding: 1.5rem 1rem 1.7rem;
    display: block;
    text-decoration: none;
    cursor: pointer;
    
    text-align: center;
    
    & input {
      display: none;
    }
  }
  
  &__item-content {
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      //width: calc(~"100% + "2rem);
      width: 100%;
      height: 100%;
      border-radius: @radius;
      border: 0.1rem solid @color-mint;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0 1rem 1.6rem rgba(0, 171, 186, 0.44);
      
      transition: @time;
    }
  }
  
  &__tick {
    position: absolute;
    z-index: 42;
    top: 0.5rem;
    right: 0.6rem;
    opacity: 0;
    
    transition: @time;
    
    & svg {
      width: 1.4rem;
      height: 1.1rem;
      fill: @color-blue
    }
  }
  
  &__img-wrap {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  &__img {
    margin: 0 auto;
    max-width: 100%;
  }
  
  &__item-title {
    margin: 0;
    
    font-size: 1.4rem;
    font-family: @source-sans;
    font-weight: @regular;
    color: @color-violet-lightest;
    text-transform: none;
    
    transition: color @time;
  }
  
  &__item  input:checked ~ &__item-content::after {
    visibility: visible;
    opacity: 1;
    z-index: 6;
  }
  
  &__item input:checked ~ &__item-content &__tick {
    opacity: 1;
  }
  
  &__item input:checked ~ &__item-content &__item-title {
    color: @color-mint;
  }
  
  &__item:hover &__item-title {
    color: @color-mint;
  }
}
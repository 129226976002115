.page-cart {
  background-image: url(../img/jpg/cart-bg.jpg);
  background-repeat: no-repeat;
  background-position: left top;

  &__cart {
    padding-bottom: 8rem;
  }
  
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem;
  }
  
  &__title {
    margin-bottom: 0;
  }
  
  &__add {
    min-width: 13.4rem;
    
    & svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.8rem;
    }
  }
  
  &__cart-list-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 2.8rem;
    border: 0.1rem solid #493752;
    border-top-width: 0;
    margin-bottom: 1rem;
  }
  
  &__total-wrap {
    text-align: right;
    padding-right: 2.2rem;
  }
  
  &__total-old {
    font-size: 2rem;
    text-decoration: line-through;
    font-weight: @semibold;
  }
  
  &__total {
    position: relative;
    margin: 0.4rem 0 0.6rem;
    
    font-size: 2.7rem;
    font-family: @oswald;
    text-transform: uppercase;
    font-weight: @regular;
  
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 1.7rem;
      height: 1.7rem;
      transform: translate(0.6rem, -50%);
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
    }
  }
  
  &__total-value {
    margin-left: 0.4rem;
    color: @color-blue;
  }
  
  &__total-usd {
    font-size: 2rem;
    color: fade(@color-blue, 60);
  }
  
  &__bonus-wrap {
    margin-bottom: 0.6rem;
    
    text-align: right;
  }
  
  &__bonus {
    font-size: 1.4rem;
    color: @color-blue;
  }
  
  &__status-bar-wrap {
    margin-bottom: 6rem;
  }
  
  &__status-bar {
    
    & .status-bar__title {
      margin: 0 0 0.6rem;
      
      text-align: left;
      text-transform: none;
      font-size: 1.4rem;
      font-family: @source-sans;
      font-weight: @regular;
      color: fade(white, 60);
    }
  }
  
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  &__alert {
    flex-grow: 1;
    margin-right: 2.5rem;
    min-height: 5rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 1.3rem;
      right: -0.9rem;
      display: block;
      width: 2.3rem;
      height: 2.3rem;
      background: #3e374a;
      transform: rotate(45deg);
    }
  }
  
  &__btn {
    flex-shrink: 0;
    min-width: 12.8rem;
  }
}
.base-tooltip {
  position: relative;
  display: inline-block;
  
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: help;
    
    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: fade(white, 60);
      transition: 0.2s;
      &:hover {
        fill: #2dd6dc;
      }
    }
  }
  
  &__dropdown {
    display: none;
    position: absolute;
    z-index: 42;
    bottom: 2.6rem;
    left: 0.6rem;
    //transition: transform @time-modal;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-left: 9px solid #ededf1;
      border-bottom: 8px solid transparent;
    }
  }

  //&:hover &__dropdown {
  //  display: block;
  //  animation: fadeIn @time-modal;
  //}

  &__text {
    background: #ededf1;
    padding: 1.6rem 1.8rem 1.8rem;
    width: 20rem;

    .font();
    color: @color-violet-dark;
    font-size: 1.2rem;
    text-transform: none;
    text-decoration: none;
  }
}
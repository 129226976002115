.review-group {

  &__new-review {

    background-color: #362940;
    border-radius: @radius;
  }

  &__new-review-top {
    display: flex;
    .font;
    min-height: 4rem;
    padding: 0.6rem 3.5rem 0.6rem 2rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      border-top: .4rem solid currentColor;
      border-left: .3rem solid transparent;
      border-right: .3rem solid transparent;
      transition: .2s;
      top: 50%;
      right: 1.2rem;
      transform: translateY(-50%);
    }
  }

  &__new-review-label {
    display: flex;
    align-items: center;
    svg {
      fill: white;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1rem;
    }
    font: inherit;
    color: #a27ba8;
  }

  &__new-review-rating {
    visibility: hidden;
    opacity: 0;
    transition: opacity @time, visibility @time;
  }

  &__new-review-block {
    padding: 0 2rem 2rem;
    display: none;
  }

  &__review-form {
    .font;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__review-form-textarea {
    font: inherit;
    width: 100%;
    resize: none;
    color: #2d233c;
    padding: .5rem 1rem;
  }

  &__reviev-form-btn {
    width: 100%;

    &::before {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__new-review._open {
    .review-group__new-review-rating {
      opacity: 1;
      visibility: visible;
    }

    .review-group__new-review-top::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid #493752;
  }

  &__review-article,
  &__review-comment {
    padding-left: 0;
    padding-right: 0;
    background-color: @transparent;
  }

  &__review-comment {
    padding-top: 0;
    padding-left: 17rem;
    .review-article__head,
    .review-article__text {
      padding-left: 0;
    }

    .review-article__name {
      a {
        color: #db5121;

        &:hover {
          color: @color-blue;
        }
      }
    }
  }

  .review-article__head {
    margin-bottom: 1.8rem;
  }

  .review-article__time {
    margin-bottom: .2rem;
  }

  .review-article__name {
    margin-bottom: 0;
  }
  
  .catalog-pagination {
    margin-top: 5rem;
    margin-bottom: 2rem;
    ul {
      li {
        border-color: #493752;
        
        &:last-child {
          border-color: #493752;
        }
        
        a,
        span {
          color: white;
        }
      }
    }
  }
  
  &__load-more-wrap {
    text-align: center;
    margin: 2rem 0;
  }
}
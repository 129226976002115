.store-panel {
	height: 3.3rem;
	background-color: @color-violet-dark;
	display: flex;
	padding: .5rem 3rem;
	&__menu {
		.font;
		display: flex;
		align-items: center;
		
		
		li {
			font: inherit;
			color: inherit;
			margin-right: 2.5rem;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
	
	&__info {
		display: flex;
		margin-left: auto;
		align-items: center;
	}
	
	&__info-text {
		.font;
		font-size: 1.2rem;
		margin-right: 1.5rem;
	}
	
	&__main-logo {
		width: 9rem;
	}
}
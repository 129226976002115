.items-choice {
	.font();
	font-size: 1.2rem;
	width: 100%;
	
	
	&__container {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-right: -3.5rem;
		margin-top: -3.5rem;
		font: inherit;
	}
	
	& input {
		position: absolute;
		visibility: hidden;
		width: 0;
		height: 0;
		left: 0;
		top: 0;
	}
	
	&__item {
		width: 24.5rem;
		position: relative;
		font: inherit;
		margin-right: 3.5rem;
		margin-top: 3.5rem;
	}
	
	&__checkbox {
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		font: inherit;
		align-content: flex-start;
	}
	
	&__btn-remove {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: @radius;
		background-color: @color-orange;
		transition: background-color @time;
		color: white;
		
		&:hover {
			background-color: @color-orange-light;
			color: white;
		}
	}
	
	&__checkbox-img {
		display: block;
		width: 100%;
		padding-top: 55.9%;
		//height: 14rem;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: @radius;
		margin-bottom: 1rem;
		transform: translate3d(0,0,0);
		transition: transform @time*3, box-shadow @time*2;
	}
	
	&__checkbox-mark {
		position: relative;
		display: block;
		width: 2rem;
		height: 2rem;
		border: 0.1rem solid #5d4d65;
		border-radius: @radius;
		
		transition: border-color @time;
		flex-shrink: 0;
		margin-right: 1rem;
		
		&::before {
			content: '';
			position: absolute;
			top: 0.3rem;
			left: 0.3rem;
			display: block;
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 0.2rem;
			background: @color-orange;
			opacity: 0;
			
			transition: opacity @time;
		}
	}
	
	&__item:hover {
		.items-choice__checkbox-mark {
			border-color: #74637d;
			
		}
		
		.items-choice__checkbox-img {
			transform: scale(1.05);
			box-shadow: @box-shadow;
		}
	}
	
	& input:checked ~ &__checkbox-mark::before {
		opacity: 1;
	}
	
	&__checkbox-text {
		font: inherit;
		display: inline-flex;
		width: calc(~'100% - 3rem');
		align-self: center;
	}
	
	//--radio
	& input[type="radio"] ~ &__checkbox-mark,
	& input[type="radio"] ~ &__checkbox-mark::before {
		border-radius: 50%;
	}
	
	
	&__file {
		display: flex;
		width: 100%;
		padding-top: 55.9%;
		position: relative;
		cursor: pointer;
		font: inherit;
		color: @color-blue;
		
		&::before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 1px solid currentColor;
			border-radius: @radius;
		}
	}
	
	&__file-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font: inherit;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: inherit;
		transition: color @time;
	}
	
	&__file-text {
		font: inherit;
		display: inline-flex;
		margin-top: 1rem;
	}
	
	&__file-plus {
		width: 2.6rem;
		height: 2.6rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		color: inherit;
		
		&::before,
		&::after {
			position: absolute;
			content: '';
			background-color: currentColor;
		}
		
		&::before {
			width: .4rem;
			height: 100%;
			top: 0;
		}
		
		&::after {
			width: 100%;
			height: .4rem;
			left: 0;
		}
	}
	
	&__file:hover &__file-content {
		color: white;
	}
	
}

.add-tags {
	.font();
	position: relative;
	
	&__container {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1rem;
		margin-top: -1rem;
	}
	
	&__item {
		display: inline-flex;
		margin-right: 1rem;
		margin-top: 1rem;
	}
	
	&__tag-dropdown {
		font: inherit;
		//position: absolute;
		//z-index: 2;
		//top: 100%;
		//left: 0;
		margin-top: 1rem;
		display: none;
		width: 100%;
	}
	
	&__tag-field {
		font: inherit;
		display: flex;
		width: 28rem;
		max-width: 100%;
	}
	
	&__tag-field-input {
		flex-grow: 1;
		font: inherit;
	}
	
	&__tag-field-btn {
		font: inherit;
		
		background: @color-mint;
		border-radius: 0 @radius @radius 0;
		flex-shrink: 0;
		color: white;
		padding: .5rem 1.3rem;
		
		&:hover {
			background-color: @color-mint-light;
			color: white;
		}
	}
}
.inline-alert {
  position: relative;
  display: flex;
  background: #3e374a;
  padding: 1.8rem 2rem;
  
  &._lower {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  &__ico {
    width: 1.9rem;
    height: 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid @color-orange;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 1.2rem;
    margin-top: 0.1rem;
    
    & svg {
      width: 0.3rem;
      height: 1.1rem;
      fill: @color-orange
    }
  }
  
  &__close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0.3rem;
    right: 0.5rem;
    
    & svg {
      width: 1rem;
      height: 1rem;
      fill: white
    }
    
    &:hover {
      opacity: 0.6;
    }
  }
  
  &__content {
    flex-grow: 1;
  }
  
  &__text {
    padding-right: 1rem;
    
    color: white;
    line-height: 1.3em;
  }
  
  &__coins {
    position: relative;
    padding-right: 1.6rem;
    margin: 0 0.4rem;
    
    color: @color-orange;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
      transform: translateY(-0.5rem);
    }
  }
  
  &__inline-btn {
    padding: 0.3rem 0.8rem 0.5rem;
    border-radius: @radius;
    background: @color-orange;
    margin: -0.7rem .4rem;
    
    font-size: 1.4rem;
    line-height: @line-height;
    color: white !important;
    
    transition: box-shadow @time, background-color @time;
    
    &:hover {
      background-color: @color-orange-light;
      box-shadow: 0 0.9rem 1.3rem rgba(237, 118, 0, 0.44);
    }
  }
  
  &__footer {
    margin-top: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  &__btn {
    margin-right: 2rem;
    
    &:last-child {
      margin-right: 0;
    }
  }
}
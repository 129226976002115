.menu-tooltip {
	.font();
	color: @color-blue;
	text-transform: none;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	//padding-top: 3rem;
	
	&__text {
		font: inherit;
		color: inherit;
		width: 11rem;
		padding-top: 2.8rem;
	}
	
	&__arrow {
		margin-right: -.3rem;
		align-self: flex-start;
		width: 5rem;
		height: 5.5rem;
		flex-shrink: 0;
		
		&:hover {
			fill: #0d95e8;
		}
	}
}
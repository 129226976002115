@media @xs-max {
	.messages {
		display: block;
		position: relative;
		top: auto;
		right: auto;
		max-height: none;
		padding: 0;
		overflow: visible;
		width: 100%;
		
		
		&__inner {
			width: 100%;
		}
		
		&__items {
		
		}
		
		&__item {
		
		
		}
		
		&__item-close {
		
		}
		
		&__author {
			margin-bottom: 1.8rem;
		}
		
		&__author-ava {
		
		}
		
		&__author-info {
		
		}
		
		&__time {
		
		}
		
		&__title {
		
		}
		
		&__author-name {
		
		}
		
		&__text {
		
		}
		
		&__link {
		
		}
		
		&__attention-label {
			left: auto;
			right: 1rem;
			top: 1.2rem;
		}
		
		&__reply-wrap {
		
		}
		
		&__reply-dropdown {
		
		}
		
		&__reply-form {
		
		}
		
		&__reply-textarea {
		
		}
		
		&__reply-btn {
		
		}
		
		&__item:hover {
		
		}
		
		&__item._attention {
		
		}
	}
	
}
@select-category-marg: 2rem;

.special-tabs {
  
  //&__content {
  //  width: 50%;
  //}
  
  &__title {
    margin: 0 0 2.8rem;
    
    font-size: 2.7rem;
  }
  
  &__main {
    position: relative;
    margin-bottom: -2rem;
  }
  
  &__tabs-wrap {
  
  }
  
  &__tabs-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -@select-category-marg;
  }
  
  &__tab,
  &__item-mark {
    padding: 1.1rem 1.8rem 1.2rem;
    border: 0.1rem solid #5d4d65;
    border-radius: @radius;
    
    font-size: 1.4rem;
  }
  
  &__tab,
  &__item {
    margin: 0 0 @select-category-marg @select-category-marg;
  }
  
  &__tab {
    
    &:hover {
      border-color: #928d94;
      
      color: white;
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: -0.1rem;
      width: calc(~"100% + " 0.2rem);
      height: 2.1rem;
      background: #362940;
      opacity: 0;
      pointer-events: none;
    }
    
    &._open {
      background-color: #362940;
      border-color: #362940;
      border-radius: @radius @radius 0 0;
      
      color: white;
    }
  
    &._open::after {
      opacity: 1;
    }
  }
  
  &__items-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    
    margin-left: -@select-category-marg;
  }
  
  &__block {
    display: none;
    //position: absolute;
    //top: calc(~"100% + " 2rem);
    //left: -0.1rem;
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  
  &__block-content {
    background: #362940;
    padding: 3rem 3rem 1rem;
    overflow: hidden;
  }
  
  &__item {
    display: block;
  }
}
.tags-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  
  //&::after {
  //  content: '';
  //  display: flex;
  //  width: 100%;
  //  margin-top: -1rem;
  //}
  
  &__list {
    margin-bottom: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  &__tag {
    margin: 0 1rem 1rem 0 ;
    
    //&:last-child {
    //  margin-right: 0;
    //}
    
    &:not(.tags-list__tag--add):last-of-type {
      margin-right: 2rem;
    }
  }
  
  &--dots &__tag {
    &::before {
      content: ':';
      position: absolute;
      font: inherit;
      color: white;
      right: -1rem;
      top: .4rem;
    }
    
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
}
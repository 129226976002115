@media @sm-max {
	.store-panel {
		height: auto;
		margin-top: auto;
		padding: 3.4rem 2rem;
		&__menu {
			flex-direction: column;
			width: 100%;
			
			li {
				margin-right: 0;
				
				margin-bottom: 2.2rem;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				a {
					padding: .4rem 0;
				}
			}
		}
		
		&__info {
		
		}
		
		&__info-text {
		
		}
		
		&__main-logo {
		
		}
	}
}
.buying-small {
	position: relative;
	border-top: 1px solid #00aba5;
	
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0.1rem;
		min-width: 1px;
		height: calc(~"100% - 4.9rem");
		background: linear-gradient(to bottom, #00aba5, #db5121);
	}
	
	&::after {
		left: auto;
		right: 0;
	}
	
	&::after {
		background: linear-gradient(to bottom, #00aba5, #55375d);
	}
	
	&._cut::after {
		background: linear-gradient(to bottom, #00aba5, #db5121);
	}
	
	&__title {
		.h3;
		font-weight: @semibold;
		font-size: 2rem;
		padding: 2.5rem 3rem 2rem;
	}
	
	&__main {
		padding: 0 3rem;
	}
	
	&__group {
		width: 100%;
		margin-bottom: 2.4rem;
		.font;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__group-title {
		display: block;
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
		opacity: .6;
		margin-bottom: .5rem;
	}
	
	&__group-text {
		font: inherit;
		color: inherit;
		font-size: 1.6rem;
	}
	
	&__value-wrap {
		padding: 2.3rem 3rem 2.5rem;
		display: flex;
		flex-direction: column;
	}
	
	&__amount-wrap,
	&__price-wrap {
		width: 100%;
		.font();
		
	}
	
	&__amount-wrap {
		margin-bottom: 2.3rem;
	}
	
	&__bonuses {
		margin-bottom: 0.7rem;
		
		font: inherit;
		color: @color-blue;
	}
	
	&__amount {
		margin-bottom: 0.6rem;
	}
	
	&__available {
		display: flex;
		align-items: center;
		
		font: inherit;
		color: fade(white, 60);
		font-size: 1.2rem;
		
		& svg {
			margin-right: 0.5rem;
			width: 1.4rem;
			height: 1.1rem;
			fill: @color-blue
		}
	}
	
	&__price-wrap {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
	}
	
	&__price {
		display: inline-block;
		position: relative;
		padding-right: 2.2rem;
		
		font: inherit;
		color: white;
		font-weight: @semibold;
		font-size: 2rem;
		margin-right: 1rem;
		
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 1.7rem;
			height: 1.7rem;
			background: url(../img/png/coin-ico.png) no-repeat center;
			background-size: contain;
			transform: translateY(-50%);
		}
	}
	
	&__old-price {
		font: inherit;
		font-size: 1.2rem;
		color: fade(white, 60);
	}
	
	&__btns-wrap {
		display: flex;
	}
	
	&__btn {
		width: 50%;
		flex-grow: 1;
		
		&:first-child {
			z-index: 6;
		}
		
		&::before {
			border-radius: 0;
		}
		
		&:first-child::before {
			border-bottom-left-radius: @radius;
		}
		
		&:last-child::before {
			border-bottom-right-radius: @radius;
		}
	}

	//---new

	&__values-block {
		padding: 0 3rem;
		margin-top: 2.5rem;
		margin-bottom: 3rem;
	}
}
@media @sm-max {
  .products-list-toolbar {
    align-items: flex-start;

    &__editor {
      margin-right: auto;
    }

    &__import-toggle {
      margin-left: 2rem;
      background: @color-orange;
      border: 0.1rem solid @color-orange;
      border-bottom: 0;
      border-radius: @radius;
      padding: 1rem 2rem 1.2rem;
      box-shadow: 0 1.7rem 5rem rgba(217, 80, 34, 0.44);

      text-transform: uppercase;

      transition: @time;

      &:hover {
        color: white;
      }

      &::before {
        content: "";
        display: inline-block;
        vertical-align: .18em;
        border-top: .4rem solid white;
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        margin-right: .7rem;
        transition: .2s;
      }

      &._open {
        background: @color-violet-darklight;
        border-radius: @radius @radius 0 0;
        padding-bottom: 4.3rem;
        box-shadow: none;
      }

      &._open::before {
        transform: rotate(-180deg);
      }
    }

    &__ico-btn {
      flex-shrink: 0;
      position: relative;
      z-index: 11;
      height: 3.9rem;
      width: 3.9rem;
      background: #40384b;
      border-radius: @radius;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;

      transition: @time;

      & svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: white;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        background: #40384b;
        width: 100%;
        height: 100%;
        border-radius: @radius;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.1rem solid #40384b;
        border-bottom: 0;
        opacity: 0;
        border-radius: @radius @radius 0 0;
        background: @color-violet-darklight;

        transition: @time;
      }

      &._open {
        margin-bottom: 2rem;
      }

      &._open::before {
        opacity: 1;
        height: calc(~"100% + 2rem");
      }
    }
  }
}

@media @xs-max {
  .products-list-toolbar {
    &__editor {
      margin-left: -@side-padding-mob;
      padding-left: @side-padding-mob;
      min-width: 0;
      width: 50%;
      flex-grow: 1;
    }

    &__editor-btn {
      margin-left: 1.4rem;
    }
  }
}
.main-footer-menu {
  .font;
  &__title {
    font: inherit;
    color: inherit;
    font-size: 1.4rem;
    font-weight: @regular;
    text-transform: uppercase;
    letter-spacing: .02em;
    margin: 0;
  }

  &__list {
    font: inherit;
    margin-top: 1.3rem;
    font-size: 1.2rem;
    line-height: 1.2em;
    color: fade(white,60%);
  }

  &__item {
    font: inherit;
    color: inherit;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    font: inherit;
    color: inherit;
    transition: color @time;
    &:hover {
      color: @color-blue;
    }
  }
}
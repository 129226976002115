@media @md-max {
  .user-info {
    width: auto;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
  
    &__toggle {
      width: auto;
    }
    
    &__avatar {
      margin-right: 0;
      img {

      }

      &::after {

      }
    }

    &__name-wrap {
      display: none;
    }
    
  }
}

@media @sm-max {
  @avatar-width: 3.6rem;
  
  .user-info {
  
    &__toggle {
      position: relative;
    }
  
    &__toggle-close {
      opacity: 0;
      position: absolute;
      z-index: 8;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      
      transition: @time;
    
      & svg {
        width: 1.8rem;
        height: 1.8rem;
        fill: white
      }
    }
  
    &__avatar img {
      transition: opacity @time;
    }
    
    &__toggle._open &__avatar img {
      opacity: 0.28;
    }
    
    &__toggle._open &__toggle-close {
      opacity: 1;
    }

    &__avatar {
      margin-right: 0;
      width: @avatar-width;
      height: @avatar-width;
      
      img {

      }

      &::after {

      }
      
      .badge {
        right: -0.5rem;
      }
    }
  
    &__avatar-shadow {
      height: 6rem;
    
      &::before {
        width: @avatar-width - .8rem;
        height: @avatar-width - .8rem;
      }
    }

    &__name-wrap {
      display: none;
    }
  }
}

@media @xs-max  {
  .user-info {
    padding: 0;
  }
}
@media @xs-max {
  .header-search {
    
    &__toggle {
      width: 3.3rem;
      background: transparent !important;
      box-shadow: none !important;
      
      &::before {
        display: none !important;
      }
      
      & svg {
        fill: white;
      }
      
      &._open svg {
        fill: @color-blue
      }
    }
  
    &__dropdown {
      width: 100%;
      max-width: none;
      max-height: calc(~"100vh - 100%");
    }
  }
}
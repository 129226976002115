.tags-crumbs {
	width: 100%;
	
	background: #362940;
	display: flex;
	position: relative;
	padding: 3rem;
	padding-right: 30rem;
	
	&__list {
		
		display: flex;
		flex-wrap: wrap;
		margin-right: -3rem;
		margin-top: -1rem;
		position: relative;
		z-index: 3;
	}
	
	&__item {
		display: flex;
		margin-right: 3rem;
		margin-top: 1rem;
	}
	
	&__item-name {
		//padding-top: .5rem;
		align-self: center;
		color: fade(white, 60);
		margin-right: 2rem;
		flex-shrink: 0;
	}
	
	&__img-wrap {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 47.5rem;
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(to right, #362940 0%, fade(#362940, 0) 78%);
			z-index: 2;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
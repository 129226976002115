@media @lg-max {
  .top {
    
    &__inner {
      
      &::before,
      &::after {
      
      }
      
      &::before {
      
      }
      
      &::after {
      
      }
    }
    
    &__content {
    
    }
    
    &__title {
    
    }
    
    &__nav-tabs {
    
    }
    
    &__tab-items {
    
    }
    
    &__tab-item {
    
    }
    
    &__cards {
      max-width: 1100px;
    }
    
    &__cards-container {
    
    }
    
    &__cards-item {
      width: calc(~'(100% - 3.5rem * 4) / 4');
    }
    
    &__more-btn {
    
    }
    
    
  }
}

@media @md-max {
  .top {
    
    &__inner {
      
      &::before,
      &::after {
      
      }
      
      &::before {
      
      }
      
      &::after {
      
      }
    }
    
    &__content {
    
    }
    
    &__title {
    
    }
    
    &__nav-tabs {
    
    }
    
    &__tab-items {
    
    }
    
    &__tab-item {
    
    }
    
    &__cards {
      max-width: 820px;
    }
    
    &__cards-container {
    
    }
    
    &__cards-item {
      width: calc(~'(100% - 3.5rem * 3) / 3');
    }
    
    &__more-btn {
    
    }
    
    
  }
}

@media @sm-max {
  .top {
    
    &__inner {
      padding-bottom: 1.6rem;
      &::before,
      &::after {
      
      }
      
      &::before {
      
      }
      
      &::after {
      
      }
    }
    
    &__content {
    
    }
    
    &__title {
      font-size: 4rem;
    }
    
    &__nav-tabs {
    
    }
    
    &__tab-items {
    
    }
    
    &__tab-item {
    
    }
    
    &__cards {
      max-width: 530px;
    }
    
    &__cards-container {
    
    }
    
    &__cards-item {
      width: calc(~'(100% - 3.5rem * 2) / 2');
    }
    
    &__more-btn {
    
    }
    
    
  }
}

@media @xs-max {
  .top {
    
    &__inner {
      padding-top: .5rem;
      padding-bottom: .5rem;
      &::before,
      &::after {
      
      }
      
      &::before {
      
      }
      
      &::after {
      
      }
    }
    
    &__content {
    
    }
    
    &__title {
      font-size: 2.7rem;
    }
    
    &__nav-tabs {
    
    }
    
    &__tab-items {
    
    }
    
    &__tab-item {
    
    }
    
    &__cards {
      max-width: 250px;
    }
    
    &__cards-container {
    
    }
    
    &__cards-item {
      width: 100%;
    }
    
    &__more-btn {
      margin-top: 4rem;
    }
    
    
  }
}
@media @lg-max {
	
	.main-slider {
		
		
		&__container {
		}
		
		&__wrap {
		
		}
		
		&__slide {
		
			
		}
		
		&__img-wrap {
			
			img {
			
			}
			
			&::after {
			
			}
		}
		
		&__caption {
		
		}
		
		&__caption-content {
		
		}
		
		&__title {
		
		}
		
		&__text {
		
		}
		
		&__nav {
		
		}
		
		&__nav._prev {
		
		}
		
		&__nav._next {
		
		}
		
		&__pagination {
		
		}
		
		&__bottom {
		
			&::before {
				top: 4rem;
			}
		}
		
		&__categories {
		
		}
	}
}

@media @md-max {
	
	.main-slider {
		
		
		&__container {
		}
		
		&__wrap {
		
		}
		
		&__slide {
			padding-bottom: 4rem;
		
		}
		
		&__img-wrap {
			
			img {
			
			}
			
			&::after {
			
			}
		}
		
		&__caption {
			padding-left: 10rem;
			padding-right: 10rem;
		}
		
		&__caption-content {
		
		}
		
		&__title {
		
		}
		
		&__text {
		
		}
		
		&__nav {
		
		}
		
		&__nav._prev {
			left: 3rem;
		}
		
		&__nav._next {
			right: 3rem;
		}
		
		&__pagination {
			bottom: 2.5rem;
		}
		
		&__bottom {
			margin-left: 3rem;
		}
		
		&__categories {
		
		}
	}
}

@media @sm-max {
	
	
	.main-slider {
		
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			padding-bottom: 0;
		}
		
		&__img-wrap {
			
			picture {
				bottom: -5.4rem;
			}
			
			img {
			
			}
			
			&::after {
				bottom: -5.5rem;
			}
		}
		
		&__caption {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		&__caption-content {
		
		}
		
		&__title {
			font-size: 3rem;
		}
		
		&__text {
			font-size: 2rem;
		}
		
		&__nav {
			display: none;
		}
		
		&__nav._prev {
		
		}
		
		&__nav._next {
		
		}
		
		&__pagination {
			bottom: 1.5rem;
		}
		
		&__bottom {
			margin: 0 2rem;
			
			&::before {
				display: none;
			}
		}
		
		&__search {
			margin-left: 0;
			width: calc(~'50% - .1rem');
		}
	}
}

@media @xs-max {
	
	
	.main-slider {
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			flex-direction: column;
		}
		
		&__img-wrap {
			
			&::before {
				padding-top: 0;
				height: 50vw;
			}
			
			picture {
				bottom: 0;
			}
			
			img {
			
			}
			
			&::after {
				display: none;
			}
		}
		
		&__caption {
			position: relative;
			height: auto;
			flex-grow: 0;
			padding: 5rem 2rem 6rem;
		}
		
		&__caption-content {
			padding: 0;
			max-width: none;
		}
		
		&__title {
		
		}
		
		&__text {
		
		}
		
		&__nav {
		
		}
		
		&__nav._prev {
		
		}
		
		&__nav._next {
		
		}
		
		&__pagination {
			bottom: auto;
			top: calc(~'50vw + 1.4rem');
			left: 1.5rem;
			transform: none;
		}
		
		&__bottom {
			margin: 0;
		}
		
		&__search {
			width: 100%;
			min-width: auto;
		}
	}
}
@media @xs-max {
	.seller-bar {
		
		
		&__content {
		
		}
		
		&__col {
		
		}
		
		&__col-inner {
			padding: .9rem 1rem;
		}
		
		&__col--seller {
			width: 65%;
			flex: 1 1 64%;
		}
		
		&__col--info {
			display: none;
		}
		
		&__col--btn {
			width: 35%;
			flex: 1 1 35%;
		}
		
		&__ava-wrap {
			
			img {
			
			}
		}
		
		&__label {
		
		}
		
		&__btn {
			
			
			span {
			
			}
		}
		
		&__open-btn {
		
		}
		
		&__open-btn-decor {
		
		}
		
		&__open-btn-arrow {
		
		}
		
		&__info-list {
			
			li {
			
			
			}
			
			b {
			
			}
			
			&--right {
			
			}
		}
		
		&__name {
			a {
			
			}
		}
		
		&__link {
		
		}
	}
}
@media @md-max {
	.search {
		
		&__input-wrap {
			
			
			&:before {
			
			}
		}
		&__input {
			
			
			&::placeholder {
			
			}
		}
		
		&__submit {
			
			svg {
			
			}
		}
	}
	
	.search._open {
		width: 100%;
		
		.search__search-prev-results {
		
		}
	}
}
@media @sm-max {
	.search {
		
		&__input-wrap {
			//width: calc(~'50% - .1rem');
			//padding-left: 3rem;
			
			&:before {
				top: 0;
				left: 0;
			}
		}
		&__input {
			
			
			&::placeholder {
			
			}
		}
		
		&__submit {
			
			svg {
			
			}
		}
	}
}

@media @xs-max {
	.search {
		
		&__input-wrap {
			padding-left: 2rem;
			padding-right: 2rem;
			
			&:before {
			
			}
		}
		&__input {
			
			
			&::placeholder {
			
			}
		}
		
		&__submit {
			
			svg {
			
			}
		}
	}
}
@media @md-max {
	.header-reg {
		
		
		&__toggle {
			
			
			&::before {
			
			}
			
			span {
				display: none;
			}
			
			&::after {
			
			}
			
			svg {
				display: inline-block;
			}
		}
		
		&__block {
		
		}
		
		&__title {
		
		}
		
		&__buttons {
		
		}
		
		&__btn {
			
			
			span {
			
			}
			
			&--mint {
			
			}
			
			&--orange {
			
			}
			
			&:hover {
				
				
				span {
				
				}
			}
			
			&--mint:hover {
			
			}
			&--orange:hover {
			
			}
		}
		
		&__sign-btn {
			display: inline-flex;
			svg {
			
			}
		}
	}
}
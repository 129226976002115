.menu-list {
	align-self: stretch;
	display: flex;
	font-size: 1.4rem;
	letter-spacing: .02em;
	line-height: @line-height;
	
	&__item {
		display: flex;
		
		.font();

		&:last-child {
			margin-right: 0;
		}
	}
	
	&__link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 1.5rem;

		font: inherit;
		text-transform: uppercase;

		transition: @time;
		border: 0;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: -.2rem;
			background-color: #1f1928;
			box-shadow: 0 -2rem 2.7rem rgba(0, 171, 165, 0.44);
			z-index: 1;
			opacity: 0;
			transition: opacity @time;
		}

		span {
			position: relative;
			z-index: 5;
		}

		&::after {
			z-index: 5;
		}
	}
	
	&__item:hover &__link {

		color: @color-blue;
	}
	
	&__sub-menu {
		display: none;
	}
	
	&__item._open &__sub-menu {
		display: flex;
		animation: fadeIn @time * 1.5;
	}
	
	&__item._open .dropdown-toggle::before {
		opacity: 1;
	}
}
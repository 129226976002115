.page-settings {

  h1{
    margin-bottom: 3.2rem;
  }

  .checkbox{
    a{
      color: #a27ba8;
      &:hover{
        color: #c3a2d3;
      }
    }
  }

  .but-descr{
    a{
      color: #a27ba8;
      &:hover{
        color: #c3a2d3;
      }
    }
  }

  .info-block{
    padding-left: 5px;
    position: relative;
    cursor: pointer;
    svg{
      width: 12px;
      height: 12px;
      fill: #948c9a;
      vertical-align: middle;
      margin-top: -3px;
    }    
    .text{
      display: none;
      position: absolute;
      bottom: 36px;
      left: 12px;
      background-color: #ededf1;
      padding: 18px 20px;
      font-size: 12px;
      color: #261e34;
      min-width: 200px;
      &:after{
        display: block;
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 9px solid #ededf1;
        border-bottom: 8px solid transparent;
      }
    }
    &:hover{
      .elem{
        svg{
          fill: #078c8b;
        }
      }
      .text{
        display: block;
      }
    }
  }

  &__topmenu{
    margin-top: -4rem;
    margin-bottom: 4rem;
    background-color: #362940;    
    ul{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      li{
        font-size: 14.6px;
        font-weight: 400;
        a{
          display: block;
          padding: 18px 20px;
          &.open{
            background-color: #261e34;
            color: #2dd6dc;
          }
        }
      }
    }    
  }

  &__tabs{
    justify-content: flex-start;
    margin-bottom: 3.5rem;
  }

  &__main-wrap {
    display: flex;
    margin-right: -950px;
    padding-top: 13px;
    padding-bottom: 90px;
    position: relative;
  }
  
  &__main {
    display: flex;
    flex-grow: 1;
    width: 72%;
    max-width: 845px;
  }

  &__form {    
    width: 55%;
    max-width: 445px;
    margin-right: 11%;
    .form-group{
      padding-bottom: 26px;
      input:disabled{
        opacity: .18;
      }
    }
    .form-text{
      font-size: 14px;
      color: #bfbcc1;
      padding-bottom: 10px;
      letter-spacing: -0.2px;
      &.disabled{
        opacity: .18;
      }
    }
    .form-input{
      textarea{
        resize: none;
      }
    }

    .inputfile {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        top: 11px;
        right: 10px;
        svg{
          width: 13px;
          height: 13px;
          fill: #362940;
          transition: all .2s;
        }        
    }
    .inputfile:focus + label,
    .inputfile + label:hover {
      svg{
        fill: #c3a2d3;
      }
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }

  }
  &__form-textright{
    display: flex;
    justify-content: space-between;
    .toolbar__item{
      margin-top: -8px;
    }
  }
  &__info {    
    width: 34%;
  }

  &__preview {
    width: 950px;
  }
  .full-logo{
    position: relative;
    .qual{
      display: block;
      position: absolute;
      width: 206px;
      height: 58px;
      left: 0;
      top: 32px;
      object-fit: cover;
    }
  }
  
  &__product {
    display: flex;
    justify-content: space-between;
  }
  
  &__main-info-wrap {
    width: 45.906%;
  }
  
  &__main-info {
    background: #362940;
    padding: 3rem 2.8rem;
  }
  
}

.blockname{
  font-size: 14px;
  color: #bfbcc1;
  padding-bottom: 10px;
}
.logoblock{
  max-width: 230px;
  background-color: #362940;
  background-image: url('../img/jpg/settings-logo-bg.jpg');
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 144px;
  .logoblock-container{
    padding: 25px 30px;
  }
  .logoblock-img{
    width: 106px;
    height: 106px;
    text-align: center;
    border: 2px solid #00c2bb;
    border-radius: 50%;
    padding: 6px;
    box-shadow: 0 0 6px rgba(0,242,233,.8), inset 0 0 6px rgba(0,242,233,.8);
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 50%;
    overflow: hidden;
    img{
      display: inline-block;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
  }
  .seller{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .name{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 9px;
  }
  .status{
    font-size: 12px;
    font-weight: 400;
    color: #2dd6dc;
  }
}
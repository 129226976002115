.social {

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -1.5rem -2rem 0 0;
  }

  &__link {
    display: flex;
    flex-shrink: 0;
    margin: 1.5rem 2rem 0 0;
    padding: .3rem;
    svg {
      width: 2rem;
      height: 2rem;
      fill: white;
      transition: fill @time;
    }
  }

  &__link:hover {
    svg {
      fill: @color-blue;
    }
  }
}
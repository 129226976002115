.products-list {
  width: 100%;
  
  & tbody {
    background: #362940;
  }
  
  & th,
  & td,
  &__number,
  &__name,
  &__categories-list,
  &__link,
  &__expire,
  &__status-text {
    .font();
  }

  & tbody tr:first-child td {
    border-top: 0.1rem solid @color-violet-darklight;
  }

  & th,
  & td {
    padding: 1.6rem 1rem;
    vertical-align: middle;
  }

  & tr:first-child td {
    //min-height: 10.4rem;
  }

  & th {
    padding-top: 0.7rem;
    padding-bottom: 0.8rem;
    border-top: 0.1rem solid #493752;

    color: fade(white, 60);
  }
  
  &__checkbox {
    padding-left: 2rem;
  }
  
  &__number {
    opacity: 0.6;
    margin-bottom: 0.4rem;

    font-size: 1.2rem;
  }
  
  & tr {
  
  }
  
  & td:first-child {
    width: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
    line-height: 0;
  }
  
  & td:nth-child(2) {
    position: relative;
    padding-left: 2rem;
    width: 25%;
    
    & > * {
      position: relative;
      z-index: 2;
    }
  }
  
  & td:nth-child(3),
  & th:nth-child(3),
  & td:nth-child(5),
  & th:nth-child(5) {
    text-align: center;
    width: 6%;
  }
  
  &__bg-img {
    position: absolute !important;
    z-index: 1 !important;
    top: 0;
    left: 0;
    width: 18.2rem;
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left, #362940, fade(#362940, 0));
    }
  }
  
  &__name {
    font-weight: @semibold;
    font-size: 1.6rem;
  }
  
  &__categories-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    
    & * {
      font: inherit;
    }
  }
  
  &__category {
    display: flex;
    align-items: center;
    color: #a27ba8;
    
    &:hover {
      color: #c3a2d3;
    }
    
    & svg {
      width: 0.4rem;
      height: 0.6rem;
      fill: currentColor;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
      
      transition: @time;
    }
  }
  
  &__price {
    display: inline-block;
    position: relative;
    padding-right: 2.2rem;
    
    .font();
    font-weight: @semibold;
    font-size: 2rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.7rem;
      height: 1.7rem;
      background: url(../img/png/coin-ico.png) no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
  
  &__old-price {
    .font();
    font-size: 1.2rem;
    color: fade(white, 60);
  }
  
  & td:last-child {
    position: relative;
    width: 3rem;
  }
  
  &__link {
    display: inline-block;
    margin: 0 0 0.6rem;
    color: @color-blue;
    white-space: nowrap;

    &:hover {
      color: white;
    }
    
    &._expired {
      color: #a27ba8;
    }
    
    &._expired:hover {
      color: #c3a2d3;
    }
  }
  
  &__expire {
    margin: 0 0 0.4rem;
    
    color: fade(white, 60);
    font-size: 1.2rem;
  }
  
  &__status {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  &__status-point {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #818181;
  }
  
  &__status._available &__status-point {
    box-shadow: 0 0 1.7rem 1.5rem fade(#00aba5, 35);
    background: #00aba5;
  }
  
  
  &__status._moderation &__status-point {
    box-shadow: 0 0 1.7rem 1.5rem fade(#f9a400, 35);
    background: #f9a400;
  }
  
  &__status-text {
    margin-left: 1.2rem;
  }
  
  &__more {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-left: 0.1rem solid @color-violet-darklight;
    
    & span {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: white;
      margin: 0.2rem 0;
    }

    &:hover span {
      background-color: @color-blue;
    }
  }
  
  &__product._open &__more {
    background: #3e374a;
    border-color: #3e374a;
  }
  
  // - - - -
  
  &__toolbar-row {
    background: #3e374a;
  }
  
  &__toolbar-row td {
    padding: 0;
  }
  
  &__toolbar-wrap {
    padding-right: 3rem;
    display: none;
  }
}
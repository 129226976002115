.create-buy-order {
	background: @color-violet-darklight;
	
	&__content {
		padding-top: 6.4rem;
	}
	
	&__title {
		margin: 0 0 3rem;
		
		font-size: 2.4rem;
		line-height: 1.3em;
		
		&--main {
			font-size: 5rem;
			line-height: 1.3em;
			font-weight: @semibold;
		}
	}
	
	&__steps-tabs {
		margin-bottom: 5rem;
	}
	
	&__games-wrap {
	
	}
	
	&__games-carousel {
		margin-bottom: 1rem;
	}
	
	&__games-list {
		border-bottom: 0.1rem solid #493752;
		padding-bottom: 2rem;
		margin-bottom: 2.8rem;
	}
	
	&__footer {
		display: flex;
		flex-wrap: wrap;
		margin-top: 5rem;
	}
	
	&__default-btn {
		width: 100%;
		background-color: @color-violet-lighter;
		border-radius: @radius;
		margin-bottom: 4.5rem;
	}
	
	&__footer-btn {
		margin-right: 2rem;
		
		&:last-child {
			margin-right: 0;
		}
		
		&._back {
			background: transparent;
			border: 0.1rem solid #493752;
			border-radius: @radius;
			
			& svg {
				width: 0.4rem;
				height: 0.6rem;
				margin-right: 0.8rem;
			}
			
			&:hover {
				border-color: #5d4d65;
			}
		}
	}
	
	&__group {
		padding-bottom: 2.9rem;
		margin-bottom: 2.6rem;
		border-bottom: 1px solid @color-violet-lightdark-3;
		
		&._last,
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom-width: 0;
		}
		
	}
	
	&__group-text {
		color: white;
		margin-bottom: 2rem;
	}
	
	&__group-content {
		& > *:last-child {
			margin-bottom: 0;
		}
	}
	
	&__group-row {
		margin-bottom: 2.5rem;
	}
	
	&__editor {
		width: 100%;
		margin-bottom: 2rem;
	}
	
	&__group-note {
		margin: 2.4rem 0;
		
		font-size: 1.4rem;
		color: fade(white, 60);
	}
	
	& .section-header + &__group-note {
		margin-top: -0.4rem;
	}
	
	&__price-wrap {
		display: flex;
		.font();
		margin-bottom: 2.5rem;
	}
	
	&__price-input {
		flex-basis: 20.5rem;
	}
	
	&__price-val {
		display: inline-flex;
		align-self: center;
		font: inherit;
		font-size: 1.2rem;
		color: fade(white, 60);
		margin-left: 1rem;
	}
	
	&__inline-alert {
		margin-bottom: 4.6rem;
	}
	
	&__wrap-list {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-left: -2rem;
		margin-top: -2rem;
	}
	
	&__wrap-list-item {
		margin-left: 2rem;
		margin-top: 2rem;
	}
	
	&__inputs {
		display: flex;
		flex-wrap: wrap;
	}
	
	&__input {
		width: 100%;
	}
	
	&__email-row {
		align-items: center;
	}
	
	&__mail {
		display: flex;
		flex-wrap: wrap;
	}
	
	&__mail-input {
		flex-basis: 53rem;
	}
	
	&__mail-text {
		align-self: center;
	}
	
	&__have-account {
		
		& a {
			color: @color-violet-lightest;
		}
	}
	
	&__add-language-wrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	
	&__add-language-label {
		margin: 0 1rem 0 0;
		color: fade(white, 60);
		text-align: right;
	}
	
	&__add-language {
		display: flex;
	}
	
	&__add-language-select {
		width: 14rem;
	}
	
	&__add-language-btn {
		width: 3.9rem;
		height: 3.9rem;
		background: @color-mint;
		border-radius: 0 @radius @radius 0;
		flex-shrink: 0;
		
		& svg {
			width: 1.1rem;
			height: 1.1rem;
			fill: white;
		}
		
		&:hover {
			background-color: @color-mint-light;
		}
	}
	
	&__class-row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: -3rem;
	}
	
	&__class-col {
		width: 46%;
		flex-grow: 1;
		margin-right: 3rem;
		margin-top: 3rem;
		
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}
@sub-menu-game-menu-width-md: 17.612%;

@media @md-max {
  .sub-menu {
  
    &__game-menu {
      width: @sub-menu-game-menu-width-md;
      margin-right: (100% - (@sub-menu-game-menu-width-md * 5)) / 4;
  
      &:nth-child(6n) {
        margin-right: (100% - (@sub-menu-game-menu-width-md * 5)) / 4;
      }
    
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
.icon-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  cursor: pointer;

  transition: @time;

  font-family: @source-sans;
  font-size: @font-size;
  line-height: 1.3em;
  font-weight: @regular;
  color: @color-mint-light;
  text-decoration: none;
  letter-spacing: 0;

  & > * {
    margin-right: 0.8rem;
  }

  & > *:last-child {
    margin-right: 0;
  }

  &__icon {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: @radius;
    background: @color-mint;
    display: flex;
    align-items: center;
    justify-content: center;


    transition: @time;

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 1rem;
      height: 1rem;
      fill: white;
    }
  }

  &:hover {
    color: lighten(@color-mint-light, 10%);
    //text-decoration: underline;
  }

  &:hover &__icon {
    background-color: @color-mint-light;
  }

  & > *:not(&__icon) {
    padding-bottom: 0.2rem;
  }
}


// COLORS

.icon-btn {

  &._orange {
    color: @color-orange-light;
  }

  &._orange &__icon {
    background-color: @color-orange;
  }

  &._orange:hover {
    color: lighten(@color-orange-light, 10%);
  }

  &._orange:hover &__icon {
    background-color: @color-orange-light;
  }

  &._violet {
    color: @color-violet-lightest;
  }

  &._violet:hover {
    color: @color-violet-lightest2;
  }
}


// OUTLINE

.icon-btn {

  &._outline &__icon {
    background: transparent;
    border: 0.1rem solid @color-mint;
  }

  &._outline:hover &__icon {
    border-color: @color-mint-light;
  }

  // - -

  &._outline._violet &__icon {
    border-color: #5d4d65;
  }

  &._outline._violet:hover &__icon {
    border-color: lighten(#5d4d65, 8%);
  }
}

// LARGE

.icon-btn {

  &._large > * {
    margin-right: 0.8rem;
  }

  &._large > *:last-child {
    margin-right: 0;
  }

  &._large &__icon {
    width: 3.1rem;
    height: 3.1rem;
  }
}

// LARGE

.icon-btn {

  //&._large > * {
  //  margin-right: 0.8rem;
  //}
  //
  //&._large > *:last-child {
  //  margin-right: 0;
  //}

  &._small &__icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.slider-navs {
	display: flex;
	.no-selection();
	
	&__btn {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: @color-violet-lighter;
		border: none;
		padding: 1rem 1.2rem;
		height: 5rem;
		cursor: pointer;
		font-size: 0;
		line-height: 0;
		
		
		transition: opacity @time;
		
		svg {
			position: relative;
			width: 1.2rem;
			height: 1.9rem;
			fill: white;
			
			transition: @time;
		}
		
		&._prev {
			padding-left: 3rem;
			svg {
				transform: scale(-1,1);
			}
		}
		
		
		&._next {
			padding-right: 3rem;
			svg {
			
			}
		}
		
		&:hover {
			svg {
				fill: @color-blue;
			}
		}
		
		&._disabled {
			svg {
				opacity: .1;
			}
			pointer-events: none;
		}
	}
}
.customers-price {

  &__block {
    padding-top: 0.8rem;
    margin-bottom: 3rem;
    border-top: 0.1rem solid #3e374a;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__sub-title {
    margin-bottom: 0.8rem;
    
    .font();
    text-transform: none;
    color: @color-blue;
  }
  
  &__table {
    width: 100%;
    border-spacing: 0 0.1rem;
    margin-bottom: -0.1rem;
    
    & td {
      padding: 0.9rem;
      background: #362940;
    }
    
    & td:first-child {
      padding-left: 2.5rem;
    }
    
    & td:last-child {
      text-align: right;
    }
  }
  
  &__input {
    display: block;
    width: 9rem;
    margin-left: auto;
    
    & .input__input {
      padding-left: 1.5rem;
    }
  }
}
.bottom-panel {
	width: 100%;
	
	&__inner {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
		background-color: @color-violet-dark;
		padding: 1rem 3rem;

		position: relative;
	}
	
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	&__col {
		display: flex;
		align-items: center;
		margin-right: -2rem;
	}
	
	&__item {
		margin-right: 2rem;
		
		&._preview:hover {
			color: white;
		}
		
		&._preview:hover::before {
			background-color: #493752;
		}
		
		&._preview:hover svg {
			fill: white
		}
	}
	
	&._sticky {
		.bottom-panel__inner {
			position: fixed;
			bottom: 0;
			z-index: 179;
		}
	}
}
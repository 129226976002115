.main-footer {
  width: 100%;
  background-color: @color-violet-dark;
  .font();
  position: relative;
  z-index: 10;

  &__line {
    width: 100%;
    height: .2rem;
    background: linear-gradient(to right, #00a7a9, #90159b);
  }

  &__inner {
    font: inherit;
    color: inherit;
    padding: 5.5rem 10rem;
    display: flex;
    justify-content: space-between;
  }

  &__social {
    margin-bottom: 2.5rem;
  }

  &__col {
    font: inherit;
    color: inherit;
    margin: 0 2rem;
  }

  &__col--info {
    font: inherit;
    color: inherit;
    width: 20rem;
  }

  &__col--dropdowns {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__dropdown {
    margin-bottom: 2rem;
    width: auto;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media @xs-max {
  .select-game-carousel {
    
    &__content {
      padding-top: 2.5rem;
    }
    
    &__carousel-outer {
      margin-left: -@side-padding-mob;
      margin-right: -@side-padding-mob;
    }
    
    &__carousel::before,
    &__carousel::after {
      display: none;
    }
    
    &__navs {
      right: 0;
    }
  }
}
.seller {
	.font;
	position: relative;
	flex-direction: column;
	display: flex;
	
	@media @md {
		display: flex!important;
	}
	
	&__modal-close {
		display: none;
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
	
	&__content {
		font: inherit;
		color: inherit;
		background-color: @color-violet-lightdark;
		width: 100%;
	}
	
	&__top {
		font: inherit;
		color: inherit;
	}
	
	&__bottom {
		font: inherit;
		color: inherit;
	}
	
	&__row {
		font: inherit;
		color: inherit;
		border-bottom: 1px solid @color-violet-darklight;
		display: flex;
		flex-direction: column;
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	&__row-inner {
		font: inherit;
		color: inherit;
		padding: 1.8rem 3rem;
		display: flex;
		flex-direction: column;
	}
	
	&__row-inner--head {
		padding: 3rem 3rem 2rem;
	}
	
	&__row-inner--block {
		padding: 1.4rem 3rem 3.5rem;
	}
	
	&__ava-wrap {
		width: 11rem;
		height: 11rem;
		border-radius: 100%;
		border: .2rem solid @color-blue;
		padding: .7rem;
		box-shadow: 0 0 .4rem .1rem @color-blue, inset 0 0 .4rem .1rem @color-blue;
		align-self: center;
		margin-bottom: 1rem;
		flex-shrink: 0;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 100%;
		}
	}
	
	&__label {
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
		margin-bottom: 0.7rem;
	}
	
	&__title {
		.h3;
		letter-spacing: .02em;
		color: white;
		font-size: 2rem;
		text-transform: uppercase;
		margin-bottom: .8rem;
		display: flex;
		align-items: center;
		
		a {
			font: inherit;
			color: inherit;
			transition: color @time;
			
			&:hover {
				color: @color-blue;
			}
		}
		
		&--name {
			justify-content: flex-start;
			align-items: center;
		}
		
		&--block {
			margin-bottom: 1.5rem;
		}
	}
	
	&__title-ico {
		display: inline-block;
		width: 3.7rem;
		height: 3.7rem;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 1rem;
		flex-shrink: 0;
	}
	
	&__status {
		font: inherit;
		font-size: 1.2rem;
		color: @color-blue;
	}
	
	&__info-list {
		font: inherit;
		color: fade(white, 60%);
		font-size: 1.2rem;
		
		li {
			font: inherit;
			color: inherit;
			margin-bottom: 1rem;
			
			&>* {
				margin-right: 1rem;
				&:last-child {
					margin-right: 0;
				}
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		b {
			font: inherit;
			color: white;
		}
	}
	
	&__link {
		font: inherit;
		color: @color-violet-lightest;
		transition: color @time;
		
		&:hover {
			color: @color-violet-lightest2;
		}
	}
	
	&__contact-list {
		font: inherit;
		color: inherit;
		font-size: 1.6rem;
		
		li {
			font: inherit;
			color: inherit;
			margin-bottom: .5rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__tel {
		color: inherit;
		transition: color @time;
		
		&:hover {
			color: @color-blue;
		}
	}
	
	&__btn {
		margin: .8rem 1.5rem 1.8rem;
	}
	
	&__text {
		color: fade(white,60%);
		ul,ol {
			padding-left: 0;
		}
		
		li {
			margin-bottom: 1.4em;
		}
	}
	
	&__bottom-btn {
		width: 100%;
		&::before {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
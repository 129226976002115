@media @sm-max {
  .mob-modal {
    position: absolute;
    left: 0;
    top: 100%;
    height: calc(~"100vh - 100%");
    width: 100%;
    background: #1f1928;
    display: flex;
    flex-direction: column;
    
    & > * {
      flex-shrink: 0;
    }
    
    &__head {
      display: flex;
    }
    
    &__content {
      height: 50%;
      flex-grow: 1;
      .g-scroll-y;
      display: flex;
      flex-direction: column;
    }
    
    &__head-btn {
      position: relative;
      width: 50%;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2d233c;
      min-height: 5rem;
      padding: 0.8rem @side-padding-mob;
      
      .font();
      text-transform: uppercase;
      
      &:nth-child(even) {
        background: #56245e;
      }
      
      &:hover {
        color: white;
      }
      
      & svg {
        position: relative;
        width: 1.7rem;
        height: 1.7rem;
        fill: currentColor;
        margin-right: 0.8rem;
      }
      
      & .badge {
        margin-left: 0.8rem;
      }
    }
  
    &__head-btn._open {
      background-color: @color-mint;
    }
    
    &__notice-btn {
      position: relative;
      display: flex;
      margin-right: 1.2rem;
      
      & svg {
        width: 1.4rem;
        height: 1.7rem;
        margin: 0;
      }
    }
  }
}
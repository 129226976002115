@media @xs-max {
  .steps-tabs {
    flex-direction: column;
    
    &__tab._active {
    
    }
    
    &__tab {
      width: 100%;
      margin: 0 0 2rem;
      
      &:not(._active) {
        padding-top: 0.6rem;
        padding-bottom: 0.7rem;
      }
    }
    
    &__tab:not(._active) &__list {
      display: none !important;
    }
    
    &__tab:not(._active) &__title {
      font-size: 1.4rem;
      text-transform: none;
      font-family: @source-sans;
      font-weight: @regular;
    }
  
    &__tab._disabled &__title {
      color: white;
    }
    
    &__dropdown,
    &__closed-ico {
      display: none !important;
    }
  
    &__list li {
      display: none;
    }
  
    &__list li:first-child {
      display: block;
    }
  }
}
.search-main {
  display: flex;
  background: #40384b;
  padding: 0 0 0 2rem;
  
  & > * {
    flex-shrink: 0;
  }
  
  &__btn {
    padding: 0;
    width: 3.4rem;
    border: 0;
    margin-right: 0.6rem;
    
    font-size: 0;
    transition: @time;
    
    svg {
      position: relative;
      top: -0.1rem;
      width: 1.2rem;
      height: 1.3rem;
      fill: @color-blue;
      
      transition: @time;
    }
    
    &:hover svg {
      fill: white
    }
  }
  
  &__input {
    width: 10rem;
    flex: 1 1 20%;
    background: transparent;
    padding: 1.7rem 0 1.8rem;
    
    .font();
    color: white;
    font-size: 1.4rem;
  }
  
  &__select-label {
    opacity: 0.6;
    align-self: center;
    margin: 0 1.8rem 0.4rem 0;
    
    .font();
  }
  
  &__select {
    width: 24.5%;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 2.7rem;
      transform: translateY(-50%);
      background: #554e5f;
    }
  }
  
  &__select .selectbox-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    
    &::after {
      display: inline-block;
      vertical-align: 0.18em;
      content: "";
      border-top: .5rem solid white;
      border-left: .4rem solid @transparent;
      border-right: .4rem solid @transparent;
      transition: border-top-color @time, transform @time;
    }
  }
  
  &__select.opened .selectbox-trigger::after {
    border-top-color: @color-blue;
  }
  
  &__select .selectbox-select {
    padding: 0 1.8rem;
    height: auto;
    background: transparent;
  }
  
  &__select .selectbox-select-text {
    position: relative;
    padding: 1.7rem 0 1.8rem;
  
    .font();
    color: @color-blue;
    text-overflow: inherit;
    
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 6rem;
      height: 100%;
      background: linear-gradient(to left, #40384b, fade(#40384b, 0));
      pointer-events: none;
    }
  }
  
  &__select .selectbox-dropdown {
    left: 0;
    top: calc(~"100% - 0.5rem") !important;
    background: #40384b;
  }
  
  &__select .selectbox-dropdown li {
    padding: 0 1.8rem;
    margin-bottom: 1.3rem;
    
    .font();
    color: @color-blue;
  }
  
  &__select .selectbox-dropdown li.sel:first-child {
    display: none;
  }
}
.reviews-slider {
	position: relative;
	display: flex;
	flex-direction: column;
	
	&__navs-wrap {
		position: absolute;
		top: -5rem;
		right: -3rem;
		display: flex;
		align-items: center;
		background-color: @color-violet-lighter;
		padding-left: 3rem;
	}
	&::before {
		content: '';
		position: absolute;
		width: 3rem;
		right: -3rem;
		top: 0;
		bottom: 50%;
		background-color: @color-violet-lighter;
		z-index: 7;
	}
	&__navs-link {
		font-size: 1.2rem;
	}
	
	&__container {
		width: 100%;
		overflow: hidden;
	}
	
	&__wrap {
		width: 100%;
		display: flex;
	}
	
	&__slide {
		flex-shrink: 0;
		width: 100%;
		margin-right: 1rem;
		
		&:last-child {
			margin-right: 0;
		}
	}
}
.helps-side {
  position: relative;
  background: #20192c;
  flex-grow: 1;
  width: 100%;
  
  &::before {
    content: '?';
    position: absolute;
    top: -14rem;
    right: -12.5rem;
    font-family: 'Arial Black', 'Arial', 'Gadget', sans-serif;
    font-weight: 900;
    font-size: 55rem;
    line-height: 1.2em;
    color: #2d243b;
  }
  
  &__list {
    position: relative;
    //position: fixed;
    //top: @header-height;
    //right: 6.4rem;
    height: calc(~"100vh - 6rem -" @header-height);
    //width: 49rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
    pointer-events: none;
    
    transition: @time;
    
    &._show {
      opacity: 1;
      z-index: 5;
      pointer-events: auto;
    }
  }
  
  &__item-main-wrap {
    padding-left: 7rem;
    height: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
  }
  
  &__item-main {
    display: flex;
  }
  
  &__item-text {
    flex-grow: 1;
    width: 40%;
    max-width: 29rem;
  }
  
  &__item-title {
    margin: 0 0 2.2rem;
    padding-right: 7rem;
    
    font-size: 2.7rem;
  }
  
  &__item-img-wrap {
    width: 25rem;
    flex-shrink: 0;
    margin-left: 2rem;

    &._full {
      width: 100%;
    }
  }
  
  &__item-img {
    width: 100%;
  }
  
  &__item-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    margin-top: 3rem;
    flex-shrink: 0;
  }
  
  &__item-link {
    font-size: 1.2rem;
    color: #a27ba8;
    
    & svg {
      width: 0.4rem;
      height: 0.6rem;
      margin-top: 0.2rem;
      margin-left: 0.8rem;
    }
    
    &._prev svg {
      transform: rotate(180deg);
      margin-left: 0;
      margin-right: 0.8rem;
    }
  }
}
.nav-buttons {
  .font();
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: .02em;
  line-height: @line-height;
  text-transform: uppercase;
  padding: 0 3rem;
  position: relative;

  /*
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: white;
    opacity: .07;
  }


  &::after {
    left: auto;
    right: 0;
  }
  */

  &__btn {
    font: inherit;
    transition: color @time;
    position: relative;
    display: flex;
    margin: 0 3rem 0 0;
    align-items: center;
    justify-content: center;

    svg {
      width: .9rem;
      height: 1.3rem;
      fill: currentColor;
      margin-right: 1rem;
    }

    &:last-child {
      margin-right: 0
    }
  }

  &__btn:hover {
    color: @color-blue;
  }
}
.side-slider {
	.font;
	position: relative;
	padding-top: 5rem;
	&__inner {
		font: inherit;
		color: inherit;
		width: 100%;
		padding: 2.5rem 3rem;
		background-color: @color-violet-lightdark;
	}
	
	&__container {
		font: inherit;
		color: inherit;
		width: 100%;
		overflow: hidden;
		
	}
	
	&__title {
		.h3;
		margin-bottom: 2.5rem;
	}
	
	&__wrap {
		font: inherit;
		color: inherit;
		width: 100%;
		display: flex;
	}
	
	&__slide {
		font: inherit;
		color: inherit;
		flex-shrink: 0;
		width: 100%;
		margin-right: 1rem;
		
		&:last-child {
			margin-right: 0;
		}
		
		&:hover {
			transform: none;
		}
	}
	
	&__navs-wrap {
		background-color: @color-violet-lighter;
		position: absolute;
		right: -3rem;
		width: 100%;
		top: 0;
		display: flex;
		align-items: center;
	}
	
	&__navs {
		.slider-navs__btn._next {
			padding-right: 1.2rem;
		}
		margin-right: .6rem;
	}
	
	&__navs-link {
		font-size: 1.2rem;
	}
	
	&::before {
		content: '';
		position: absolute;
		width: 3rem;
		right: -3rem;
		top: 5rem;
		bottom: 13.5rem;
		background-color: @color-violet-lighter;
		z-index: 7;
	}
}
.info-list {
  
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__item-name {
    flex-shrink: 0;
    width: 9rem;
    margin-right: 1rem;
    
    .font();
    font-size: 1.6rem;
    opacity: 0.6;
  }
  
  &__item-content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  
  //&__item-value {
  //  margin: 0.5rem 1rem 0.5rem 0;
  //
  //  &:last-child {
  //    margin-right: 0;
  //  }
  //}
}
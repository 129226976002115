@media @xs-max {
	.reviews-slider {


		&__navs-wrap {
			position: relative;
			top: auto;
			right: auto;
			align-self: flex-end;
		}
		&::before {
			display: none;
		}
		&__navs-link {

		}

		&__container {

		}

		&__wrap {

		}

		&__slide {


			&:last-child {

			}
		}
	}
}
.status-bar {
	.font();
	&__title {
		text-align: center;
		.h4();
		margin-bottom: 2.5rem;
	}
	
	&__panel {
		display: flex;
		font: inherit;
		position: relative;
		margin-bottom: 5rem;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&._top {
			padding-top: 2.2rem;
		}
	}
	
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0;
		font: inherit;
		height: 2.5rem;
		background-color: @color-violet-lighter;
		transition: width @time;
		position: relative;

		&:first-child {
			border-top-left-radius: @radius;
			border-bottom-left-radius: @radius;
			justify-content: flex-start;
		}
		
		&:last-child {
			justify-content: flex-end;
			text-align: right;
			border-top-right-radius: @radius;
			border-bottom-right-radius: @radius;
		}

		&._orange {
			background: linear-gradient(to left, #e99717, #db5121);
		}

		&._blue {
			background: linear-gradient(to left, #00aba5, #2dd6dc);
			box-shadow: 0 .9rem 1.8rem 0 fade(#00aba5, 48%);
		}

		&._auto {
			flex: 1 1 0;
		}
	}

	&__item-text {
		display: inline-block;
		font: inherit;
		white-space: nowrap;
		padding: 0 1rem .1rem;

		.highlighted {
			color: @color-blue;
		}
	}

	&__item-text._bottom {
		padding: 0;
		position: absolute;
		top: 100%;
		margin-top: 1rem;
	}

	&__item-text._top {
		position: absolute;
		bottom: 100%;
		margin-bottom: .4rem;
		&._left {
			left: -1rem;
		}
		
		&._right {
			right: -1rem;
		}
	}
}

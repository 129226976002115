.game-box-custom {
	margin-top: 7rem;
	margin-bottom: 8rem;
	
	& .game-box-list {
		grid-template-columns: auto auto auto;
		margin-bottom: -6rem;
		//justify-content: normal;
		
		//& > li:nth-child(3n - 1) {
		//	justify-self: center;
		//}
		//
		//& > li:nth-child(3n) {
		//	justify-self: end;
		//}
		
		//& > li:first-child {
		//	justify-self: center;
		//}
	}
	
	&__title {
		.h2;
		text-align: center;
		margin-bottom: 5rem;
	}
	
	.game-box-list>li {
		width: 29vw;
		height: 29vw;
		margin-bottom: 6rem;
	}
	
	.game-box-item-box {
		width: 100%;
		height: 100%;
	}
	
	.game-box-item {
		transform-origin: 50% 50% -14vw;
	}
	
}


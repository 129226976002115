@media @lg-max {
	.news {
		
		&__header {
		
		}
		
		&__title {
		
		}
		
		&__tab-items {
		
		}
		
		&__tab-item {
			
			
			&._open {
			
			}
		}
	}
}

@media @md-max {
	.news {
		
		&__header {
		
		}
		
		&__title {
		
		}
		
		&__tab-items {
		
		}
		
		&__tab-item {
			
			
			&._open {
			
			}
		}
	}
}

@media @sm-max {
	.news {
		
		&__header {
		
		}
		
		&__title {
			font-size: 4rem;
		}
		
		&__tab-items {
		
		}
		
		&__tab-item {
			
			
			&._open {
			
			}
		}
	}
}

@media @xs-max {
	.news {
		
		&__header {
			margin-bottom: 4rem;
		}
		
		&__title {
			font-size: 2.7rem;
			margin-bottom: 2.5rem;
		}
		
		& > .news__title {
			margin-bottom: 2.5rem;
		}
		
		&__tab-items {
		
		}
		
		&__tab-item {
			
			
			&._open {
			
			}
		}
	}
}
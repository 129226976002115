.new-select .selectbox-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  
  &::after {
    display: inline-block;
    vertical-align: 0.18em;
    content: "";
    border-top: .5rem solid @color-violet-darklight;
    border-left: .4rem solid @transparent;
    border-right: .4rem solid @transparent;
    transition: border-top-color @time, transform @time;
  }
}

.new-select.selectbox.opened .selectbox-trigger::after {
  border-top-color: @color-blue;
}

.new-select .selectbox-select {
  padding: 0 1rem;
  height: auto;
  background: white;
}

.new-select .selectbox-select-text {
  position: relative;
  padding: 1.1rem 0 1.2rem;
  
  .font();
  color: @color-violet-darklight;
}

.new-select .selectbox-dropdown {
  left: 0;
  background: white;
}

.new-select .selectbox-dropdown li {
  padding: 0 1rem;
  margin-bottom: 1.3rem;
  
  .font();
  color: @color-violet-darklight;
}
.flag-icon {
  display: inline-block;
  width: 2.1rem;
  height: 1.2rem;
  vertical-align: middle;
  position: relative;
  top: -.2rem;
  margin-right: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 50rem 20rem #332442;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  padding-top: @header-height;
  position: relative;
  
  &__main {
    background: @color-violet-darklight;
    flex-grow: 1;
    min-height: auto;
    padding-top: 4rem;
  }
  
  &__pagination {
    margin-bottom: 1.8rem;
  }
  
  &__title {
    padding: 0;
    margin-bottom: 1.4rem;
    margin-left: -0.3rem;
    
    font-size: 5rem;
    line-height: @line-height;
    font-family: @oswald;
    font-weight: @semibold;
  }
}

//--custom-shop
.page._custom-shop {
  
  @media @md {
    padding-top: @header-height-custom;
    .main-header__inner {
      height: 6rem;
    }
  }
  
  .page__main {
    //background-image: url(../img/png/highlight-blue-left.png),url(../img/png/highlight-blue-right.png);
    //background-repeat: no-repeat;
    //background-position: center top, center bottom;
  }
  
  .user-info__avatar {
    width: 3.3rem;
    height: 3.3rem;
    padding: .2rem;
    &::after {
      border: .1rem solid @color-blue;
      box-shadow: 0 0 .2rem .1rem fade(@color-blue, 60%), inset 0 0 .2rem .1rem fade(@color-blue, 60%);
    }
  }
  
  .user-info__avatar-shadow {
    display: none;
  }
  
  .user-info__name-wrap {
    top: .4rem;
    width: calc(~'100% - 4rem');
  }
}
.badge {
  font-family: @source-sans;
  font-weight: @regular;
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translateY(-1.9rem);
  z-index: 10;
  background-color: grey;
  color: white;

  display: block;
  padding: .3rem;
  min-width: 1.7rem;
  border-radius: 1.8rem;
  
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 1.8rem;
    
  }
  
  &._relative {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    transform: none;
  }
}

.badge._blue {
  background-color: @color-blue;
  box-shadow: 0 0 .4rem @color-blue;
  &::before {
    box-shadow: 0 0 3rem @color-blue;
    pointer-events: none;
  }
}

.badge._pink {
  background-color: @color-pink;
  box-shadow: 0 0 .4rem @color-pink;
  &::before {
    box-shadow: 0 0 3rem @color-pink;
  }
}
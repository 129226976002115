@media @sm-max {
  .product-toolbar {
    padding-left: 3rem;
    
    &__star-rating {
      margin-right: 4.2%;
    }
    
    &__edit-wrap {
    
    }
    
    &__edit-btn {
      font-size: 0;
      padding: 0.6rem;
      margin-right: 1.8rem;
      
      &:last-child {
        margin-right: 0;
      }
      
      & svg {
        margin: 0;
      }
    }
  }
}

@media @xs-max {
  .product-toolbar {
    flex-direction: column;
    align-items: stretch;
    
    &__star-rating {
      margin: 0 0 3rem;
      width: 100%;
    }
    
    &__prices {
      width: 100%;
      margin-bottom: 1.2rem;
    }
    
    &__links-wrap {
      margin-bottom: 2.6rem;
    }
    
    &__param {
      margin: 0 0 1.3rem;
      
      & ._not-mob {
        display: inline-block !important;
      }
    }
    
    &__edit-wrap {
      width: calc(~"100% + " @side-padding-mob * 2);
      min-width: 100%;
      margin: 2rem -@side-padding-mob 0;
      background: #1b1524;
      align-self: stretch;
      padding: 1.1rem @side-padding-mob;
    }
  
    &__edit-btn {
      font-size: 1.4rem;
      
      & svg {
        margin-right: 0.8rem;
      }
    }
  }
}
@media @xs-max {
  .set-price {
    
    &__content {
      flex-direction: column;
      padding: 2rem;
    }
    
    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
  }
}
@media @xs-max {
	.order-history {
		&__table {
			tr {
				display: block;
				width: 100%;
				border: 1px solid @color-violet-lightdark-3;
				border-top-width: 0;
			}
			
			td {
				display: block;
				width: 100%;
				border: 0;
				padding-top: 0;
				
				&:first-child {
					padding-top: 1.2rem;
					width: 100%;
				}
				
				&:last-child {
					width: 100%;
					text-align: left;
					padding-bottom: 1.5rem;
				}
			}
			
			tr:first-child {
				border-top-width: 1px;
				border-top-left-radius: @radius;
				border-top-right-radius: @radius;
				
				td:first-child {
					border-radius: 0;
				}
				
				td:last-child {
					border-radius: 0;
				}
			}
			
			tr:last-child {
				border-bottom-left-radius: @radius;
				border-bottom-right-radius: @radius;
				td:first-child {
					border-radius: 0;
				}
				
				td:last-child {
					border-radius: 0;
				}
			}
		}
		
		&__cell-text {
			width: 100%;
			flex: 0 1 auto;
			white-space: normal;
			text-overflow: clip;
			overflow: visible;
		}
	}
}
@media @sm-max {
	.fixed-navs {
		top: @header-height-mob;
		&__inner {
			margin-right: 0;
		}
		
		&__tabs {
		
		}
	}
}
.extras-block {
	&__content {
		background-color: #362940;
		padding: 2.8rem 3rem 2.6rem;
	}
	
	&__text {
		margin-bottom: 2rem;
	}
	
	&__price-wrap {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
		.font();
	}
	
	&__price {
		display: inline-block;
		position: relative;
		padding-right: 2.2rem;
		
		font: inherit;
		color: white;
		font-weight: @semibold;
		font-size: 2rem;
		margin-right: 1rem;
		
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 1.7rem;
			height: 1.7rem;
			background: url(../img/png/coin-ico.png) no-repeat center;
			background-size: contain;
			transform: translateY(-50%);
		}
	}
	
	&__sub-price {
		font: inherit;
		font-size: 1.2rem;
		color: fade(white, 60);
	}
	
	&__btn {
		width: 100%;
		
		&::before {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
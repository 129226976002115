@media @sm-max {
	.seller {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 191;
		flex-direction: column;
		display: none;
		& > * {
			flex-shrink: 0;
		}
		
		&__modal-close {
			display: block;
			svg {
			
			}
		}
		
		&__content {
			height: 50%;
			flex-grow: 1;
			overflow-x: hidden;
			overflow-y: auto;
		}
		
		&__top {
			display: flex;
			border-bottom: 1px solid @color-violet-darklight;
		}
		
		&__bottom {
		
		}
		
		&__row {
			border-bottom: 0;
			
			
			&--mob-col {
				width: 20%;
				flex: 1 1 20%;
				border-left: 1px solid @color-violet-darklight;
				
				&:first-child {
					border-left: 0;
				}
			}
		}
		
		&__row-inner {
		
		}
		
		&__row-inner--head {
		
		}
		
		&__row-inner--block {
			padding: 2.5rem 3rem 3.5rem;
		}
		
		&__ava-wrap {
		
		}
		
		&__label {
		
		}
		
		&__title {
			
			
			a {
			
			}
			
			&--name {
			
			}
		}
		
		&__title-ico {
		
		}
		
		&__status {
		
		}
		
		&__info-list {
			
			
			li {
				
				
				&>* {
				
				
				}
				
			}
			
			b {
			
			}
		}
		
		&__link {
		
		
		
		}
		
		&__contact-list {
			
			
			li {
			
			}
		}
		
		&__tel {
		
		}
		
		&__btn {
			margin: .8rem 3rem 1.8rem;
		}
		
		
		&__bottom-btn {
			&::before {
				border-radius: 0;
			}
		}
	}
}

@media @sm-max and @sm {
	.seller {
		&__title--block {
			justify-content: center;
			margin-bottom: 2rem;
		}
		
		&__text {
			
			ul,ol {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -3rem;
			}
			
			li {
				width: calc(~'(100% - (3rem * 6)) / 3');
				
				margin: 0 3rem 3rem;
				
				&:last-child {
					margin-bottom: 3rem;
				}
			}
		}
	}
}

@media @xs-max {
	.seller {
		
		& > * {
		
		}
		
		&__modal-close {
			
			svg {
			
			}
		}
		
		&__content {
		
		}
		
		&__top {
			flex-direction: column;
		}
		
		&__bottom {
		
		}
		
		&__row {
			
			
			
			&--mob-col {
				width: 100%;
				flex: auto;
				border-left: 0;
				border-bottom: 1px solid @color-violet-darklight;
				
				&:first-child {
				
				}
			}
		}
		
		&__row--info {
			flex-direction: row;
			
			.seller__row {
				width: 50%;
				border-left: 1px solid @color-violet-darklight;
				
				&:first-child {
					border-left: 0;
				}
			}
		}
		
		&__row-inner {
			padding: 2.5rem 2rem;
		}
		
		&__row-inner--head {
			padding: 2.5rem 3rem;
		}
		
		&__row-inner--block {
		
		}
		
		&__ava-wrap {
		
		}
		
		&__label {
		
		}
		
		&__title {
			
			
			a {
			
			}
			
			&--name {
			
			}
			
			&--block {
			
			}
		}
		
		&__title-ico {
		
		}
		
		&__status {
		
		}
		
		&__info-list {
			
			
			li {
				
				
				&>* {
				
				
				}
				
			}
			
			b {
			
			}
		}
		
		&__link {
		
		
		
		}
		
		&__contact-list {
			
			
			li {
			
			}
		}
		
		&__tel {
		
		}
		
		&__btn {
			margin: -1rem 2rem 3rem;
		}
		
		&__text {
			
			ul,ol {
			
			}
			
			li {
				
				
				&:last-child {
				
				}
			}
		}
		
		&__bottom-btn {
			&::before {
			
			}
		}
	}
}
@media @md-max {
	.custom-review-slider {
		
		&__title {
			font-size: 4rem;
		}
		
		
		&__inner {
			
			&::before {
			
			}
		}
		
		&__navs {
		
		}
		
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			width: calc(~'(100% - 3.5rem) / 2');
		}
	}
}

@media @xs-max {
	.custom-review-slider {
		
		&__title {
			font-size: 2.7rem;
			text-align: left;
		}
		
		
		&__inner {
			margin-top: -3.2rem;
			&::before {
				display: none;
			}
		}
		
		&__navs {
			left: auto;
			align-self: flex-end;
		}
		
		
		&__container {
		
		}
		
		&__wrap {
		
		}
		
		&__slide {
			width: 100%;
		}
	}
}
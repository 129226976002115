.review-article {
	width: 100%;
	background-color: @color-violet-lightdark;
	padding: 3rem 3rem;
	.font;

	
	&__head {
		font: inherit;
		color: inherit;
		margin-bottom: 2.5rem;
		position: relative;
		padding-left: 11rem;
		padding-top: 0.1rem;
	}

	&__author-ava {
		width: 9rem;
		height: 9rem;
		border-radius: 100%;
		overflow: hidden;
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	&__time {
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
		opacity: .6;
		display: block;
		margin-bottom: 1.3rem;
		padding-right: 9rem;
		
		span {
			margin-right: .7rem;
		}
	}
	
	&__name {
		.h3;
		margin-bottom: .2rem;
	}
	
	&__user-rating {
		font: inherit;
		color: inherit;
		font-size: 1.2rem;
	}
	
	&__star-rating {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
	}

	&__text {
		padding-left: 11rem;
		line-height: 1.2;
	}
}
.fixed-navs {
	position: fixed;
	z-index: 179;
	top: @header-height;
	right: 0;
	left: 0;
	
	width: 100%;
	max-width: 1920px;
	background-color: @color-violet-dark;
	display: flex;
	
	transform: translateY(-200%);
	transition: transform @time-modal;
	&__inner {
		margin-right: 7rem + 23rem;
		flex-grow: 1;
		width: 50%;
	}
	
	&__tabs {
	
	}
	
	&._open {
		transform: translateY(0);
	}
	
	&__anchors-tabs {
		width: 100%;
	}
	
	&__anchors-tab {
		width: 100%;
		display: none;
		padding-top: 1rem;
		padding-bottom: .5rem;
		
		&._open {
			display: block;
			animation: fadeIn @time-modal;
		}
	}
}
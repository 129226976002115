@media @xs-max {
  .combinations-list {
    
    & thead {
      display: none;
    }
    
    &__combination {
      display: block;
      margin-top: 0.1rem;
      
      & tr,
      & td {
        display: block;
      }
      
      & tr:first-child {
        display: flex;
        flex-wrap: wrap;
      }
      
      & tr:first-child td {
        width: 100%;
        padding: 0;
        border: none !important;
      }
  
      & tr:first-child td:first-child {
        margin-bottom: 1.8rem;
        padding: 0 0.2rem;
      }
      
      & tr:first-child td:nth-child(n+2) {
        width: 33.33%;
       
        padding-right: 0.4rem;
        padding-left: 0.4rem;
      }
  
      & tr:first-child {
        padding: 2rem 1.8rem 0;
      }
    }
  
    &__input {
      width: 100%;
    }
    
    &__input-wrap {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.2rem;
    }
    
    &__input-wrap &__price-note {
      order: 5;
      margin: 0;
    }
    
    &__input-wrap &__input {
      order: 4;
      margin-bottom: 0.2rem;
    }
    
    &__mob-col-title {
      margin: 0 0 0.8rem;
      
      font-size: 1.4rem;
      color: fade(white, 60);
    }
  
    &__combination tr:first-child td._more {
      width: 100%;
      padding: 0.4rem 0 0;
    }
  
    &__more {
      position: static;
      border: 0;
      width: 4.4rem;
      height: 4rem;
      transform: translateX(1.8rem);
      margin-left: auto;
    }
    
    &__param {
      width: 100%;
      margin-right: 0;
    }
  }
}
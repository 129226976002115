.main-slider {
	width: 100%;
	
	position: relative;
	
	&__container {
		width: 100%;
		display: flex;
		position: relative;
		z-index: 2;
	}
	
	&__wrap {
		width: 100%;
		display: flex;
	}
	
	&__slide {
		display: flex;
		width: 100%;
		flex-shrink: 0;
		position: relative;
	}
	
	&__img-wrap {
		width: 100%;
		//z-index: 1;
		position: relative;
		
		&::before {
			content: '';
			position: relative;
			display: block;
			width: 100%;
			padding-top: calc(~'100% * 840 / 1920');
		}
		
		picture {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: -13.5rem;
			overflow: hidden;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
			z-index: 1;
		}
		
		&::after {
			content: '';
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			right: 0;
			bottom: -13.6rem;
			background: linear-gradient(to top, @color-violet-darklight 0%, fade(@color-violet-darklight, 30%) 100%);
		}
	}
	
	&__caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		padding: 5rem 13rem 5rem;
	}
	
	&__caption-content {
		max-width: 60%;
	}
	
	&__title {
		.h1;
		margin: 0 0 1rem;
	}
	
	&__text {
		.font;
		font-weight: @semibold;
		font-size: 2.4rem;
		text-transform: uppercase;
		margin: 0 0 3rem;
	}
	
	&__nav {
		z-index: 10;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	
	&__nav._prev {
		right: auto;
		left: 4.5rem;
	}
	
	&__nav._next {
		left: auto;
		right: 4.5rem;
	}
	
	&__pagination {
		z-index: 10;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 1.5rem;
	}
	
	&__bottom {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 10;
		margin-left: 13rem;
		
		&::before {
			content: '';
			position: absolute;
			width: 3rem;
			right: 100%;
			bottom: 5rem;
			top: 6rem;
			//background-color: fade(white, 10%);
			background-color: @color-violet-lighter;
			//background-color: #4b3854;
			z-index: 5;
		}
	}
	
	&__search {
		margin-left: -3rem;
		width: calc(~'(25% - .1rem) + 3rem');
		min-width: 20rem;
		
		.search__input-wrap {
			//background-color: #4b3854;
		}
	}
	
	&__categories {
	
	}
}
.param-group {
	.font();
	&__head {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 3rem;
		padding-right: 3rem;
		position: relative;
		font: inherit;
		margin-bottom: 3.5rem;
		
		&::after {
			font: inherit;
			position: absolute;
			content: '';
			left: 3rem;
			right: 0;
			top: .6em;
			height: 1px;
			background-color: #493752;
		}
	}
	
	&__title {
		display: block;
		font: inherit;
		text-transform: uppercase;
		background-color: #362940;
		position: relative;
		z-index: 1;
		padding-right: 2rem;
	}
	
	&__subtitle {
		display: block;
		font: inherit;
		font-size: 1.2rem;
		margin-top: .4rem;
		color: fade(white, 60);
		margin-bottom: -1.7rem;
	}
	
	&__items-list {
		font: inherit;
		padding: 0 3rem;
	}
	
	&__customer {
		font: inherit;
		padding: 0 3rem;
		margin-bottom: 3.5rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__parameter {
		font: inherit;
		padding: 3rem;
		background-color: #261e34;
		position: relative;
		margin-top: 4.5rem;
		margin-bottom: 3rem;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__parameter-del {
		position: absolute;
		bottom: 100%;
		right: 0;
		margin-bottom: 1rem;
	}
	
	&__parameter-list {
		padding: 3rem 3rem 2rem;
		border: 1px solid #493752;
		border-radius: @radius;
		margin-bottom: 2rem;
		margin-top: 3.5rem;
		position: relative;
	}
	
	&__parameter-list-lang {
		position: absolute;
		bottom: 100%;
		right: 0;
		margin-bottom: 1rem;
	}
	
	&__item {
		font: inherit;
		display: flex;
		align-items: flex-start;
		margin-bottom: 3rem;
		&:last-child {
			margin-bottom: 0;
		}
		
		&._align-center {
			align-items: center;
		}
		
		&._has-range {
			
			.param-group__item-name {
				min-height: 12rem;
				padding-top: 2.8rem;
			}
		}
	}
	
	&__item-name {
		font: inherit;
		display: flex;
		align-items: center;
		width: 11.5rem;
		margin-right: 1.5rem;
		flex-shrink: 0;
		//min-height: 3.9rem;
	}
	
	&__item-titles {
		font: inherit;
		color: fade(white, 60);
	}
	
	&__item-title {
		font: inherit;
		color: inherit;
		font-size: 1.6rem;
	}
	
	&__item-subtitle {
		display: block;
		font: inherit;
		font-size: 1.2rem;
		margin-top: .4rem;
		margin-bottom: -1.7rem;
	}
	
	&__item-help {
		margin-left: .8rem;
	}
	
	&__item-content {
		flex-grow: 1;
		width: 10%;
		display: flex;
		flex-direction: column;
	}
	
	&__input {
		width: 100%;
		margin-bottom: 1.5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&__checkbox-list {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1.5rem;
		margin-top: -1rem;
	}
	
	&__checkbox-item {
		width: calc(~'(100% - 1.5rem * 3) / 3');
		margin-right: 1.5rem;
		margin-top: 1rem;
	}
	
	&__rating-range {
	
	}
	
	&__item-del {
		margin-left: 1.5rem;
		display: flex;
		.icon-btn__icon {
			height: 3.9rem;
		}
		
	}
	
	&__item-add-row {
		margin-top: -1rem;
		padding-left: 13rem;
	}
	
	&__add-btn {
		width: 100%;
		text-transform: none;
	}
}
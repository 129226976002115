@media @sm-max {
  .page-cart {
    &__cart {
      padding-bottom: 5.5rem;
    }
  
    &__bonus-wrap {
      margin-bottom: 2.6rem;
    }
  }
}

@media @xs-max {
  .page-cart {
    
    &__cart {
      padding-bottom: 1.5rem;
    }
    
    &__cart-list {
      margin-bottom: 2.4rem;
    }
    
    &__cart-list-footer {
      flex-direction: column;
      align-items: flex-start;
      border: 0;
      padding: 0;
    }
    
    &__total-wrap {
      width: 100%;
      padding: 1.5rem 4.4rem 1.7rem 1.8rem;
      border: 0.1rem solid #493752;
      border-radius: @radius;
    }
    
    &__promo-code {
      margin-bottom: 2rem;
    }
    
    &__footer {
      flex-direction: column;
      align-items: flex-end;
    }
    
    &__alert {
      margin: 0 0 3rem;
      padding-left: 1rem;
      
      &::after {
        top: auto;
        right: 5.4rem;
        bottom: -0.9rem;
      }
    }
  
    &__bonus-wrap {
      text-align: left;
    }
  }
}
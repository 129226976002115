.search-products-list-toolbar {
  position: relative;
  width: 3.9rem;
  
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #40384b;
    width: 100%;
    height: 3.9rem;
    border-radius: @radius;
    
    text-decoration: none;
    
    transition: @time;
    
    &:hover {
      background-color: @color-blue;
    }
    
    & svg {
      width: 1.2rem;
      height: 1.3rem;
      fill: white;
      
      transition: @time;
    }
  }
  
  &._open &__btn {
    border-radius: 0 @radius @radius 0;
  }
  
  &__dropdown {
    position: absolute;
    z-index: 41;
    right: 100%;
    top: 0;
    width: 0;
    height: 100%;
    background: #40384b;
    border-radius: @radius 0 0 @radius;
    overflow: hidden;
    
    transition: @time-modal;
  }
  
  &__input {
    height: 100%;
    width: 100%;
    padding: 1rem 2rem 1.1rem;
    background: transparent;
  
    .font();
  }
}
.product-toolbar {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &__star-rating,
  &__param {
    margin-right: 3.2%;
  }
  
  &__edit-wrap {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: 10rem;
    flex-grow: 1;
  }
  
  &__param,
  &__edit-btn {
    display: flex;
    align-items: center;
    .font();
    
    & svg {
      flex-shrink: 0;
      fill: white;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.6rem;
    }
    
    & > span {
      .clipText();
  
    }
  }
  
  &__param {
    color: fade(white, 60);
  }
  
  &__param-value {
    margin-left: 0.4rem;
    font: inherit;
    
    color: white;
  }
  
  &__param-link {
    margin-left: 0.8rem;
    color: @color-blue;
    font: inherit;
    white-space: nowrap;
    word-wrap: normal;
    word-break: normal;
    
    &:hover {
      color: white;
    }
  }
  
  &__edit-btn {
    display: flex;
   
    margin-right: 2.5rem;
    flex-shrink: 1;
    max-width: 30%;
    
    &:last-child {
      margin-right: 0;
    }
  
    & svg {
      flex-shrink: 0;
    }
  
    & span {
      width: 50%;
      flex-grow: 1;
    }
  }
  
  &__star-rating  .star-rating__review-amount,
  &__edit-btn {
    color: #a27ba8;
    font-size: 1.4rem;
    
    &:hover {
      color: #c3a2d3;
    }
  }
}
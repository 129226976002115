.g-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: @transparent;
	border: 0;
	.font();
	font-size: 1.4rem;
	letter-spacing: .02em;
	color: white;
	cursor: pointer;
	transition: color @time, opacity @time, border-color @time, background-color @time, box-shadow @time;
	text-decoration: none;
	position: relative;
	z-index: 5;
	
	svg {
		fill: currentColor;
	}
	
	&:hover {
		color: @color-blue;
	}
	&._disabled {
		cursor: auto;
		pointer-events: none;
	}
	
	
	&--primary {
		text-transform: uppercase;
		padding: 1.7rem 2rem;
		white-space: nowrap;
		
		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border-radius: @radius;
			transition: transform @time, box-shadow @time, background-color @time;
			z-index: -1;
		}
	}
	
	&--small {
		padding: 1.2rem 2rem;
	}
	
	&--primary:hover {
		color: white;
	}
	
	&--zoom:hover {
		&::before {
			transform: scale(1.1);
		}
	}
	
	//--colors
	
	//--violet-lightest
	&--violet-lightest {
		&::before {
			background-color: #55375d;
		}
		
		&.g-btn--small::before {
		
		}
	}
	&--violet-lightest:hover::before {
		background-color: #764a82;
	
	}
	&--small.g-btn--violet-lightest:hover::before {
	
	}
	
	//--blue
	&--blue {
		&::before {
			background-color: #2e4d7a;
		}
		
		&.g-btn--small::before {
		
		}
	}
	&--blue:hover::before {
		background-color: #36405d;
		
	}
	&--small.g-btn--blue:hover::before {
	
	}
	
	//--violet
	&--violet {
		&::before {
			background-color: #3e2f45;
		}
		
		&.g-btn--small::before {
		
		}
	}
	&--violet:hover::before {
		background-color: #503b59;
		box-shadow: 0 1.2rem 3.5rem 0 fade(#503b59, 45%);
	}
	&--small.g-btn--violet:hover::before {
	
	}
	//--
	
	//--violet-dark
	&--violet-dark {
		&::before {
			background-color: @color-violet-darklight;
		}
		
		&.g-btn--small::before {
		
		}
	}
	&--violet-dark:hover {
		color: @color-blue;
	}
	&--violet-dark:hover::before {
		//background-color: #503b59;
		//box-shadow: 0 1.2rem 3.5rem 0 fade(@color-violet-darklight, 45%);
	}
	&--small.g-btn--violet-dark:hover::before {
	
	}
	//--


	//--violet-lightgrey
	&--lightgray {
		&::before {
			background-color: #c6c6d3;
		}
	}
	&--lightgray:hover::before {
		background-color: #c6c6d3;
	}

	//--mint
	&--mint {
		
		&::before {
			background-color: @color-mint;
			box-shadow: 0 1.9rem 5.5rem 0 fade(@color-mint, 40%);
		}
		
		&.g-btn--small::before {
			box-shadow: 0 1.2rem 3.5rem 0 fade(@color-mint, 40%);
		}
	}
	&--mint:hover::before {
		background-color: @color-mint-light;
		box-shadow: 0 1.9rem 5.5rem 0 fade(@color-mint-light, 45%);
	}
	&--small.g-btn--mint:hover::before {
		box-shadow: 0 1.2rem 3.5rem 0 fade(@color-mint-light, 45%);
	}
	//--
	
	//--orange
	&--orange {
		
		&::before {
			background-color: @color-orange;
			box-shadow: 0 1.9rem 5.5rem 0 fade(@color-orange, 40%);
		}
		
		&.g-btn--small::before {
			box-shadow: 0 1.2rem 3.5rem 0 fade(@color-orange, 40%);
		}
	}
	&--orange:hover::before {
		background-color: @color-orange-light;
		box-shadow: 0 1.9rem 5.5rem 0 fade(@color-orange-light, 40%);
	}
	&--small.g-btn--orange:hover::before {
		box-shadow: 0 1.2rem 3.5rem 0 fade(@color-orange-light, 40%);
	}
	//--

	//--violet
	&--violet-shadow {
		&::before {
			background-color: #55375d;
			box-shadow: 0 1.2rem 3.5rem rgba(85, 55, 93, 0.7);
		}

		&.g-btn--small::before {
			box-shadow: 0 1.2rem 3.5rem rgba(85, 55, 93, 0.7);
		}
	}
	&--violet-shadow:hover::before {
		background-color:  #684372;
		box-shadow: 0 1.2rem 3.5rem rgba(85, 55, 93, 0.7);
	}
	&--small.g-btn--violet-shadow:hover::before {
		box-shadow: 0 1.2rem 3.5rem rgba(85, 55, 93, 0.7);
	}
	//--
	
	&--non-shadow {
		box-shadow: none !important;
	}
	
	// outline
	
	&--outline {
		padding: 0.5rem 0.8rem 0.6rem;
		border: 0.1rem solid #5d4d65;
		border-radius: @radius;
		
		font-size: 1.6rem;
		
		&:hover {
			border-color: #928d94;
			
			color: white;
		}
	}
	
	// outline yellow
	
	&--outline&--yellow {
		border-color: #886a22;
		
		color: @color-yellow;;
		
		&:hover {
			border-color: @color-yellow;
			
			color: @color-yellow;
		}
	}
	
	// outline yellow
	
	&--tag {
		background: #302741;
		padding: 0.3rem 0.8rem 0.4rem;
		border-radius: @radius;
		
		color: #827796;
		
		svg {
			fill: white;
			transition: fill @time;
		}
		
		&:hover {
			background: #372940;
			
			color: white;
			
			svg {
				fill: @color-blue;
			}
		}
		
		
	}
	
	&--ico {
		width: 2.4rem;
		height: 2.4rem;
		border-radius: @radius;
		
		svg {
			width: .8rem;
			height: .8rem;
			fill: white;
		}
	}
	
	&--ico-orange {
		background-color: @color-orange;
		&:hover {
			background-color: @color-orange-light;
		}
	}
	
	&:not(.g-btn--lightgray)._disabled {
		color: fade(white, 30);
		&::before {
			background-color: #493752;
			box-shadow: none;
		}
	}
}



@media @xs-max {
  .pagination {
    flex-wrap: wrap;
    
    
    
    &__list {
      margin-top: 1.2rem;
      width: 100%;
    }
    
    &__link._back {
      color: @color-violet-lightest;
    }
  }
}
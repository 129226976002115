@media @md-max  {
  .cart-list {
  
    & td:nth-child(2) {
      width: 36%;
    }
  
    & th:nth-child(3),
    & td:nth-child(3) {
      width: 0;
      padding: 0;
    }
    
    & td:nth-child(4) {
      width: 14%;
    }
    
    & td:nth-child(5) {
      width: 12%;
    }
    
    &__type {
      margin-top: 0.8rem;
    }
  }
}

@media @sm-max  {
  .cart-list {
    
    & th:nth-child(2),
    & td:nth-child(2) {
      padding-left: 0;
    }
    
    & th:nth-child(5),
    & td:nth-child(5) {
      width: 0;
      padding: 0;
    }
    
    &__bg-img {
      display: none;
    }
    
    &__info-list-value-item {
      width: (100% - 2% * 3) / 4;
      margin-right: 2%;
      
      &:nth-child(6n) {
        margin-right: 2%;
      }
      
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    
    &__ava-wrap {
      width: 3rem;
      height: 3rem;
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
    }
  }
}

@media @xs-max {
  .cart-list {
    display: block;
    width: auto;
    min-width: 100%;
    margin-left: -@side-padding-mob;
    margin-right: -@side-padding-mob;
    
    & tbody {
      display: block;
      margin-bottom: 0.5rem;
    }
  
    & tr {
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
    
    & tr:first-child {
      padding: 0 @side-padding-mob;
    }
    
    & tbody tr:first-child td {
      border-top: 0;
    }
    
    & td:first-child {
      position: static;
      width: 0;
    }
    
    & tr:first-child td:nth-child(2) {
      width: 100%;
      padding: 2.6rem 1rem 1.8rem 0;
    }
    
    & td:nth-child(4) {
      order: 6;
      width: 50%;
      flex-grow: 1;
      padding-left: 0;
      padding-bottom: 2.2rem;
    }
  
    & tbody tr:first-child td:nth-child(6) ,
    & tbody tr:first-child td:nth-child(7) {
      width: 50%;
      border: 0.1rem solid #5d4e65;
      margin-bottom: 0.5rem;
    }
  
    & tbody tr:first-child td:nth-child(6) {
      border-right: 0;
      border-radius: @radius 0 0 @radius;
      padding-left: 1.8rem;
    }
  
    & tbody tr:first-child td:nth-child(7) {
      border-left: 0;
      border-radius: 0 @radius @radius 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding-right: 1.8rem;
    }
    
    & td:nth-child(8) {
      order: 8;
      width: auto;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
    }
    
    &__delete-btn {
      z-index: 5;
      top: 1rem;
      left: auto;
      right: 1.4rem;
      width: 2.4rem;
      height: 2.4rem;
    }
    
    &__amount-title,
    &__delivery-title {
      margin-bottom: 0.6rem;
      
      font-size: 1.4rem;
      color: fade(white, 60);
      font-weight: @regular;
      font-family: @source-sans;
    }
    
    &__amount {
      width: 10rem;
    }
  
    &__delivery-title {
      margin-bottom: 0;
      margin-right: 0.6rem;
    }
    
    &__more {
      position: static;
      border: 0;
      width: 5rem;
      height: 5rem;
      transform: translateX(@side-padding-mob);
    }
    
    // - - - -
    
    
    & tbody &__info-list-row td {
      width: 100%;
    }
  
    &__info-list {
      justify-content: space-between;
      padding: 0.8rem @side-padding-mob;
    }
  
    &__info-list-value-item {
      width: 47%;
      margin-right: 0 !important;
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
    }
  }
}
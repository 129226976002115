.nav-tabs {
	.font;
	display: flex;
	justify-content: center;
	max-width: 100%;
	overflow-x: auto;
	
	&__tab {
		display: inline-block;
		font: inherit;
		color: inherit;
		border-color: @color-violet-lightdark-3;
		border-style: solid;
		border-width: 1px 0 1px 0;
		background-color: transparent;
		text-transform: uppercase;
		letter-spacing: .02em;
		padding: 1.1rem 2rem;
		cursor: pointer;
		transition: color @time, background-color @time, border-color @time;
		flex-shrink: 0;
		
		&:first-child {
			border-left-width: 1px;
			border-top-left-radius: @radius;
			border-bottom-left-radius: @radius;
		}
		
		&:last-child {
			border-right-width: 1px;
			border-top-right-radius: @radius;
			border-bottom-right-radius: @radius;
		}
	}
	
	&__tab:hover {
		background-color: @color-violet-lightdark-3;
		border-color: @color-violet-lightdark-3;
		color: inherit;
	}
	
	&__tab._open {
		background-color: @color-mint;
		border-color: @color-mint;
		color: white;
		pointer-events: none;
	}
}

.nav-tabs._light {
	color: @color-violet-darklight;
	
	.nav-tabs__tab {
		border-color: @color-grey-violet;
	}
	
	.nav-tabs__tab:hover {
		background-color: @color-grey-violet;
		border-color: @color-grey-violet;
	}
	
	.nav-tabs__tab._open {
		background-color: @color-mint;
		border-color: @color-mint;
	}
	
}
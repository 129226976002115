.tabs-list {
	.font;
	width: 100%;
	border-bottom: 1px solid @color-violet-lighter;
	position: relative;
	
	&__list {
		font: inherit;
		color: inherit;
		display: flex;
		max-width: 100%;
	}
	
	&__item {
		font: inherit;
		color: inherit;
		flex-shrink: 0;
		margin-right: 4rem;
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	&__tab {
		position: relative;
		display: inline-block;
		font: inherit;
		color: inherit;
		text-transform: uppercase;
		letter-spacing: .02em;
		padding: 1.2rem 0;
		cursor: pointer;
		transition: color @time;
		
		&::before {
			content: '';
			position: absolute;
			z-index: 3;
			left: 0;
			bottom: -0.1rem;
			width: 100%;
			height: 0.2rem;
			background: @color-blue;
			opacity: 0;
			
			transition: @time;
		}
	}
	
	&__tab:hover,
	&__tab._open {
		color: @color-blue;
		
		&::before {
			opacity: 1;
		}
	}
	
	&__tab._open {
		pointer-events: none;
	}
	
	&__line {
		position: absolute;
		left: 0;
		bottom: -.1rem;
		height: .2rem;
		background-color: @color-blue;
		width: 0;
		transition: width @time;
	}
}
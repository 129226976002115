.top-panel {
	.font();
	background-color: #362940;
	display: flex;
	justify-content: center;
	&__menu {
		display: flex;
		font: inherit;
		text-transform: uppercase;
		max-width: 100%;
	}
	
	&__menu-item {
		font: inherit;
		position: relative;
	}
	
	&__menu-link {
		background-color: transparent;
		font: inherit;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-height: 5rem;
		padding: 1rem 2rem;
		white-space: nowrap;
		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: currentColor;
			margin-right: 1rem;
		}
		
		&:hover,
		&._active {
			background-color: @color-violet-darklight;
		}
	}
	
	&__menu-tooltip {
		position: absolute;
		margin-top: 1.5rem;
		top: 100%;
		right: 50%;
		
		&._reverse {
			flex-direction: row-reverse;
			right: auto;
			left: 50%;
			
			.menu-tooltip__arrow {
				transform: scaleX(-1);
				margin-right: 1rem;
				margin-left: -.3rem;
			}
		}
	}
}
@media @sm-max {
  .search-prev-results {
    padding-top: 1.6rem;
    
    &__group {
      padding: 0 @side-padding-mob;
    }
    
    &__group-show-all {
      text-transform: none;
    }
    
    &__btn {
      padding: 1.4rem @side-padding-mob;
    }
  }
}
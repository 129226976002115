.no-selection() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clipText() {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: normal;
  word-break: normal;
}

.clipTextHeight(@lineHeight, @row) {
  line-height: @lineHeight;
  max-height: calc(~""@lineHeight * 1em~" * "@row~"");
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: @row;
  -webkit-box-orient: vertical;
}

.font() {
  font-family: @source-sans;
  font-size: @font-size;
  line-height: @line-height;
  font-weight: @regular;
  color: white;
}

.h1() {
  font-family: @oswald;
  font-weight: @semibold;
  font-size: 7rem;
  text-transform: uppercase;
  line-height: 1.2;
  padding: 0;
}

.h2() {
  font-family: @oswald;
  font-weight: @semibold;
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 1.2;
}

.h3() {
  font-family: @source-sans;
  font-weight: @semibold;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.2;
}

.h4() {
  font-family: @source-sans;
  font-weight: @semibold;
  font-size: 1.6rem;
  text-transform: uppercase;
  line-height: 1.2;
}
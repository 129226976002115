@media @sm-max {
  .mob-menu {
    
    &__path-wrap {
     
      display: flex;
      justify-content: flex-start;
      width: 100%;
      overflow: hidden;
      padding: 1.6rem @side-padding-mob;
      border-bottom: 1px solid #2d233c;
      
    }
    
    &__path {
      position: relative;
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
  
      &::before {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0;
        left: -@side-padding-mob - 0.1rem;
        width: 29%;
        height: 100%;
        background: linear-gradient(to right, #1f1928, fade(#1f1928, 0));
        pointer-events: none;
      }
    }
    
    &__path-link {
      margin-right: 2rem;
      opacity: 0.5;
      
      .font();
      text-transform: uppercase;
      white-space: nowrap;
      word-break: normal;
      word-wrap: normal;
      
      &:last-child {
        margin-right: 0;
      }
      
      &::before {
        display: inline-block;
        content: "";
        vertical-align: 0.1rem;
        border-top: .3rem solid transparent;
        border-right: .4rem solid white;
        border-bottom: .3rem solid transparent;
        margin-right: 0.8rem;
      }
    }
    
    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3.4rem @side-padding-mob;
      .font();
      text-transform: uppercase;
      &:first-child {
        padding-bottom: 0;
      }
    }
    
    &__list + &__list {
      margin-top: -1.4rem;
      padding-top: 0;
    }
    
    &__link {
      padding: 0.4rem 0;
      margin-bottom: 2.2rem;
      font: inherit;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      &::after {
        display: inline-block;
        content: "";
        vertical-align: 0.1rem;
        border-top: .3rem solid transparent;
        border-left: .4rem solid white;
        border-bottom: .3rem solid transparent;
        margin-left: 0.8rem;
      }
    }
  
    &__list._plain {
      padding-top: 2rem;
      text-transform: none;
      //font-size: 1.6rem;
    }
  
    &__list._plain &__link::after {
      display: none;
    }
  }
}